import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Chip } from '@mui/material';
import { capitalize } from 'lodash';

const defaultWorkdayCalendar = {
  sunday: '0',
  monday: '0',
  tuesday: '0',
  wednesday: '0',
  thursday: '0',
  friday: '0',
  saturday: '0',
};

export function WorkDaysComponent({
  workdayCalendar,
  onChange,
  disabled,
}: {
  workdayCalendar: any;
  onChange: any;
  disabled: boolean;
}) {
  const workDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  workdayCalendar = !workdayCalendar ? defaultWorkdayCalendar : workdayCalendar;

  const handleClick = (day) => {
    const workdayCalendarClone = { ...workdayCalendar };
    // Weekday values are currently stored as strings in the backend... need to move to booleans at some point
    workdayCalendarClone[day] = workdayCalendarClone[day] === '1' ? '0' : '1';
    onChange(workdayCalendarClone);
  };

  if (disabled) {
    return (
      <Grid container item spacing={0.5}>
        {workDays.map((day) => (
          <Grid item key={`${day}-${workdayCalendar?.[day]}`}>
            <Chip
              label={capitalize(day.slice(0, 3))}
              size={'small'}
              sx={{
                backgroundColor: workdayCalendar?.[day] === '1' ? '#3f51b5' : '',
                color: workdayCalendar?.[day] === '1' ? 'white' : '',
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Grid container item spacing={0.5}>
      {workDays.map((day) => (
        <Grid item key={`${day}-${workdayCalendar?.[day]}`}>
          <Button
            disabled={disabled}
            size={'small'}
            variant={'contained'}
            sx={{
              backgroundColor: workdayCalendar?.[day] === '0' ? 'grey.400' : undefined,
            }}
            onClick={() => handleClick(day)}
          >
            {day.slice(0, 3)}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}
