import { useEffect } from 'react';

export const useGanttColorBy = (gantt, colorBy) => {
  useEffect(() => {
    if (gantt && colorBy) {
      gantt.attachEvent(
        'onBeforeTaskDisplay',
        function (taskId, task) {
          task.$color = colorBy(task);
          return true;
        },
        { id: 'colorBy' }
      );

      if (gantt.$root) gantt?.render();
    }
  }, [colorBy, gantt]);
};
