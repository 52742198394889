import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ProcurementIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useGetTaskProcurementsQuery } from 'features/procurement/store/procurement.api';
import ProcurementDataGridTable from 'features/procurement/components/ProcurementDataGridTable';

/**
 * Procurement Task Dialog
 * @param {*} taskId
 * @returns
 */
export const ProcurementTab = ({ taskId }) => {
  const [filters, setFilters] = useState({
    status: null,
    page: 1,
    per_page: 50,
    order_by: 'created_at,desc',
  });

  const {
    data: taskProcurements = [],
    isLoading,
    isFetching,
  } = useGetTaskProcurementsQuery({ taskId });

  return (
    <Stack spacing={1}>
      <Stack alignItems={'center'} direction={'row'}>
        <ProcurementIcon color={'action'} sx={{ p: 1.5, fontSize: '1.75rem' }} />
        <Typography sx={{ fontWeight: '600' }} variant={'h6'}>
          Procurements
        </Typography>
      </Stack>
      <Paper elevation={3}>
        <ProcurementDataGridTable
          disableToolBar={true}
          filters={filters}
          isFetching={isFetching}
          isLoading={isLoading}
          rows={taskProcurements}
          setFilters={setFilters}
          total={taskProcurements.length}
        />
      </Paper>
    </Stack>
  );
};

export default ProcurementTab;
