import { useEffect } from 'react';

export const useGanttMakers = (gantt, markers, options = {}) => {
  const { skip } = options;
  // Add markers
  useEffect(() => {
    if (markers && gantt && !gantt.$destroyed && !skip) {
      const addMarkers = () => {
        markers.forEach((marker) => gantt.addMarker(marker));
      };
      gantt.plugins({ marker: true });
      addMarkers();
      gantt.attachEvent('onClear', addMarkers, { id: 'addMarkers' });
    }

    // Clean up markers
    return () => {
      if (gantt && gantt.deleteMarker) {
        markers.forEach((marker) => gantt.deleteMarker(marker.id));
        gantt.detachEvent('addMarkers');
      }
    };
  }, [gantt, markers, skip]);
};
