import { formatTaskToGanttTask } from '@blackhyve/utilities/gantt';
import { hasChild } from '@blackhyve/utilities/tasks';
import {
  ContentCopy,
  ContentPaste,
  KeyboardTab,
  LockOpen,
  LockOutlined,
  SettingsOutlined,
  VerticalAlignBottom,
  VerticalAlignTop,
} from '@mui/icons-material';
import SquareFootIcon from '@mui/icons-material//SquareFoot';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import FormatIndentDecreaseOutlinedIcon from '@mui/icons-material/FormatIndentDecreaseOutlined';
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import ConfirmationPopup from 'components/common/v3/ConfirmationPopup';
import { selectCurrentUser } from 'features/auth';
import SelectAutoSchedulerDateDialog from 'features/gantt/menu/SelectAutoSchedulerDateDialog';
import { Label as LabelComponent } from 'features/labels/components/Label';
import {
  insertChildTask,
  insertTaskAbove,
  insertTaskBelow,
} from 'features/tasksGantt/utils/taskActions/insertTask';
import { pasteTaskClipboard } from 'features/tasksGantt/utils/taskActions/pasteTaskClipboard';
import { squareUpTask } from 'features/tasksGantt/utils/taskActions/squareUpTask';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocalStorage } from 'usehooks-ts';
import { useModal } from '../../../components/common/ModalProvider';
import ConfirmDeleteDialog from '../../../components/common/popovers/ConfirmDeleteDialog';
import { getTaskActions } from '../../../components/projectOverview/gantt/ganttConfig/column/menu/BulkEditMenu';
import ganttStore from '../../../components/projectOverview/gantt/ganttConfig/ganttStore';

/**
 * Task Context Menu
 * @param {*} props
 * @param {function} props.handleClose - HandleClosing menu
 * @param {ref} props.anchor
 * @param {Number} props.id - task id
 * @returns
 */
const TaskContextMenu = ({ id, ganttId, anchor, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [taskClipBoard, setTaskClipboard, removeTaskClipboard] = useLocalStorage('taskClipboard', {
    tasks: [],
    links: [],
  });
  const gantt = ganttStore.getGantt(ganttId);
  const { openModal } = useModal();

  const currentUser = useSelector((state) => selectCurrentUser(state));

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [squareUpConfirmationOpen, setSquareUpConfirmationOpen] = useState(false);
  const [chooseAutoScheduleDatesOpen, setChooseAutoScheduleDatesOpen] = useState(false);

  if (!gantt || !gantt.isTaskExists(id)) {
    return false;
  }

  const task = gantt.getTask(id);

  const canEdit = !gantt.config.readonly && (!task.readonly || task.type === 'project_bar');
  const hasChildren = hasChild(task.id, gantt);
  const isProjectBar = task?.id < 0;

  const handleIndent = () => {
    if (gantt?.ext?.undo) {
      gantt.ext.undo.saveState(id, 'task');
    }
    const action = getTaskActions(ganttId)['indent'];
    action(id);
  };

  const handleOutdent = () => {
    if (gantt?.ext?.undo) {
      gantt.ext.undo.saveState(id, 'task');
    }
    const action = getTaskActions(ganttId)['outdent'];
    const indexes = { id: gantt.getTaskIndex(id) };
    let currentId = id;
    while (
      gantt.isTaskExists(gantt.getPrevSibling(currentId)) &&
      gantt.isSelectedTask(gantt.getPrevSibling(currentId))
    ) {
      currentId = gantt.getPrevSibling(currentId);
    }
    const siblings = { [id]: { first: currentId } };
    action(id, indexes, siblings);
  };

  const handleDelete = () => {
    if (gantt.isTaskExists(id)) {
      if (gantt?.ext?.undo) {
        gantt.ext.undo.saveState(id, 'task');
      }
      gantt.deleteTask(id);
    }
  };

  const handleAddChild = () => {
    if (gantt?.ext?.undo) {
      gantt.ext.undo.saveState(id, 'task');
    }
    insertChildTask(id, gantt);
  };

  const handleInsertAbove = () => {
    insertTaskAbove(id, gantt);
  };

  const handleInsertBelow = () => {
    insertTaskBelow(id, gantt);
  };

  const handleInsertRecipe = () => {
    openModal('recipeDrawerDialog', {
      ganttId,
      taskId: task?.id,
      projectId: task?.project_id,
    });
    handleClose();
  };

  const handleSquareUp = () => {
    squareUpTask(id, gantt);
    handleClose();
  };

  const handleChangeAutoSchedulerDates = (selectedDateType) => {
    const task = gantt.getTask(id);
    if (gantt?.ext?.undo) {
      gantt.ext.undo.saveState(id, 'task');
    }
    task.autoschedule_date = selectedDateType;
    gantt.updateTask(task.id);
    gantt.autoSchedule(task.id);
  };

  const handleToggleLockedScheduledDates = () => {
    if (gantt?.ext?.undo) {
      gantt.ext.undo.saveState(task.id, 'task');
    }
    if (task.dates_locked_by) {
      task.dates_locked_by = null;
    } else {
      task.dates_locked_by = currentUser;
    }
    gantt.updateTask(id);
    gantt.autoSchedule(id);
  };

  const handleJobwalkClick = () => {
    openModal('jobwalkDialog', {
      open: true,
      handleUpdate: handleAfterJobwalk,
      ganttId,
      taskId: task.id,
      projectId: task.project_id,
      disabled: !canEdit || task.id < 0,
    });
  };

  const handleStuckPointClick = () => {
    openModal('stuckPointDialog', {
      open: true,
      ganttId,
      taskId: task.id,
      projectId: task.project_id,
      disabled: isProjectBar,
    });
  };

  const handleAfterJobwalk = (taskResponse) => {
    const updatedTask = cloneDeep(formatTaskToGanttTask(taskResponse));
    if (gantt.config.project_bar) {
      updatedTask.parent = updatedTask.parent ? updatedTask.parent : -1;
    }
    Object.assign(gantt.getTask(task.id), updatedTask);
    gantt.updateTask(task.id);

    // Need to attach an event because the autoschedule undo action stack push is in a timeout
    gantt.attachEvent(
      'onBeforeUndoStack',
      function () {
        gantt.clearUndoStack();
        gantt.clearRedoStack();
        return false;
      },
      { once: true }
    );
    gantt.autoSchedule(task.id);
  };

  const isComplete = task?.status === 'complete';

  return (
    <>
      <Menu
        autoFocus
        anchorEl={anchor}
        getContentAnchorEl={null}
        open={Boolean(anchor)}
        role={'menu'}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <MenuItem
          autoFocus
          disabled={isProjectBar}
          key={'stuckpoint'}
          onClick={handleStuckPointClick}
        >
          <ListItemIcon>
            <PendingActionsIcon color={'currentColor'} height={'1em'} width={'1em'} />
          </ListItemIcon>
          <ListItemText primary={<LabelComponent labelKey="stuck_point" />} />
        </MenuItem>
        {!hasChildren ? (
          [
            <MenuItem autoFocus disabled={!canEdit} key={'jobwalk'} onClick={handleJobwalkClick}>
              <ListItemIcon>
                <DirectionsWalkIcon color={'currentColor'} height={'1em'} width={'1em'} />
              </ListItemIcon>
              <ListItemText primary={'Jobwalk'} />
            </MenuItem>,
            <Divider key={'divider'} />,
          ]
        ) : (
          <MenuItem
            disabled={!canEdit || isComplete || isProjectBar}
            onClick={() => setSquareUpConfirmationOpen(true)}
          >
            <ListItemIcon>
              <SquareFootIcon />
            </ListItemIcon>
            <ListItemText primary="Square Up" />
          </MenuItem>
        )}
        <MenuItem
          disabled={
            !canEdit ||
            isComplete ||
            isProjectBar ||
            (task.dates_locked_by &&
              currentUser.role !== 'super_admin' &&
              currentUser.role !== 'admin' &&
              currentUser.id !== task.dates_locked_by.id)
          }
          onClick={handleToggleLockedScheduledDates}
        >
          <ListItemIcon>{task?.dates_locked_by ? <LockOpen /> : <LockOutlined />}</ListItemIcon>
          <ListItemText
            primary={`${task?.dates_locked_by ? 'Unlock' : 'Lock'} Scheduled Dates`}
            secondary={
              task?.dates_locked_by &&
              `Locked by ${task.dates_locked_by.first_name}  ${task.dates_locked_by.last_name}`
            }
          />
        </MenuItem>
        <MenuItem
          disabled={!canEdit || isProjectBar}
          key={'change-autoschedule-dates'}
          onClick={() => setChooseAutoScheduleDatesOpen(true)}
        >
          <ListItemIcon>
            <SettingsOutlined />
          </ListItemIcon>
          <ListItemText primary={'Settings'} />
        </MenuItem>
        <MenuItem disabled={!canEdit || isComplete} onClick={handleInsertRecipe}>
          <ListItemIcon>
            <BookOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={'Insert Recipe'} />
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={!canEdit || isComplete}
          onClick={() => {
            setTaskClipboard({ tasks: [task] });
            enqueueSnackbar(`Task copied to clipboard`, {
              variant: 'success',
            });
          }}
        >
          <ListItemIcon>
            <ContentCopy />
          </ListItemIcon>
          <ListItemText primary={'Copy'} />
        </MenuItem>
        <MenuItem
          disabled={!canEdit || isComplete || taskClipBoard?.tasks?.length === 0}
          onClick={() => {
            pasteTaskClipboard({ clipBoard: taskClipBoard, parentTask: task, gantt });
          }}
        >
          <ListItemIcon>
            <ContentPaste />
          </ListItemIcon>
          <ListItemText primary={'Paste'} />
        </MenuItem>
        <Divider key={'divider'} />
        {gantt?.config?.order_branch
          ? [
              <MenuItem disabled={!canEdit || isProjectBar} key={'indent'} onClick={handleIndent}>
                <ListItemIcon>
                  <FormatIndentIncreaseOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={'Indent'} />
              </MenuItem>,
              <MenuItem disabled={!canEdit || isProjectBar} key={'outdent'} onClick={handleOutdent}>
                <ListItemIcon>
                  <FormatIndentDecreaseOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={'Outdent'} />
              </MenuItem>,
            ]
          : null}
        <MenuItem disabled={!canEdit} key={'insert child'} onClick={handleAddChild}>
          <ListItemIcon>
            <KeyboardTab />
          </ListItemIcon>
          <ListItemText primary={'Insert Child'} />
        </MenuItem>
        <MenuItem
          disabled={!canEdit || isProjectBar}
          key={'insert above'}
          onClick={handleInsertAbove}
        >
          <ListItemIcon>
            <VerticalAlignTop />
          </ListItemIcon>
          <ListItemText primary={'Insert Above'} />
        </MenuItem>
        <MenuItem
          disabled={!canEdit || isProjectBar}
          key={'insert below'}
          onClick={handleInsertBelow}
        >
          <ListItemIcon>
            <VerticalAlignBottom />
          </ListItemIcon>
          <ListItemText primary={'Insert Below'} />
        </MenuItem>
        <MenuItem disabled={!canEdit || isProjectBar} onClick={() => setDeleteDialogOpen(true)}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon color={'error'} />
          </ListItemIcon>
          <ListItemText primary={'Delete'} sx={{ color: 'error.main' }} />
        </MenuItem>
      </Menu>
      <ConfirmDeleteDialog
        handleClose={() => setDeleteDialogOpen(false)}
        handleDelete={handleDelete}
        item={'task'}
        itemNames={[task.text]}
        open={deleteDialogOpen}
      />
      <ConfirmationPopup
        confirmationMessage="Are you sure you want to square up"
        handleClose={() => setSquareUpConfirmationOpen(false)}
        handleConfirm={handleSquareUp}
        id={id}
        open={squareUpConfirmationOpen}
        title="Square Up"
      />
      <SelectAutoSchedulerDateDialog
        excitingValue={task.autoschedule_date}
        handleClose={() => setChooseAutoScheduleDatesOpen(false)}
        handleSave={handleChangeAutoSchedulerDates}
        id={id}
        open={chooseAutoScheduleDatesOpen}
      />
    </>
  );
};

export default TaskContextMenu;
