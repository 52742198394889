function topologicalSort(gantt, projectId) {
  const sortedTasks = [];
  const permanentMark = new Set();
  const temporaryMark = new Set();

  function visit(taskId) {
    if (permanentMark.has(taskId)) {
      return;
    }

    if (temporaryMark.has(taskId)) {
      console.error('Graph has at least one cycle.');
      return;
    }

    temporaryMark.add(taskId);

    const task = gantt.getTask(taskId);
    if (task.type !== 'placeholder' && (!projectId || task.project_id === projectId)) {
      const successors = task.$target.map((linkId) => gantt.getLink(linkId));

      for (const successorLink of successors) {
        visit(successorLink.source);
      }

      temporaryMark.delete(taskId);
      permanentMark.add(taskId);
      sortedTasks.unshift(task);
    }
  }

  gantt.eachTask((task) => {
    const taskId = task.id;
    if (!permanentMark.has(taskId)) {
      visit(taskId);
    }
  }, 0);

  return sortedTasks;
}

module.exports = { topologicalSort };
