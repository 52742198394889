import { getClosestWorkingTime, parseDate } from '@blackhyve/utilities/dates';
import { isAfter, isBefore, isSameDay, startOfDay } from 'date-fns';
import { useMemo } from 'react';
import { CustomDetailsStep } from '../components/CustomDetailsStep';
import { SkipDetailsStep } from '../components/SkipDetailsStep';
import { useSetFormValues } from './useSetFormValues';

export const usePresets = ({ task, form, holidays = [] }) => {
  const workdayCalendar = task.workday_calendar;

  const { setValue, unregister, clearErrors } = form;

  const { setFormValues } = useSetFormValues({ form, task, holidays });

  const presets = useMemo(() => {
    if (!task) return [];
    const today = startOfDay(new Date());
    const lastWorkingDay = getClosestWorkingTime(today, 'past', {
      workdayCalendar: workdayCalendar,
      holidays,
    });

    return [
      {
        value: 'skip',
        label: isAfter(parseDate(task.scheduled_start), lastWorkingDay)
          ? 'Starting On Time'
          : 'Not Started',
        detailsStep: <SkipDetailsStep form={form} holidays={holidays} task={task} />,
        action: () => {
          clearErrors();
          setValue(
            'effective_date',
            getClosestWorkingTime(lastWorkingDay, 'past', {
              workdayCalendar: task.workday_calendar,
              holidays,
            })
          );
          setValue('percent_complete', 0);
          // Clear the rest of the fields
          unregister('earned_days');
          unregister('earned_planned_days');
          unregister('actual_start');
          unregister('forecasted_end');
          unregister('days_remaining');
          unregister('pr');
          unregister('pace');
        },
      },
      {
        value: 'on target',
        label:
          isAfter(parseDate(task.scheduled_end), lastWorkingDay) ||
          isSameDay(parseDate(task.scheduled_end), lastWorkingDay)
            ? 'On Target'
            : 'Finished On Time',
        action: () => {
          const startDate = parseDate(task.actual_start || task.scheduled_start);
          const endDate = parseDate(task.scheduled_end);
          const effectiveDate = isAfter(parseDate(task.scheduled_end), lastWorkingDay)
            ? lastWorkingDay
            : parseDate(task.scheduled_end);

          clearErrors();
          setFormValues({ startDate, endDate, effectiveDate });
        },
      },
      {
        value: 'custom',
        label: 'Custom',
        detailsStep: <CustomDetailsStep form={form} holidays={holidays} task={task} />,
        action: () => {
          const startDate = parseDate(task.actual_start || task.scheduled_start);
          const endDate = parseDate(task.forecasted_end);
          const effectiveDate = lastWorkingDay;
          clearErrors();
          setFormValues({
            startDate,
            endDate: isBefore(lastWorkingDay, endDate) ? endDate : lastWorkingDay,
            effectiveDate,
          });
        },
      },
    ];
  }, [task, workdayCalendar, holidays, form, clearErrors, setValue, unregister, setFormValues]);

  return presets;
};
