import keyBy from 'lodash/keyBy';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import Skeleton from '@mui/material/Skeleton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { selectCurrentUser } from 'features/auth';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

import OpenInNew from '@mui/icons-material/OpenInNew';
import { parseDate } from '@blackhyve/utilities/dates';
import Autocomplete from '@mui/material/Autocomplete';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useLayoutEffect, useMemo, useRef } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  useGetProjectCompaniesQuery,
  useGetWorkspaceCompanyQuery,
} from 'features/projectCompanies/api/projectCompanies.api';
import { GridActionsCellItem, GridRowModes } from '@mui/x-data-grid';
import { useGetProjectGeneratedTagsQuery } from 'features/tags/store/tag.api';
import { ResponsibleUser } from 'features/stuckPoints/components/StuckPointTable';

import { PROCUREMENT_STATUS_COLORS } from '../components/ProcurementDetail';
import { addDays, addWeeks, differenceInDays, differenceInWeeks, isValid } from 'date-fns';
import { GroupByCompanyListBoxComponent } from 'features/contacts/components/SelectContactList';

const RenderEditLeadTimeCell = (props) => {
  const ref = useRef();
  useLayoutEffect(() => {
    if (props.hasFocus) {
      ref.current.focus();
    }
  }, [props.hasFocus]);

  return (
    <TextField
      fullWidth
      inputProps={{ min: 0 }}
      inputRef={ref}
      size="small"
      type="number"
      value={props.value}
      variant="outlined"
      sx={{
        '& .MuiInputBase-root': {
          height: '100%',
        },
      }}
      onChange={(e) => {
        const updatedValue = parseInt(e.target.value) || 0;
        const { order_date, transit_time_days } = props.row;

        props.api.setEditCellValue({
          id: props.id,
          field: props.field,
          value: updatedValue,
        });
        if (order_date && isValid(order_date)) {
          const orderDate = order_date instanceof Date ? order_date : parseDate(order_date);
          const newShipDate = addWeeks(orderDate, updatedValue);
          props.api.setEditCellValue({
            id: props.id,
            field: 'ship_date',
            value: newShipDate,
          });
          if (transit_time_days) {
            props.api.setEditCellValue({
              id: props.id,
              field: 'arrival_date',
              value: addDays(newShipDate, transit_time_days),
            });
          }
        }
      }}
    />
  );
};

const RenderEditTransitTimeCell = (props) => {
  const ref = useRef();

  useLayoutEffect(() => {
    if (props.hasFocus) {
      ref.current.focus();
    }
  }, [props.hasFocus]);

  return (
    <TextField
      fullWidth
      inputProps={{ min: 0 }}
      inputRef={ref}
      size="small"
      type="number"
      value={props.value}
      variant="outlined"
      sx={{
        '& .MuiInputBase-root': {
          height: '100%',
        },
      }}
      onChange={(e) => {
        const updatedValue = parseInt(e.target.value) || 0;
        const { ship_date } = props.row;

        props.api.setEditCellValue({
          id: props.id,
          field: props.field,
          value: updatedValue,
        });

        if (ship_date && isValid(ship_date)) {
          const newArrivalDate = addDays(parseDate(ship_date), updatedValue);
          props.api.setEditCellValue({
            id: props.id,
            field: 'arrival_date',
            value: newArrivalDate,
          });
        }
      }}
    />
  );
};

const RenderOrderOnCell = (props) => {
  const ref = useRef();
  const value = props.value
    ? props?.value instanceof Date
      ? props.value
      : parseDate(props.value)
    : undefined;

  useLayoutEffect(() => {
    if (props.hasFocus) {
      ref.current.focus();
    }
  }, [props.hasFocus]);

  return (
    <DatePicker
      {...props}
      inputRef={ref}
      value={value}
      slotProps={{
        textField: {
          size: 'small',
          fullWidth: true,
          error: props.error,
          sx: {
            '& .MuiInputBase-root': {
              height: '100%',
            },
          },
        },
      }}
      onChange={(newValue) => {
        const { lead_time_weeks, transit_time_days } = props.row;
        props.api.setEditCellValue({
          id: props.id,
          field: props.field,
          value: newValue,
        });

        if (lead_time_weeks && isValid(newValue)) {
          const newShipDate = addWeeks(newValue, lead_time_weeks);
          props.api.setEditCellValue({
            id: props.id,
            field: 'ship_date',
            value: newShipDate,
          });

          if (transit_time_days) {
            const newArrivalDate = addDays(newShipDate, transit_time_days);
            props.api.setEditCellValue({
              id: props.id,
              field: 'arrival_date',
              value: newArrivalDate,
            });
          }
        }
      }}
    />
  );
};

const RenderShipDateCell = (props) => {
  const ref = useRef();

  const value = props.value
    ? props?.value instanceof Date
      ? props.value
      : parseDate(props.value)
    : undefined;

  useLayoutEffect(() => {
    if (props.hasFocus) {
      ref.current.focus();
    }
  }, [props.hasFocus]);

  return (
    <DatePicker
      {...props}
      inputRef={ref}
      minDate={props?.row?.order_date ? props.row.order_date : null}
      value={value}
      slotProps={{
        textField: {
          size: 'small',
          error: props.error,
          fullWidth: true,
          sx: {
            '& .MuiInputBase-root': {
              height: '100%',
            },
          },
        },
      }}
      onChange={(newValue) => {
        const { order_date, transit_time_days } = props.row;
        props.api.setEditCellValue({
          id: props.id,
          field: props.field,
          value: newValue,
        });

        if (order_date && isValid(newValue)) {
          const orderDate = order_date instanceof Date ? order_date : parseDate(order_date);
          const calculateLeadTimeWeeks = differenceInWeeks(newValue, orderDate);
          props.api.setEditCellValue({
            id: props.id,
            field: 'lead_time_weeks',
            value: calculateLeadTimeWeeks,
          });
        }

        if (transit_time_days && isValid(newValue)) {
          const calculatedArrivalDate = addDays(newValue, transit_time_days);
          props.api.setEditCellValue({
            id: props.id,
            field: 'arrival_date',
            value: calculatedArrivalDate,
          });
        }
      }}
    />
  );
};

const RenderArrivalDateCell = (props) => {
  const ref = useRef();
  useLayoutEffect(() => {
    if (props.hasFocus) {
      ref.current.focus();
    }
  }, [props.hasFocus]);
  const value = props.value
    ? props?.value instanceof Date
      ? props.value
      : parseDate(props.value)
    : undefined;

  return (
    <DatePicker
      {...props}
      inputRef={ref}
      minDate={props?.row?.ship_date ? props.row.ship_date : null}
      value={value}
      slotProps={{
        textField: {
          size: 'small',
          fullWidth: true,
          sx: {
            '& .MuiInputBase-root': {
              height: '100%',
            },
          },
        },
      }}
      onChange={(newValue) => {
        const { ship_date } = props.row;
        props.api.setEditCellValue({
          id: props.id,
          field: props.field,
          value: newValue,
        });

        if (ship_date && isValid(newValue)) {
          props.api.setEditCellValue({
            id: props.id,
            field: 'transit_time_days',
            value: differenceInDays(newValue, ship_date),
          });
        }
      }}
    />
  );
};

const RenderLeaderDataCell = (props) => {
  const currentUser = useSelector((state) => selectCurrentUser(state));
  const { data: userCompany = { users: [] } } = useGetWorkspaceCompanyQuery({
    projectId: props.row.project_id,
  });

  const ref = useRef();
  useLayoutEffect(() => {
    if (props.hasFocus) {
      ref.current.focus();
    }
  }, [props.hasFocus]);

  const users = useMemo(() => {
    const users =
      currentUser.role === 'super_admin'
        ? [{ ...currentUser, isCurrentUser: true }, ...userCompany.users]
        : [...userCompany.users];
    return users;
  }, [userCompany, currentUser]);

  const handleValueChange = (event, newValue) => {
    props.api.setEditCellValue({
      id: props.id,
      field: props.field,
      value: newValue.map((user) => user?.id ?? user),
    });
  };

  return (
    <Autocomplete
      disableCloseOnSelect
      fullWidth
      multiple
      isOptionEqualToValue={(option, value) => option.id === value}
      name="leader_users"
      options={users}
      getOptionLabel={(option) =>
        option?.first_name ??
        (users?.length && users.find((user) => user.id === option)?.first_name)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={props.error}
          helperText={props.error?.message}
          inputRef={ref}
          size="small"
          sx={{
            '&.MuiFormControl-root': {
              height: '100%',
            },
            '& .MuiInputBase-root': {
              height: '100%',
            },
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <ListItem {...props} disablePadding key={option.id}>
          <ListItemIcon>
            <Checkbox checked={selected} />
          </ListItemIcon>
          <ListItemText primary={`${option.first_name} ${option.last_name}`} />
        </ListItem>
      )}
      onChange={handleValueChange}
      limitTags={1}
      // renderTags={() => null}
      value={props.value}
    />
  );
};

const RenderResponsibleDataCell = (props) => {
  const { data: companies = [], isLoading: isLoadingCompanies } = useGetProjectCompaniesQuery({
    projectId: props.row.project_id,
  });
  const { data: userCompany = { users: [] } } = useGetWorkspaceCompanyQuery({
    projectId: props.row.project_id,
  });

  const companyEntities = Object.fromEntries(
    companies?.map((company) => [company.id, company]) || []
  );

  const contacts = companies
    ?.filter((company) => company.id !== userCompany.id)
    .flatMap((company) => company.users);

  const handleValueChange = (event, newValue) => {
    if (event.key !== 'Backspace') {
      props.api.setEditCellValue({
        id: props.id,
        field: props.field,
        value: newValue.map((user) => user?.id ?? user),
      });
    }
  };

  return (
    <Autocomplete
      disableCloseOnSelect
      fullWidth
      multiple
      getOptionDisabled={(option) => (option.deleted_at ? true : false)}
      groupBy={(option) => companyEntities[option.company_id].name}
      isOptionEqualToValue={(option, value) => option.id === value}
      ListboxComponent={GroupByCompanyListBoxComponent}
      loading={isLoadingCompanies}
      options={contacts}
      value={props.value}
      getOptionLabel={(option) =>
        option?.first_name ??
        (contacts?.length && contacts.find((contact) => contact.id === option)?.name)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          error={props.error}
          helperText={props.error?.message}
          // placeholder="Select Responsible Users"
          size="small"
          variant="outlined"
          sx={{
            '&.MuiFormControl-root': {
              height: '100%',
            },
            '& .MuiInputBase-root': {
              height: '100%',
            },
          }}
        />
      )}
      renderOption={(props, option, { selected }) => {
        return (
          <ListItemButton {...props} key={option.id}>
            <ListItemIcon>
              <Checkbox checked={selected} />
            </ListItemIcon>
            <ListItemText style={option.deleted_at ? { textDecoration: 'line-through' } : {}}>
              {option?.first_name + ' ' + option?.last_name}
            </ListItemText>
          </ListItemButton>
        );
      }}
      onChange={handleValueChange}
      limitTags={1}
      // renderTags={() => null}
      size="small"
    />
  );
};

const RenderTagDropdown = (props) => {
  const ref = useRef();
  const { data: generatedTags = [], isLoading } = useGetProjectGeneratedTagsQuery(
    props.row.project_id
  );

  const handleChange = (event) => {
    props.api.setEditCellValue({
      id: props.id,
      field: props.field,
      value: [event.target.value],
    });
  };

  useLayoutEffect(() => {
    if (props.hasFocus) {
      ref.current.focus();
    }
  }, [props.hasFocus]);

  return (
    <TextField
      fullWidth
      select
      inputRef={ref}
      sx={{ '.MuiInputBase-root': { height: 1 } }}
      value={props?.value?.length ? props?.value?.map((tag) => tag?.id || tag) : []}
      onChange={handleChange}
    >
      {generatedTags?.map((tag) => {
        return (
          <MenuItem key={tag.id} value={tag.id}>
            {tag.name}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

/**
 * Company Contacts
 * @param {[string]} contactIds
 * @returns
 */
const CompanyContacts = ({ projectId, contactIds }) => {
  const { data: companies = [], isLoading } = useGetProjectCompaniesQuery({ projectId });
  const contacts = keyBy(
    companies?.flatMap((company) => company.users),
    'id'
  );

  if (isLoading) {
    return <Skeleton height={50} width={100} />;
  }

  return (
    <Box display="inline">
      {contactIds?.map((contactId, index) => {
        const contact = contacts[contactId];
        return (
          <Typography component="span" key={contactId} variant="subtitle2">
            {contact?.name}
            {contactIds?.length > index + 1 ? ', ' : ''}
          </Typography>
        );
      })}
    </Box>
  );
};

export const getProcurementDataGridColumns = (
  rowModesModel,
  setRowModesModel,
  rows,
  setTempRows,
  handleDelete,
  handleDetailPage
) => {
  return [
    {
      field: 'actions',
      type: 'actions',
      width: 50,
      getActions: (props) => {
        const isInEditMode = rowModesModel[props.id]?.mode === GridRowModes.Edit;

        const handleCancelClick = () => {
          setRowModesModel({
            ...rowModesModel,
            [props.id]: { mode: GridRowModes.View, ignoreModifications: true },
          });

          const editedRow = rows.find((row) => row.id === props.id);
          if (editedRow.isNew) {
            setTempRows((tempRows) => {
              return tempRows.filter((row) => row.id !== props.id);
            });
          }
        };

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              className="textPrimary"
              color="inherit"
              icon={<CancelIcon />}
              label="Cancel"
              onClick={handleCancelClick}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            showInMenu
            icon={<OpenInNew />}
            label="Details"
            onClick={() => handleDetailPage(props.id)}
          />,
          <GridActionsCellItem
            showInMenu
            icon={<DeleteIcon color="error" />}
            label="Delete"
            onClick={() => handleDelete(props.id)}
          />,
        ];
      },
    },
    {
      field: 'order_number',
      headerName: 'No',
      editable: true,
      width: 80,
    },
    {
      headerName: 'Name',
      field: 'name',
      width: 250,
      editable: true,
    },
    {
      headerName: 'Description',
      field: 'description',
      width: 200,
      editable: true,
    },
    {
      headerName: 'Category',
      field: 'category',
      width: 120,
      editable: true,
    },
    {
      headerName: 'LBS',
      field: 'tags',
      width: 200,
      editable: true,
      renderCell: (props) => {
        return (
          <Box display="inline">
            {props.value?.map((tag, index) => {
              return (
                <Typography component="span" key={tag.id} variant="subtitle2">
                  {tag.name}
                </Typography>
              );
            })}
          </Box>
        );
      },
      renderEditCell: (params) => {
        return <RenderTagDropdown {...params} />;
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 100,
      editable: true,
      type: 'singleSelect',
      valueOptions: Object.keys(PROCUREMENT_STATUS_COLORS),
      renderCell: (params) => {
        return (
          <Chip
            color={PROCUREMENT_STATUS_COLORS[params?.value]}
            label={params?.value}
            size="small"
            sx={{ '& .MuiChip-label': { mt: '1px' } }}
            variant="contained"
          />
        );
      },
      preProcessEditCellProps: (params) => {
        const hasError = !params.props.value;
        return { ...params.props, error: hasError };
      },
    },
    {
      headerName: 'Ordered On',
      field: 'order_date',
      width: 150,
      type: 'date',
      editable: true,
      valueGetter: (value) => value ? parseDate(value) : '',
      valueFormatter: (value) => {
        return value && parseDate(value).toLocaleDateString();
      },
      renderEditCell: (params) => {
        return <RenderOrderOnCell {...params} />;
      },
      preProcessEditCellProps: (params) => {
        const value = params.props.value;
        const hasError = !value || !isValid(value);
        return { ...params.props, error: hasError };
      },
    },
    {
      headerName: 'Lead Time',
      field: 'lead_time_weeks',
      width: 90,
      editable: true,
      valueFormatter: (value, row) => {
        return `${value ? value : 0} Week`;
      },
      renderEditCell: (params) => {
        return <RenderEditLeadTimeCell {...params} />;
      },
    },
    {
      headerName: 'Ship Date',
      field: 'ship_date',
      width: 150,
      type: 'date',
      editable: true,
      valueGetter: (value) => value ? parseDate(value) : '',
      valueFormatter: (value) => {
        return value && parseDate(value).toLocaleDateString();
      },
      renderEditCell: (params) => {
        return <RenderShipDateCell {...params} />;
      },
    },
    {
      headerName: 'Transit Time',
      label: 'Transit Days',
      field: 'transit_time_days',
      editable: true,
      width: 90,
      valueFormatter: (value, row) => {
        return `${value ? value : 0} Days`;
      },
      renderEditCell: (params) => {
        return <RenderEditTransitTimeCell {...params} />;
      },
    },
    {
      headerName: 'Arrival Date',
      field: 'arrival_date',
      width: 150,
      type: 'date',
      editable: true,
      valueGetter: (value) => value ? parseDate(value) : '',
      valueFormatter: (value, row) => {
        return value && parseDate(value).toLocaleDateString();
      },
      renderEditCell: (params) => {
        return <RenderArrivalDateCell {...params} />;
      },
    },
    {
      headerName: 'Cost',
      field: 'cost',
      width: 120,
      editable: true,
      valueFormatter: (value) => {
        return value ? `$${value}` : '';
      },
    },
    {
      headerName: 'Vendor',
      field: 'vendor',
      width: 180,
      editable: true,
    },
    {
      headerName: 'Vendor Contact',
      field: 'vendor_contact',
      width: 180,
      editable: true,
    },
    {
      headerName: 'Leaders',
      field: 'leader_users',
      width: 250,
      editable: true,
      renderCell: (params) => {
        return <ResponsibleUser projectId={params.row.project_id} userIds={params.value} />;
      },
      renderEditCell: (params) => {
        return <RenderLeaderDataCell {...params} />;
      },
    },
    {
      headerName: 'Responsible',
      field: 'responsible_users',
      width: 250,
      editable: true,
      renderCell: (params) => {
        return <CompanyContacts contactIds={params.value} projectId={params.row.project_id} />;
      },
      renderEditCell: (params) => {
        return <RenderResponsibleDataCell {...params} />;
      },
    },
  ];
};
