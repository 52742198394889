import { differenceInWeeks, endOfWeek, format, startOfWeek } from 'date-fns';

const numberToWord = {
  0: 'zero',
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
};

export const scales = [
  {
    unit: 'week',
    step: 1,
    css: function (date) {
      const today = startOfWeek(new Date());
      const weeksOut = differenceInWeeks(date, today);
      return weeksOut >= 0 ? `${numberToWord[weeksOut]}-weeks-outs` : '';
    },
    format: function (date) {
      const today = startOfWeek(new Date());
      const endDate = endOfWeek(date, { weekStartsOn: 1 });
      const weeksOut = differenceInWeeks(date, today);
      return `
        <strong>Weeks Out: ${weeksOut} </strong> &nbsp; ${format(date, 'MMM')}-${format(date, 'd')} - ${format(endDate, 'MMM')}-${format(endDate, 'd')}
      `;
    },
  },
  {
    unit: 'day',
    step: 1,
    format: function (date) {
      return format(date, 'eeeeee') + ' ' + format(date, 'dd');
    },
  },
];
