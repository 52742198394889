import { differenceInWorkdays, parseDate } from '@blackhyve/utilities/dates';
import { differenceInDays } from 'date-fns';
import { getProductionRatingFromEndDate } from 'features/tasks/utils/getProductionRating';
import { useCallback } from 'react';

export const useSetFormValues = ({ form, task, holidays }) => {
  const { setValue } = form;

  const setFormValues = useCallback(
    ({ startDate, endDate, effectiveDate }) => {
      const { earnedDays, earnedPlannedDays, productionRating, percentComplete } =
        getProductionRatingFromEndDate({
          startDate,
          endDate,
          scheduledDuration: task.duration_days,
          effectiveDate,
          workdayCalendar: task.workday_calendar,
          holidays,
        });
      setValue('actual_start', startDate, { shouldDirty: true });
      setValue('effective_date', effectiveDate, { shouldDirty: true });
      setValue('forecasted_end', endDate, { shouldDirty: true });
      setValue(
        'days_remaining',
        differenceInWorkdays(effectiveDate, endDate, {
          workdayCalendar: task.workday_calendar,
          holidays,
        })
      );
      setValue('earned_days', earnedDays);
      setValue('earned_planned_days', earnedPlannedDays);
      setValue('pr', productionRating.toFixed(2), { shouldDirty: true });
      setValue('pace', differenceInDays(parseDate(task.scheduled_end), endDate), {
        shouldDirty: true,
      });
      setValue('percent_complete', percentComplete, { shouldDirty: true });
    },
    [holidays, setValue, task.duration_days, task.scheduled_end, task.workday_calendar]
  );

  return { setFormValues };
};
