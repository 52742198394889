export function isChildOf(taskId, parentId, gantt) {
  if (gantt.isTaskExists(taskId)) {
    let nextParentId = gantt.getTask(taskId).parent;
    // Traverse up tree from taskId
    while (nextParentId != parentId && gantt.isTaskExists(nextParentId)) {
      nextParentId = gantt.getTask(nextParentId).parent;
    }
    return nextParentId == parentId;
  } else {
    return false;
  }
}
