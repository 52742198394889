const { differenceInDays } = require('date-fns');
const { topologicalSort } = require('./topologicalSort');
const { calculateLinkLag } = require('../tasks');

/**
 * Updates the float values (free float and total float) for tasks in the Gantt chart.
 * @param {string} projectId - The ID of the project.
 * @param {Object} gantt - The Gantt chart object.
 * @returns {number[]} - An array of task IDs that were updated.
 */
function updateFloat(projectId, shouldUpdate, gantt) {
  const tasks = gantt.getTaskBy((task) =>
    projectId
      ? task?.project_id == projectId
      : task.type !== 'placeholder' && task.forecasted_end_date
  );
  // Calculate the maximum end date among tasks in the project
  const maxEndDate = new Date(
    tasks.reduce((maxEnd, task) => Math.max(maxEnd, +task.forecasted_end_date), -Infinity)
  );
  const updatedFreeFloatTaskIds = updateFreeFloat(projectId, maxEndDate, gantt);
  const updatedTotalFloatTaskIds = updateTotalFloat(projectId, maxEndDate, gantt);
  const updatedTaskIds = [...new Set([...updatedFreeFloatTaskIds, ...updatedTotalFloatTaskIds])];
  if (shouldUpdate) {
    gantt.batchUpdate(() => {
      for (let index = 0; index < updatedTaskIds.length; index++) {
        gantt.updateTask(updatedTaskIds[index]);
      }
    });
  }
  gantt.callEvent('onAfterUpdateFloat', [updatedTaskIds]);
  // Update both free float and total float and return updated task IDs
  return updatedTaskIds;
}

/**
 * Updates the free float values for tasks in the Gantt chart.
 * @param {string} projectId - The ID of the project.
 * @param {Date} maxEndDate - The maximum end date among tasks in the project.
 * @param {Object} gantt - The Gantt chart object.
 * @returns {number[]} - An array of task IDs that had their free float updated.
 */
function updateFreeFloat(projectId, maxEndDate, gantt) {
  const updatedTaskIds = [];
  const tasks = gantt.getTaskBy((task) =>
    projectId
      ? task?.project_id == projectId
      : task.type !== 'placeholder' && task.forecasted_end_date
  );

  tasks.forEach((task) => {
    const freeFloat = calculateTaskFreeFloat(task.id, maxEndDate, gantt);
    if (task.free_float !== freeFloat) {
      task.free_float = freeFloat;
      updatedTaskIds.push(task.id);
    }
  });
  return updatedTaskIds;
}

/**
 * Calculates the free float for a specific task in the Gantt chart.
 * @param {number} taskId - The ID of the task.
 * @param {Date} projectEndDate - The end date of the entire project.
 * @param {Object} gantt - The Gantt chart object.
 * @returns {number} - The calculated free float for the task.
 */
function calculateTaskFreeFloat(taskId, projectEndDate, gantt) {
  const task = gantt.getTask(taskId);
  let freeFloat = differenceInDays(projectEndDate, task.forecasted_end_date);

  // Iterate through task dependencies (links) to calculate free float
  task.$source.forEach((linkId) => {
    const link = gantt.getLink(linkId);
    freeFloat = Math.min(Math.max(calculateLinkLag(link, gantt) || 0, 0), freeFloat);
  });

  // Check distance to end of parent if the task has a parent
  if (task.parent > 0) {
    const parent = gantt.getTask(task.parent);
    const float = differenceInDays(parent.forecasted_end_date, task.forecasted_end_date);
    freeFloat = Math.min(float, freeFloat);
  }
  return freeFloat;
}

/**
 * Updates the total float values for tasks in the Gantt chart.
 * @param {string} projectId - The ID of the project.
 * @param {Date} maxEndDate - The maximum end date among tasks in the project.
 * @param {Object} gantt - The Gantt chart object.
 * @returns {number[]} - An array of task IDs that had their total float updated.
 */
function updateTotalFloat(projectId, maxEndDate, gantt) {
  const updatedTaskIds = [];
  const orderedTasks = topologicalSort(gantt, projectId);

  function calculateSuccessorTotalFloat(task) {
    let totalFloat = differenceInDays(maxEndDate, task.forecasted_end_date);
    task.$source.forEach((linkId) => {
      const link = gantt.getLink(linkId);
      if (gantt.isTaskExists(link.target)) {
        const lag = calculateLinkLag(link, gantt) || 0;
        const float = gantt.getTask(link.target).$total_float + Math.max(lag, 0);
        totalFloat = Math.min(float, totalFloat);
      }
    });
    task.$total_float = totalFloat;
  }

  // Iterate through tasks in topological order and calculate total float
  orderedTasks.forEach((task) => calculateSuccessorTotalFloat(task));

  // Update total float for each task and return updated task IDs
  orderedTasks.forEach((task) => {
    calculateSuccessorTotalFloat(task);
    let totalFloat = task.$total_float;
    if (task.parent > 0) {
      const parent = gantt.getTask(task.parent);
      const parentFloat =
        gantt.getTask(parent.id).$total_float +
        differenceInDays(parent.forecasted_end_date, task.forecasted_end_date);
      totalFloat = Math.min(parentFloat, totalFloat);
    }
    task.$total_float = totalFloat;
    if (task.total_float !== task.$total_float) {
      task.total_float = task.$total_float;
      updatedTaskIds.push(task.id);
    }
    // delete task.$total_float;
  });
  return updatedTaskIds;
}

module.exports = { updateFloat };
