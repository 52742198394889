import { api, providesList } from 'api';

export const FIELD_VALUES_TAG = 'FIELD_VALUES';

export const fieldValueAPI = api
  .enhanceEndpoints({ addTagTypes: [FIELD_VALUES_TAG] })
  .injectEndpoints({
    endpoints: (build) => ({
      getFieldValue: build.query({
        query: (name) => `/field_values?field=${name}`,
        providesTags: (result) => (result ? providesList(result, FIELD_VALUES_TAG) : []),
        transformResponse: (response) => response,
      }),
    }),
  });

export const { useGetFieldValueQuery } = fieldValueAPI;
