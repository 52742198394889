import { Gantt } from '@blackhyve/dhtmlx-gantt';
const ganttStore = (function () {
  const gantts = {};
  return {
    createGantt: function (name) {
      if (!this.getGantt(name)) {
        gantts[name] = Gantt.getGanttInstance();
      }
      return this.getGantt(name);
    },
    getGantt: function (name) {
      return gantts[name];
    },
    deleteInstance: function (name) {
      if (gantts.hasOwnProperty(name)) {
        delete gantts[name];
      }
    },
  };
})();

// Made this global so that we could call it from within the Gantt component
// in order to easily show/hide the resources panel in setupLayout.ts
window.ganttStore = ganttStore;
export default ganttStore;
