import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import CommentContainer from 'features/comments/components/CommentContainer';

/**
 * Comment Tab
 * @returns
 */
export const CommentTab = ({ taskId }) => {
  return (
    <Grid container>
      <Grid container item alignItems={'center'} xs={12}>
        <IconButton disableRipple size="large">
          <CommentIcon fontSize="inherit" />
        </IconButton>
        <Typography sx={{ fontWeight: '600' }} variant="h6">
          Comments
        </Typography>
      </Grid>
      <Grid item component={Paper} elevation={3} sx={{ pb: 2 }} xs={12}>
        <CommentContainer entityId={taskId} entityTag="TaskActivity" entityType="tasks" />
      </Grid>
    </Grid>
  );
};

export default CommentTab;
