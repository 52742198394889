import { MoreHoriz } from '@mui/icons-material';
import { IconButton, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Popover } from 'components/Popover';

const defaultIconButtonProps = {
  size: 'small',
};

export const OptionsMenu = ({ trigger, slotProps = {}, children }) => {
  const iconButtonProps = { ...defaultIconButtonProps, ...slotProps?.iconButton };
  const listProps = { ...slotProps?.list };
  const popoverProps = { ...slotProps?.popover };
  return (
    <Popover
      trigger={
        trigger || (
          <IconButton {...iconButtonProps}>
            <MoreHoriz />
          </IconButton>
        )
      }
      {...popoverProps}
    >
      {({ handleClose }) => (
        <List {...listProps}>
          {typeof children === 'function' ? children({ handleClose }) : children}
        </List>
      )}
    </Popover>
  );
};

const defaultListItemProps = { dense: true };

export const OptionsMenuItem = ({ icon, onClick, children, slotProps = {} }) => {
  const listItemProps = { ...defaultListItemProps, ...slotProps.listItem };
  const listItemIconProps = { ...slotProps.listItemIcon };
  const listItemTextProps = { ...slotProps.listItemText };

  return (
    <ListItemButton onClick={onClick} {...listItemProps}>
      <ListItemIcon {...listItemIconProps}>{icon}</ListItemIcon>
      <ListItemText {...listItemTextProps} primary={children} />
    </ListItemButton>
  );
};
