import { useEffect } from 'react';

export const useGanttLayers = (gantt, layers, options = {}) => {
  const { skip } = options;
  // Add layers
  useEffect(() => {
    const removeLayers = () => {
      layers.forEach((layer) => {
        gantt.removeTaskLayer(layer.id);
        if (layer.events) {
          layer.events.forEach(({ key: event, callback }, index) => {
            gantt.detachEvent(`${layer.id}-${event}-${index}`);
          });
        }
      });
    };

    if (layers && gantt && !gantt.$destroyed && !skip) {
      const addLayers = () => {
        layers.forEach((layer) => {
          gantt.addTaskLayer({ ...layer });
          if (layer.events) {
            layer.events.forEach(({ event, callback }, index) => {
              gantt.attachEvent(event, callback, { id: `${layer.id}-${event}-${index}` });
            });
          }
        });
      };

      if (gantt.$root) {
        addLayers();
      }
      gantt.attachEvent('onGanttReady', addLayers, { id: 'addLayers' });
      if (gantt.$root) {
        gantt.render();
      }
    }

    // Clean up layers
    return () => {
      if (gantt && !gantt.$destroyed) {
        gantt.detachEvent('addLayers');
        removeLayers();
      }
    };
  }, [gantt, layers, skip]);
};
