export interface ResourceAssignment {
  id: number;
  task_id: number;
  resource_id: number;
  value?: number;
}

export interface Resource {
  id: number;
  name: string;
  type: ResourceType;
  unit?: string;
  max_capacity?: number;
  workday_calendar?: any;
  parents: number[];
}
export enum ResourceType {
  Labor = 'labor',
  Equipment = 'equipment',
  Group = 'group',
}

export enum ResourceUnit {
  Hours = 'hours',
}
