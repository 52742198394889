import { Line } from 'react-chartjs-2';
import { useGetCrewSizeQuery } from '../api/analytics';
import { useParams } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { FilterList } from '@mui/icons-material';
import { useGetProjectGeneratedTagsQuery } from '../../tags/store/tag.api';

// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// Function to generate colors with good contrast
const generateColors = (count) => {
  const colors = [];
  const baseColors = [
    '#2563eb',
    '#16a34a',
    '#ea580c',
    '#9333ea',
    '#0891b2',
    '#be123c',
    '#84cc16',
    '#eab308',
    '#ec4899',
    '#6366f1',
  ];

  // First, use the predefined colors
  colors.push(...baseColors);

  // If we need more colors, generate them using HSL
  if (count > baseColors.length) {
    const additionalCount = count - baseColors.length;
    for (let i = 0; i < additionalCount; i++) {
      // Use golden ratio to spread hues evenly
      const hue = (i * 137.508) % 360; // Golden angle approximation
      const saturation = 65 + (i % 20); // Vary saturation slightly
      const lightness = 45 + (i % 15); // Vary lightness slightly
      colors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
    }
  }

  return colors;
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      mode: 'nearest',
      intersect: true,
      backgroundColor: 'rgba(255, 255, 255, 0.95)',
      titleColor: '#333',
      titleFont: {
        size: 13,
        weight: 'normal',
      },
      bodyColor: '#666',
      bodyFont: {
        size: 12,
      },
      boxPadding: 12,
      borderColor: '#ddd',
      usePointStyle: true,
      borderWidth: 1,
      padding: 15,
      itemSort: () => -1,
      callbacks: {
        title: (tooltipItems) => {
          if (tooltipItems.length === 0) return '';
          return new Date(tooltipItems[0].label).toLocaleDateString();
        },
        label: (context) => {
          return `${context.dataset.label}: ${context.parsed.y} crew members`;
        },
        afterBody: () => null,
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        maxRotation: 45,
        minRotation: 45,
        padding: 10,
        font: {
          size: 13,
        },
      },
    },
    y: {
      beginAtZero: true,
      border: {
        display: false,
      },
      grid: {
        color: '#e5e7eb',
      },
      ticks: {
        padding: 10,
        font: {
          size: 13,
        },
      },
    },
  },
};

export const ForecastedCrewSize = () => {
  const { projectId } = useParams();
  const { data: tags } = useGetProjectGeneratedTagsQuery(projectId);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedSmartKey, setSelectedSmartKey] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {
    data: crewSizeData,
    isLoading,
    isError,
  } = useGetCrewSizeQuery({ projectId, tagId: selectedTag || undefined });

  useEffect(() => {
    if (crewSizeData?.companies) {
      setSelectedCompanies(crewSizeData.companies.map((company) => company.id));
    }
  }, [crewSizeData]);

  useEffect(() => {
    if (!selectedSmartKey) {
      setSelectedTag('');
    }
  }, [selectedSmartKey]);

  const smartKeys = useMemo(() => {
    if (!tags) return [];
    const keys = new Set(tags.map((tag) => tag.smart_key));
    return Array.from(keys).sort();
  }, [tags]);

  // Get tags for selected smart_key
  const availableTags = useMemo(() => {
    if (!selectedSmartKey) return [];
    if (!tags) return [];
    return tags
      .filter((tag) => tag.smart_key === selectedSmartKey)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [selectedSmartKey, tags]);

  // Calculate chart width based on number of data points
  const chartWidth = useMemo(() => {
    if (!crewSizeData?.dataset) return 1000;
    const minWidthPerPoint = 25;
    const calculatedWidth = crewSizeData.dataset.length * minWidthPerPoint;
    return Math.max(1000, calculatedWidth);
  }, [crewSizeData?.dataset]);

  // Get unique dates for x-axis
  const dates = useMemo(() => {
    if (!crewSizeData?.dataset) return [new Date().toLocaleDateString()];
    return crewSizeData.dataset.map((item) => {
      const dateStr = item.date.split('T')[0] + 'T12:00:00';
      return new Date(dateStr).toLocaleDateString();
    });
  }, [crewSizeData?.dataset]);

  // Generate colors based on number of companies
  const colors = useMemo(() => {
    if (!crewSizeData?.companies) return [];
    return generateColors(crewSizeData.companies.length);
  }, [crewSizeData?.companies]);

  // Calculate totals based on selected companies
  const datasets = useMemo(() => {
    if (!crewSizeData?.companies || !crewSizeData?.dataset || crewSizeData.dataset.length === 0) {
      return [
        {
          label: 'No Data',
          data: [0],
          borderColor: '#e5e7eb',
          backgroundColor: '#e5e7eb',
        },
      ];
    }
    const companyDatasets = crewSizeData.companies.map((company, index) => ({
      label: company.name,
      data: crewSizeData.dataset.map((item) => item[company.id]),
      borderColor: colors[index],
      backgroundColor: colors[index],
      hidden: !selectedCompanies.includes(company.id),
      tension: 0.1,
      borderWidth: 2,
      order: 1,
    }));

    const totalData = crewSizeData.dataset.map((item) => {
      return selectedCompanies
        .filter((id) => id !== 'total') // Don't include 'total' in the calculation
        .reduce((sum, companyId) => {
          return sum + (item[companyId] || 0);
        }, 0);
    });

    const totalDataset = {
      label: 'Total Crew Size',
      data: totalData,
      borderColor: '#000000',
      backgroundColor: '#000000',
      tension: 0.1,
      borderWidth: 2,
      borderDash: [5, 5],
      order: 0,
      hidden: !selectedCompanies.includes('total'),
    };

    return [...companyDatasets, totalDataset];
  }, [crewSizeData, selectedCompanies, colors]);

  const chartData = {
    labels: dates,
    datasets: datasets,
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleCompanyToggle = (companyId) => {
    setSelectedCompanies((current) => {
      if (current.includes(companyId)) {
        return current.filter((id) => id !== companyId);
      } else {
        return [...current, companyId];
      }
    });
  };

  const handleToggleAll = () => {
    if (selectedCompanies.length > 0) {
      setSelectedCompanies([]);
    } else {
      setSelectedCompanies(crewSizeData.companies.map((company) => company.id));
    }
  };

  if (isLoading) {
    return (
      <Box alignItems="center" display="flex" height={300} justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (isError || !crewSizeData) {
    return (
      <Typography align="center" color="error" variant="body1">
        Unable to load crew size data
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        padding: '16px',
        backgroundColor: '#ffffff',
        borderRadius: '4px',
        height: '700px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Filter Icon */}
      <Box sx={{ alignSelf: 'flex-end' }}>
        <IconButton
          size="small"
          sx={{
            backgroundColor: open ? '#f3f4f6' : 'transparent',
            '&:hover': { backgroundColor: '#f3f4f6' },
          }}
          onClick={handleFilterClick}
        >
          <FilterList fontSize="small" />
        </IconButton>
      </Box>

      {/* Filter Popover */}
      <Popover
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleFilterClose}
      >
        {/* Filter Controls */}
        <Box sx={{ p: 3, display: 'flex', gap: 2, width: '400px' }}>
          <FormControl size="small" sx={{ flex: 1 }}>
            <InputLabel size="small">Filter Type</InputLabel>
            <Select
              label="Filter Type"
              value={selectedSmartKey}
              onChange={(e) => setSelectedSmartKey(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {smartKeys.map((key) => (
                <MenuItem key={key} value={key}>
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl disabled={!selectedSmartKey} size="small" sx={{ flex: 1 }}>
            <InputLabel size="small">{selectedSmartKey || 'Select Type First'}</InputLabel>
            <Select
              label={selectedSmartKey || 'Select Type First'}
              value={selectedTag}
              onChange={(e) => setSelectedTag(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {availableTags.map((tag) => (
                <MenuItem key={tag.id} value={tag.id}>
                  {tag.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Popover>

      {/* Chart Area */}
      <Box
        sx={{
          flexGrow: 1,
          overflowX: 'auto',
          overflowY: 'hidden',
          '&::-webkit-scrollbar': {
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888888',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: '#666666',
            },
          },
        }}
      >
        <Box
          sx={{
            height: '550px',
            width: `${chartWidth}px`,
            paddingBottom: '24px',
          }}
        >
          <Line data={chartData} height={560} options={options} width={chartWidth} />
        </Box>
      </Box>

      {/* Legend Area */}
      <Box
        sx={{
          marginTop: '24px',
          height: '60px',
          display: 'flex',
          justifyContent: 'center',
          gap: '24px',
          flexWrap: 'wrap',
          paddingLeft: '16px',
          paddingRight: '16px',
        }}
      >
        {/* Toggle All Button */}
        <Box
          sx={{
            display: 'flex',
            height: '12px',
            alignItems: 'center',
            gap: '8px',
            cursor: 'pointer',
            marginRight: '24px',
            padding: '4px 12px',
            borderRadius: '4px',
            backgroundColor: '#f3f4f6',
            '&:hover': {
              backgroundColor: '#e5e7eb',
            },
          }}
          onClick={handleToggleAll}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: '14px',
              lineHeight: '20px',
              fontWeight: 500,
            }}
          >
            Toggle All
          </Typography>
        </Box>

        {/* Company Legend Items */}
        {datasets.map((dataset, index) => {
          const isTotal = index === datasets.length - 1;
          return (
            <Box
              key={dataset.label}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                cursor: 'pointer',
                opacity: dataset.hidden ? 0.2 : 1,
                transition: 'opacity 0.2s ease',
                '&:hover': {
                  opacity: dataset.hidden ? 0.2 : 0.5,
                },
              }}
              onClick={() => {
                if (isTotal) {
                  handleCompanyToggle('total');
                } else {
                  handleCompanyToggle(crewSizeData.companies[index].id);
                }
              }}
            >
              <Box
                sx={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor: dataset.borderColor,
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  fontSize: '14px',
                  lineHeight: '20px',
                }}
              >
                {dataset.label}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
