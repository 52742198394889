import isEmpty from 'lodash/isEmpty';
const { api, providesList } = require('api');

export const PROCUREMENT_TAG = 'Procurement';

const procurementAPI = api.enhanceEndpoints({ addTagTypes: [PROCUREMENT_TAG] }).injectEndpoints({
  endpoints: (build) => ({
    createProcurement: build.mutation({
      query: ({ projectId, ...body }) => ({
        url: `/projects/${projectId}/procurements`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error) =>
        result ? [{ id: 'LIST', type: PROCUREMENT_TAG }] : [],
    }),
    updateProcurement: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/procurements/${id}/`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (result, error, { id }) =>
        result ? [{ type: PROCUREMENT_TAG, id }] : [],
    }),
    getProcurements: build.query({
      query: ({ projectId, ...queryParam }) => ({
        url: `/projects/${projectId}/procurements`,
        params: !isEmpty(queryParam) ? queryParam : {}
      }),
      providesTags: (result) => (result?.data ? providesList(result.data, PROCUREMENT_TAG) : []),
      transformResponse: (response) => response
    }),
    getProcurement: build.query({
      query: (id) => ({
        url: `/procurements/${id}`,
      }),
      providesTags: (result, error, args) =>
        result ? [{ type: PROCUREMENT_TAG, id: result?.id }] : [],
      transformResponse: (response) => response.data,
    }),
    deleteProcurement: build.mutation({
      query: (id) => ({
        url: `/procurements/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, params) =>
        result ? [{ type: 'Procurement', id: params.id }] : [],
    }),
    getProcurementTasks: build.query({
      query: ({ id }) => ({
        url: `/procurements/${id}/tasks`
      }),
      transformResponse: (response) => response.data
    }),
    getTaskProcurements: build.query({
      query: ({ taskId, queryParam }) => ({
        url: `/tasks/${taskId}/procurements`,
        params: !isEmpty(queryParam) ? queryParam : {}
      }),
      providesTags: (result) => (result ? providesList(result, PROCUREMENT_TAG) : []),
      transformResponse: (response) => response.data
    }),
  }),
});

export const {
  useGetProcurementQuery,
  useGetProcurementsQuery,
  useCreateProcurementMutation,
  useUpdateProcurementMutation,
  useDeleteProcurementMutation,
  useGetProcurementTasksQuery,
  useGetTaskProcurementsQuery

} = procurementAPI;
