import { useState, memo, useContext } from 'react';
import format from 'date-fns/format';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import Popper from '@mui/material/Popper';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { isToday, isYesterday } from 'date-fns';
import FormLabel from '@mui/material/FormLabel';
import Accordion from '@mui/material/Accordion';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import AvatarGroup from '@mui/material/AvatarGroup';
import ListItemIcon from '@mui/material/ListItemIcon';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { parseDate } from '@blackhyve/utilities/dates';
import { JobwalkTaskDetails } from './JobwalkTaskDetails';
import SvgMeterIcon from '@blackhyve/common/src/icons/Meter';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import VerifiedIcon from '@mui/icons-material/Verified';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import BuildingIconComponent from '@blackhyve/common/src/icons/Building';
import { Label as LabelComponent } from 'features/labels/components/Label';
import StuckPointList from '../../stuckPoints/components/StuckPointActiveList';
import AreaPolygonIconComponent from '@blackhyve/common/src/icons/AreaPolygon';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import LocationLevelIconComponent from '@blackhyve/common/src/icons/LocationLevel';
import { ProductionRating as ProductionRatingIcon } from 'components/icons/ProductionRating';
import { EditContext } from 'components/common/v3/DisplayWithEditAccess';
import { JobwalkInput } from 'features/jobwalkInput/routes/JobwalkInput';

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    marginLeft: theme.spacing(1),
  },
}));

/**
 * Jobwalk Row
 * @param {Object} task
 * @returns
 */
const JobwalkRow = memo(({ task, meta }) => {
  const canEdit = useContext(EditContext);
  const [startJobwalkStatus, setStartJobwalkStatus] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleOnSuccessOfJobwalk = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <Accordion
      square
      expanded={expanded}
      slotProps={{ transition: { timeout: 600 } }}
      slots={{ transition: Fade }}
      sx={{
        '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
        '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
      }}
      onChange={handleExpansion}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container item>
          <Grid container item md={9} sm={6}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{task?.name}</Typography>
            </Grid>

            <Grid container item gap={1} xs={12}>
              {task?.location?.name ? (
                <Stack color={'grey.600'} direction="row">
                  <Stack alignItems={'center'} direction={'row'}>
                    <BuildingIconComponent fontSize="small" />
                    <Typography mx={1} variant="subtitle2">
                      {task?.location?.name || '-'}
                    </Typography>
                  </Stack>
                  <Stack alignItems={'center'} direction={'row'}>
                    <LocationLevelIconComponent fontSize="small" />
                    <Typography mx={1} variant="subtitle2">
                      {task?.zone?.name || '-'}
                    </Typography>
                  </Stack>
                  <Stack alignItems={'center'} direction={'row'}>
                    <AreaPolygonIconComponent fontSize="small" />
                    <Typography mx={1} variant="subtitle2">
                      {task?.area?.name || '-'}
                    </Typography>
                  </Stack>
                </Stack>
              ) : null}
              <Stack color={'grey.600'} direction={'row'}>
                <Stack alignItems={'center'} direction={'row'}>
                  <ProductionRatingIcon fontSize={'small'} />
                  <Typography mx={1} sx={{ marginLeft: '3px' }} variant="subtitle2">
                    {task?.pr ? parseFloat(task.pr * 100).toFixed(0) : '-'}
                  </Typography>
                </Stack>
                <Stack alignItems={'center'} direction={'row'}>
                  <SvgMeterIcon fontSize={'small'} />
                  <Typography mx={1} sx={{ marginLeft: '5px' }} variant="subtitle2">
                    {task?.pace > 0 && '+'}
                    {task?.pace}
                  </Typography>
                </Stack>
                <Stack alignItems={'center'} direction={'row'}>
                  <Typography variant="subtitle2">
                    % {task?.percent_complete ? parseInt(task.percent_complete * 100) : 0}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>

            {task?.responsible_users?.length ? (
              <Grid container item alignItems="center" gap={1} sx={{ marginBottom: '2px' }} xs={12}>
                <AssignedMembers members={task.responsible_users} />
              </Grid>
            ) : null}
            {task?.companies?.length ? (
              <Grid container item alignItems="center" gap={1} xs={12}>
                <AssignedCompanies companies={task.companies} />
              </Grid>
            ) : null}
          </Grid>
          <Grid container item alignItems="center" gap={1} justifyContent="flex-end" md={3} sm={4}>
            {task?.open_stuck_points_count > 0 && (
              <StuckPointIcon value={task?.open_stuck_points_count} />
            )}
            <JobwalkClosed value={task?.latest_jobwalk?.created_at} />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: '#fafafa' }}>
        <Grid container spacing={1}>
          <Grid container item md={6} sm={12} spacing={2}>
            <Grid item xs={12}>
              <JobwalkTaskDetails meta={meta} task={task} />
            </Grid>
          </Grid>
          {canEdit && (
            <Grid item md={6} sm={12} style={{ position: 'relative' }}>
              <Paper
                elevation={3}
                sx={{
                  opacity: startJobwalkStatus ? 1 : 0.2,
                  pointerEvents: !startJobwalkStatus && 'none',
                }}
              >
                <JobwalkInput
                  holidays={meta.holidays}
                  shouldAutoschedule={true}
                  task={task}
                  onSuccess={handleOnSuccessOfJobwalk}
                />
              </Paper>
              {!startJobwalkStatus && (
                <Button
                  startIcon={<LockOpenIcon fontSize="small" />}
                  variant="contained"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '40%',
                    transform: 'translate(-50 %, -50 %)',
                  }}
                  onClick={() => setStartJobwalkStatus(true)}
                >
                  Start Jobwalk
                </Button>
              )}
            </Grid>
          )}
          <Grid container item xs={12}>
            {expanded ? <StuckPointList projectId={task.project_id} taskId={task.id} /> : null}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
});

export const AssignedMembers = ({ members }) => {
  const [openList, setOpenList] = useState(null);
  const poperId = 'member-list';

  const handleOpenPopupState = (event) => {
    event.stopPropagation();
    setOpenList(openList ? undefined : event.currentTarget);
  };

  return members?.length ? (
    <>
      <FormLabel sx={{ fontSize: 14 }}>Responsible:</FormLabel>
      {members?.length ? (
        members?.slice(0, 2).map((user) => {
          return (
            <>
              <Chip
                label={`${user.first_name} ${user.last_name}`}
                variant={'outlined'}
                avatar={
                  <Avatar src={user?.profile_image?.thumb_url}>
                    {user?.first_name?.[0]}
                    {user?.last_name?.[0]}
                  </Avatar>
                }
              />
            </>
          );
        })
      ) : (
        <FormLabel sx={{ fontSize: 14 }}>-</FormLabel>
      )}
      <AvatarGroup aria-describedby={poperId} max={2} onClick={handleOpenPopupState}>
        {members?.slice(2, members.length).map((user) => {
          return (
            <Tooltip title={`${user?.first_name} ${user?.last_name}`}>
              <Avatar src={user?.profile_image?.thumb_url}>
                {user?.first_name?.[0]}
                {user?.last_name?.[0]}
              </Avatar>
            </Tooltip>
          );
        })}
      </AvatarGroup>
      {openList && (
        <Popper anchorEl={openList} id={poperId} open={true}>
          <Paper p={1}>
            <MenuList>
              {members?.slice(2, members.length)?.map((user) => {
                return (
                  <MenuItem key={user.id}>
                    <ListItemIcon>
                      <Avatar
                        src={user?.profile_image?.thumb_url}
                        sx={{ width: 30, height: 30, fontSize: '14px' }}
                      >
                        {user?.first_name?.[0]}
                        {user?.last_name?.[0]}
                      </Avatar>
                    </ListItemIcon>
                    <Typography variant="inherit">
                      {user?.first_name} {user?.last_name}
                    </Typography>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Paper>
        </Popper>
      )}
    </>
  ) : null;
};

export const AssignedCompanies = ({ companies }) => {
  const [openList, setOpenList] = useState(null);
  const poperId = 'companies-list';

  const handleOpenPopupState = (event) => {
    event.stopPropagation();
    setOpenList(openList ? undefined : event.currentTarget);
  };

  return companies?.length ? (
    <>
      <FormLabel sx={{ fontSize: 14, marginRight: 1 }}>{'Companies'}:</FormLabel>
      {companies?.slice(0, 2).map((company) => {
        const name = company.name;
        return (
          <Chip
            key={company.id}
            label={company.name}
            variant={'outlined'}
            avatar={
              <Avatar src={null}>
                {name?.charAt(0)}
                {name?.charAt(1)}
              </Avatar>
            }
          />
        );
      })}
      <AvatarGroup max={2}>
        {companies?.slice(2, companies.length).map((company) => {
          const name = company.name;
          return (
            <Tooltip key={company.id} title={company.name}>
              <Avatar src={null}>
                {name?.charAt(0)}
                {name?.charAt(1)}
              </Avatar>
            </Tooltip>
          );
        })}
      </AvatarGroup>
      {openList && (
        <Popper anchorEl={openList} id={poperId} open={true} onClick={handleOpenPopupState}>
          <Paper p={1}>
            <MenuList>
              {companies?.slice(2, companies.length)?.map((company) => {
                const name = company.name;
                return (
                  <MenuItem key={company.id}>
                    <ListItemIcon>
                      <Avatar src={null} sx={{ width: 30, height: 30, fontSize: '14px' }}>
                        {name?.charAt(0)}
                        {name?.charAt(1)}
                      </Avatar>
                    </ListItemIcon>
                    <Typography variant="inherit">{company.name}</Typography>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Paper>
        </Popper>
      )}
    </>
  ) : null;
};

const StuckPointIcon = ({ value, ...props }) => {
  return (
    <Tooltip
      title={
        <>
          Opened <LabelComponent labelKey="stuck_point" />
        </>
      }
    >
      <Box
        alignItems={'center'}
        bgcolor={parseInt(value) === 0 ? 'success.light' : 'error.light'}
        borderRadius={1}
        color={'white'}
        component={'span'}
        display={'inline-flex'}
        gap={0.5}
        p={0.5}
        {...props}
      >
        <PendingActionsIcon fontSize="small" />

        <Typography variant="subtitle2"> {value}</Typography>
      </Box>
    </Tooltip>
  );
};

export const JobwalkClosed = ({ value, ...props }) => {
  return (
    <Tooltip title="Jobwalk closed on">
      <Box
        alignItems={'center'}
        bgcolor={value ? 'success.light' : 'error.light'}
        // bgcolor={'ActiveBorder'}
        borderRadius={1}
        color={'white'}
        component={'span'}
        display={'inline-flex'}
        gap={0.5}
        p={0.5}
        {...props}
      >
        {value ? <VerifiedIcon /> : <WorkHistoryIcon />}

        {/* {value ? <img alt="Done" height={70} src="/assets/images/stamp.png" width={70} /> : <PendingActionsIcon fontSize='small' />} */}

        {value && <DateTimeDisplay dateTimeString={value} />}
      </Box>
    </Tooltip>
  );
};

const DateTimeDisplay = ({ dateTimeString }) => {
  const dateTime = parseDate(dateTimeString);

  let displayDate;
  if (isToday(dateTime)) {
    displayDate = format(dateTime, "'Today', h:mm a");
  } else if (isYesterday(dateTime)) {
    displayDate = format(dateTime, "'Yesterday', h:mm a");
  } else {
    displayDate = format(dateTime, 'MMM dd, yyyy, h:mm a');
  }
  return <Typography variant="subtitle2">{displayDate}</Typography>;
};

export default JobwalkRow;
