export const defaultLayout = {
  css: 'gantt_container',
  cols: [
    {
      width: 600,
      rows: [
        {
          view: 'grid',
          scrollX: 'gridScroll',
          scrollable: true,
          scrollY: 'scrollVer',
        },
        { view: 'scrollbar', id: 'gridScroll', group: 'horizontal' },
      ],
    },
    { resizer: true, width: 1 },
    {
      rows: [
        { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
        { view: 'scrollbar', id: 'scrollHor', group: 'horizontal' },
      ],
    },
    { view: 'scrollbar', id: 'scrollVer' },
  ],
};
