import { useEffect, useMemo } from 'react';
import { StyledDialog } from '@blackhyve/common';
import { Autocomplete, MenuItem, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { FormTextField } from 'assets/style-components/textfield';
import { FormLabel } from 'assets/style-components/typography';
import { WorkDaysComponent } from 'components/common/v3/WorkDaysComponent';
import { useCanEditProject } from 'hooks/useCanEditProject';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { Controller, useForm } from 'react-hook-form';
import { STATES } from '../../../helpers/constants';
import { api } from 'api';
import { useUpdateProjectMutation } from '../store/project.api';
import { useSelector } from 'react-redux';
import { selectCurrentUser, selectCurrentUserWorkspaceId } from 'features/auth';
import FeatureFlag, { features } from '@blackhyve/common/src/components/FeatureFlag';
import useAutoSchedulerJob from 'features/gantt/hooks/useAutoSchedulerJob';
import { Label } from 'features/labels/components/Label';
import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useGetFieldValueQuery } from 'features/labels/api/fieldValues.api';
import { useGetWorkspaceCompanyQuery } from 'features/projectCompanies/api/projectCompanies.api';

const initialState = {
  id: undefined,
  shift: 0,
  workday_calendar: {
    friday: '1',
    monday: '1',
    sunday: '0',
    tuesday: '1',
    saturday: '0',
    thursday: '1',
    wednesday: '1',
  },
  project_manager: '',
  project_number: '',
  client: '',
  address: '',
  city: '',
  state: '',
  zip_code: '',
  project_dimension_1: '',
  project_dimension_2: '',
  project_dimension_3: '',
  notification_frequency: 'daily',
  check_task_dependencies: false,
  budgets_feature: false,
  production_schedule: false,
  undo_feature: true,
};

const notificationFrequencyOptions = ['off', 'immediately', 'hourly', 'daily'];
const featureOptions = [
  { value: false, label: 'Off' },
  { value: true, label: 'On' },
];

/**
 * Project setting dialog
 * @param {String} id
 * @param {Boolean} open
 * @param {Object} canEdit
 * @param {Object} handleClose
 * @returns
 */
const ProjectSettingDialog = ({ handleClose, id, open }) => {
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const { projectDetails, isProjectLoading } = api.endpoints.getProjects.useQueryState(
    { workspaceId },
    {
      selectFromResult: ({ data: projects, isFetching, isLoading }) => {
        return {
          projectDetails: projects?.find((project) => project.id === id),
          isFetching,
          isProjectLoading: isLoading,
        };
      },
    }
  );
  const currentUser = useSelector((state) => selectCurrentUser(state));

  const { data: userCompany = { users: [] } } = useGetWorkspaceCompanyQuery({ projectId: id });

  const users = useMemo(() => {
    const users =
      currentUser.role === 'super_admin'
        ? [{ ...currentUser, isCurrentUser: true }, ...userCompany.users]
        : [...userCompany.users];
    return users;
  }, [userCompany, currentUser]);

  const [updateProject, { isLoading: isUpdatingProject }] = useUpdateProjectMutation();
  const runAutoSchedulerJob = useAutoSchedulerJob();
  const canEdit = useCanEditProject(id);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: initialState,
  });

  const handleSave = async (data) => {
    const requestParameter = cloneDeep(data);
    requestParameter.shift =
      parseInt(requestParameter.hours) * 3600 +
      parseInt(requestParameter.minutes) * 60 +
      parseInt(requestParameter.seconds);
    updateProject(requestParameter)
      .unwrap()
      .then(() => {
        handleClose();
        runAutoSchedulerJob(id);
      });
  };

  useEffect(() => {
    if (!isEmpty(projectDetails) && !projectDetails?.loading) {
      let shiftData = {};
      if (projectDetails.shift && projectDetails.shift.includes(':')) {
        shiftData.hours = projectDetails.shift.split(':')[0];
        shiftData.minutes = projectDetails.shift.split(':')[1];
        shiftData.seconds = projectDetails.shift.split(':')[2];
      }
      reset({
        id: projectDetails?.id,
        shift: projectDetails?.shift,
        hours: shiftData?.hours,
        minutes: shiftData?.minutes,
        seconds: shiftData?.seconds,
        workday_calendar: projectDetails?.workday_calendar,
        address: projectDetails?.address,
        city: projectDetails?.city,
        state: projectDetails?.state,
        zip_code: projectDetails?.zip_code,
        project_manager: projectDetails?.project_manager,
        project_number: projectDetails?.project_number,
        client: projectDetails?.client,
        project_dimension_1: projectDetails?.project_dimension_1,
        project_dimension_2: projectDetails?.project_dimension_2,
        project_dimension_3: projectDetails?.project_dimension_3,
        notification_frequency: projectDetails?.notification_frequency,
        check_task_dependencies: projectDetails?.check_task_dependencies ? true : false,
        budgets_feature: projectDetails?.budgets_feature ? true : false,
        production_schedule: projectDetails?.production_schedule ? true : false,
        undo_feature: projectDetails?.undo_feature ? true : false,
      });
    }
  }, [projectDetails, reset]);

  return (
    <StyledDialog
      handleClose={handleClose}
      open={open}
      title={projectDetails?.name + ' Settings'}
      actions={
        <>
          <Button size={'small'} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={!canEdit}
            size={'small'}
            type="submit"
            variant="contained"
            onClick={handleSubmit(handleSave)}
          >
            Save
          </Button>
        </>
      }
    >
      {isUpdatingProject || isProjectLoading ? (
        <Backdrop open={true} style={{ zIndex: 13 }} sx={{ color: '#fff' }}>
          <CircularProgress className="loading" />
        </Backdrop>
      ) : null}
      <form onSubmit={handleSubmit(handleSave)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel>WORK DAYS</FormLabel>
            <Controller
              control={control}
              name="workday_calendar"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <WorkDaysComponent
                  disabled={!canEdit}
                  workdayCalendar={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h5'}>Information</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormLabel>PROJECT MANAGER</FormLabel>
            <Controller
              control={control}
              name="project_manager"
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  fullWidth
                  selectOnFocus
                  options={users}
                  getOptionLabel={(option) => {
                    console.log('option.name =>', option);
                    return option?.name ? option.name : option;
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return option.name === value;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select User" size="small" />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <ListItem
                      {...props}
                      disablePadding
                      key={option.id}
                      secondaryAction={
                        option.isCurrentUser ? (
                          <Chip color="secondary" label="Logged in" size="small" />
                        ) : null
                      }
                    >
                      <ListItemText primary={`${option.first_name} ${option.last_name}`} />
                    </ListItem>
                  )}
                  onChange={(event, newValue) => {
                    console.log('newValue =>', newValue);
                    field.onChange(newValue?.name);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel>PROJECT NUMBER</FormLabel>
            <Controller
              control={control}
              name="project_number"
              render={({ field }) => (
                <FormTextField
                  {...field}
                  fullWidth
                  disabled={!canEdit}
                  placeholder="Project Number"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel>CLIENT</FormLabel>
            <Controller
              control={control}
              name="client"
              render={({ field }) => (
                <FormTextField
                  {...field}
                  fullWidth
                  disabled={!canEdit}
                  placeholder="Client"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h5'}>Address</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormLabel>STREET</FormLabel>
            <Controller
              control={control}
              name="address"
              render={({ field }) => (
                <FormTextField
                  {...field}
                  fullWidth
                  disabled={!canEdit}
                  placeholder="Street Address"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormLabel>CITY</FormLabel>
            <Controller
              control={control}
              name="city"
              render={({ field }) => (
                <FormTextField
                  {...field}
                  fullWidth
                  disabled={!canEdit}
                  placeholder="Project City"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormLabel>STATE</FormLabel>
            <Controller
              control={control}
              name="state"
              render={({ field: { value, onChange } }) => (
                <Autocomplete
                  autoComplete
                  autoHighlight
                  disabled={!canEdit}
                  options={STATES}
                  size="small"
                  value={value}
                  variant="outlined"
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Project State" variant="outlined" />
                  )}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel>ZIP CODE</FormLabel>
            <Controller
              control={control}
              name="zip_code"
              render={({ field }) => (
                <FormTextField
                  {...field}
                  fullWidth
                  disabled={!canEdit}
                  placeholder="Project Zip Code"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel>
              <Label labelKey={'project_dimension_2'} />
            </FormLabel>
            <Controller
              control={control}
              name="project_dimension_2"
              render={({ field: { value, onChange } }) => (
                <AsyncAutocomplete name="project_dimension_2" value={value} onChange={onChange} />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel>
              <Label labelKey={'project_dimension_3'} />
            </FormLabel>
            <Controller
              control={control}
              name="project_dimension_3"
              render={({ field: { value, onChange } }) => (
                <AsyncAutocomplete name="project_dimension_3" value={value} onChange={onChange} />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel>
              <Label labelKey="project_dimension_1" />
            </FormLabel>
            <Controller
              control={control}
              name="project_dimension_1"
              render={({ field: { value, onChange } }) => (
                <AsyncAutocomplete name="project_dimension_1" value={value} onChange={onChange} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h5'}>Notifications</Typography>
          </Grid>
          <Grid item xs={6}>
            <FormLabel>Frequency</FormLabel>
            <Controller
              control={control}
              name={'notification_frequency'}
              render={({ field }) => (
                <FormTextField
                  {...field}
                  fullWidth
                  select
                  disabled={!canEdit}
                  size={'small'}
                  variant={'outlined'}
                >
                  {notificationFrequencyOptions.map((option) => (
                    <MenuItem key={option} sx={{ textTransform: 'capitalize' }} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </FormTextField>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h5'}>Features</Typography>
          </Grid>
          <FeatureFlag name={features.BUDGETS}>
            <Grid item xs={6}>
              <FormLabel>Budgets</FormLabel>
              <Controller
                control={control}
                name={'budgets_feature'}
                render={({ field }) => (
                  <FormTextField
                    {...field}
                    fullWidth
                    select
                    disabled={!canEdit}
                    size={'small'}
                    variant={'outlined'}
                  >
                    {featureOptions?.map((option) => (
                      <MenuItem
                        key={option.label}
                        sx={{ textTransform: 'capitalize' }}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </FormTextField>
                )}
              />
            </Grid>
          </FeatureFlag>
          <FeatureFlag name={features.UNDO}>
            <Grid item xs={6}>
              <FormLabel>Gantt Undo/Redo</FormLabel>
              <Controller
                control={control}
                name={'undo_feature'}
                render={({ field }) => (
                  <FormTextField
                    {...field}
                    fullWidth
                    select
                    disabled={!canEdit}
                    size={'small'}
                    variant={'outlined'}
                  >
                    {featureOptions.map((option) => (
                      <MenuItem
                        key={option}
                        sx={{ textTransform: 'capitalize' }}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </FormTextField>
                )}
              />
            </Grid>
          </FeatureFlag>
          <Grid item xs={6}>
            <FormLabel>Production Board</FormLabel>
            <Controller
              control={control}
              name={'production_schedule'}
              render={({ field }) => (
                <FormTextField
                  {...field}
                  fullWidth
                  select
                  disabled={!canEdit}
                  size={'small'}
                  variant={'outlined'}
                >
                  {featureOptions?.map((option) => (
                    <MenuItem
                      key={option}
                      sx={{ textTransform: 'capitalize' }}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </FormTextField>
              )}
            />
          </Grid>
        </Grid>
      </form>
    </StyledDialog>
  );
};

const AsyncAutocomplete = ({ name, value, onChange }) => {
  const { data = [], isLoading } = useGetFieldValueQuery(name);

  return (
    <Autocomplete
      getOptionLabel={(option) => option}
      isOptionEqualToValue={(option, value) => option === value}
      loading={isLoading}
      options={data}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="small"
          variant="outlined"
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            },
          }}
        />
      )}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
    />
  );
};

export default ProjectSettingDialog;
