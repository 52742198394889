import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import FilterListIcon from '@mui/icons-material/FilterList';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import { useCallback, useEffect, useState } from 'react';
import FilterContent from './FilterContent';
import { MenuButton } from 'assets/style-components/button';
import { useDispatch, useSelector } from 'react-redux';
import { registerMenuState, updateMenuState } from 'slices/customViewSlice';
import { getChildren } from '@blackhyve/utilities/tasks';
import { Box, Chip, Stack } from '@mui/material';

/**
 * Filter menu for gantt
 * @param {String} projectId
 * @param {String} ganttId
 * @param {String} filterOptions
 * @returns
 */
const FilterMenu = ({ projectId, ganttId, filterOptions }) => {
  const dispatch = useDispatch();
  const gantt = ganttStore.getGantt(ganttId);
  const [filters, setFilters] = useState([]);
  const [includeParents, setIncludeParents] = useState(true);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const selectedFilters = useSelector((state) => state.customViews?.menus?.filters?.currentValue);

  const handleCloseFilterDialog = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setFilters(filters.filter((filter) => filter.value));
      setIsFilterDialogOpen(false);
    }
  };

  const save = useCallback(
    (filters) => {
      //Check for errors
      const errors = filters.reduce((accumulator, filter) => {
        const validateFunction = filterOptions[filter.type]?.validate;
        const error = validateFunction ? validateFunction(filter.value) : null;
        if (error) {
          accumulator[filter.id] = error;
        }
        return accumulator;
      }, {});

      setFilters(filters.map((filter) => ({ ...filter, error: errors?.[filter?.id] })));

      if (Object.keys(errors).length === 0) {
        const filterData = filters
          .filter((filter) => filter?.value)
          .map((filter) => {
            return { id: filter.id, type: filter.type, value: filter.value, name: filter.name };
          });
        dispatch(
          updateMenuState({ name: 'filters', value: { filters: filterData, includeParents } })
        );
        setIsFilterDialogOpen(false);
      }
    },
    [dispatch, filterOptions, includeParents]
  );

  const handleSave = () => {
    save(filters);
  };

  const handleDeleteFilter = useCallback(
    (id) => {
      setFilters((prevFilters) => {
        const index = prevFilters.findIndex((condition) => condition.id === id);
        const updatedFilters = [...prevFilters];
        updatedFilters.splice(index, 1);
        save(updatedFilters);
        return updatedFilters;
      });
    },
    [save]
  );

  const handleBeforeTaskDisplay = useCallback(
    (id, task) => {
      const handleFilter = (parent) => {
        const isValid = filters?.every((filter) => {
          const filterObj = filterOptions[filter?.type];
          return filterObj?.filterFunction
            ? filterObj?.filterFunction(parent, filter?.value)
            : true;
        });

        if (isValid) {
          return true;
        }

        if (!includeParents) {
          return false;
        }

        const children = getChildren(parent.id, gantt);
        for (let i = 0; i < children.length; i++) {
          if (handleFilter(children[i])) return true;
        }

        return isValid;
      };

      let isValid = true;
      if (task.type !== 'placeholder' && selectedFilters && selectedFilters?.filters?.length) {
        isValid = handleFilter(task);
      }
      return isValid;
    },
    [selectedFilters, filters, includeParents, gantt, filterOptions]
  );

  useEffect(() => {
    let onBeforeDisplayEvent = undefined;
    if (gantt) {
      onBeforeDisplayEvent = gantt.attachEvent('onBeforeTaskDisplay', handleBeforeTaskDisplay);
    }
    return () => {
      if (gantt && onBeforeDisplayEvent) {
        gantt.detachEvent(onBeforeDisplayEvent);
      }
    };
  }, [gantt, handleBeforeTaskDisplay]);

  useEffect(() => {
    dispatch(registerMenuState({ name: 'filters', value: { filters: [], includeParents: true } }));
  }, [dispatch]);

  useEffect(() => {
    gantt?.render();
    setFilters(selectedFilters?.filters ?? []);
    setIncludeParents(selectedFilters?.includeParents ?? false);
  }, [gantt, selectedFilters]);

  return (
    <>
      <MenuButton
        // color={filters?.length ? 'primary' : 'inherit'}
        isHighlighted={filters?.length}
        startIcon={<FilterListIcon fontSize="small" />}
        variant={filters?.length ? 'outlined' : 'text'}
        sx={{
          fontWeight: 'bold',
        }}
        onClick={(event) => setIsFilterDialogOpen(event.currentTarget)}
      >
        Filters{filters?.length > 0 && ':'}
        {filters?.length > 0 && (
          <Stack direction={'row'} marginLeft={0.5} spacing={0.5}>
            {filters.slice(0, 2).map((filter, index) => (
              <Chip
                color={'primary'}
                key={filter.id}
                label={filter.name}
                size={'small'}
                sx={{ paddingY: 0, height: 'unset', fontSize: '.8em' }}
                onDelete={() => {
                  handleDeleteFilter(filter.id);
                }}
              />
            ))}
            {filters.length > 2 && (
              <Box
                sx={{
                  marginLeft: '4px',
                  fontWeight: 'bold',
                }}
              >
                +{filters.length - 2}
              </Box>
            )}
          </Stack>
        )}
      </MenuButton>
      <Popover
        anchorEl={isFilterDialogOpen}
        open={Boolean(isFilterDialogOpen)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleCloseFilterDialog}
      >
        <FilterContent
          filterOptions={filterOptions}
          filters={filters}
          gantt={gantt}
          handleClose={handleCloseFilterDialog}
          includeParents={includeParents}
          projectId={projectId}
          setFilters={setFilters}
          setIncludeParents={setIncludeParents}
        />
        <Grid
          container
          justifyContent={'flex-end'}
          style={{ padding: '0px 15px 15px 15px', gap: 5 }}
        >
          <Button size={'small'} onClick={handleCloseFilterDialog}>
            Close
          </Button>
          <Button size={'small'} variant={'contained'} onClick={handleSave}>
            Save
          </Button>
        </Grid>
      </Popover>
    </>
  );
};

export default FilterMenu;
