import { JobwalkDialog } from 'features/jobwalk/components/JobwalkDialog';
import StuckPointDialog from 'features/stuckPoints/components/StuckPointDialog';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import RecipeDrawer from 'features/drawers/components/RecipeDrawer';
import ProcurementDialog from 'features/procurement/components/ProcurementDialog';
import { useModal } from 'components/common/ModalProvider';

export const TaskGanttModals = () => {
  const { openModalId, openModalMeta, closeModal } = useModal();

  return (
    <>
      {/* Modals */}
      {openModalId === 'jobwalkDialog' && (
        <JobwalkDialog handleClose={closeModal} open={true} {...openModalMeta} />
      )}
      {openModalId === 'stuckPointDialog' && (
        <StuckPointDialog handleClose={closeModal} open={true} {...openModalMeta} />
      )}
      {openModalId === 'recipeDrawerDialog' && (
        <RecipeDrawer handleClose={closeModal} open={true} {...openModalMeta} />
      )}
      {openModalId === 'procurementDialog' && (
        <ProcurementDialog handleClose={closeModal} open={true} {...openModalMeta} />
      )}
      {openModalId === 'loadingSpinner' && <LoadingSpinner {...openModalMeta} />}
    </>
  );
};
