import { endOfWeek, format, startOfWeek } from 'date-fns';
import { useMemo, useState } from 'react';
import { useGetUnpublishedWeeklyWorkPlanQuery } from '../api/target.api';
import { groupBy } from 'lodash';
import {
  Alert,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  List,
  ListItem,
  ListSubheader,
  Stack,
} from '@mui/material';
import { TargetDetails } from './TargetDetails';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { PublishWeeklyWorkPlanDialog } from './PublishWeeklyWorkPlanDialog';
import DisplayWithEditAccess, { useEditContext } from 'components/common/v3/DisplayWithEditAccess';

const dateFormatOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};
const startOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

export const PreviewUnpublishedTargets = ({
  taskId,
  projectId,
  maxTargets = 5,
  slotProps = {},
}) => {
  const { cardProps, cardActionAreaProps, cardContentProps } = slotProps;
  const { workspaceId } = useParams();

  const canEdit = useEditContext();

  const [isPublishOpen, setIsPublishOpen] = useState(false);

  const { data: { targets = [], message = '' } = {}, isLoading: isLoadingTargets } =
    useGetUnpublishedWeeklyWorkPlanQuery({
      projectId: projectId,
      parentId: taskId,
      date: format(startOfThisWeek, 'yyyy-MM-dd'),
    });

  const grouped = useMemo(
    () =>
      groupBy(targets.slice(0, maxTargets), (value) =>
        value.task.parents.map(({ name }) => name).join(' > ')
      ),
    [maxTargets, targets]
  );

  return (
    <>
      <Card elevation={2} {...cardProps}>
        <CardHeader
          subheader={`${startOfThisWeek.toLocaleDateString(undefined, dateFormatOptions)} to ${endOfWeek(startOfThisWeek, { weekStartsOn: 1 }).toLocaleDateString(undefined, dateFormatOptions)}`}
          title={'Unpublished Targets'}
          action={
            <DisplayWithEditAccess>
              <Button size={'small'} variant={'contained'} onClick={() => setIsPublishOpen(true)}>
                Publish WWP
              </Button>
            </DisplayWithEditAccess>
          }
        />
        <CardActionArea
          disabled={!canEdit}
          onClick={() => setIsPublishOpen(true)}
          {...cardActionAreaProps}
        >
          <CardContent {...cardContentProps}>
            {isLoadingTargets ? (
              <Stack alignItems={'center'} width={1}>
                <CircularProgress />
              </Stack>
            ) : targets.length > 0 ? (
              <List dense disablePadding>
                {Object.entries(grouped).map(([groupKey, targets]) => (
                  <ListItem disablePadding divider key={groupKey}>
                    <List dense disablePadding sx={{ width: 1 }}>
                      <ListSubheader
                        disableGutters
                        sx={{ fontWeight: 600, color: 'unset', fontSize: '1rem', zIndex: 0 }}
                      >
                        {groupKey}
                      </ListSubheader>
                      {targets.map((target) => (
                        <ListItem disableGutters divider key={target.id || target.task.id}>
                          <TargetDetails target={target} />
                        </ListItem>
                      ))}
                    </List>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Alert severity={'success'}>All Targets Published This Week</Alert>
            )}
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            color={'primary'}
            component={RouterLink}
            to={`/workspace/${workspaceId}/projects/${projectId}/production-board/tasks/${taskId}/targets`}
          >
            View All
          </Button>
        </CardActions>
      </Card>
      <PublishWeeklyWorkPlanDialog
        handleClose={() => setIsPublishOpen(false)}
        open={isPublishOpen}
        projectId={projectId}
        taskId={taskId}
      />
    </>
  );
};
