import { taskSchedulerEvents } from '@blackhyve/utilities/gantt';
import { useMemo } from 'react';
import { isChildOf } from '@blackhyve/utilities/tasks/isChildOf';
import { linkHasCircularDependency } from '@blackhyve/utilities/gantt/hasCircularDependencies';
import { autoUpdateLag } from 'features/gantt/helpers/autoUpdateLag';
import { areIntervalsOverlapping } from 'date-fns';

export const useLookaheadEvents = ({ rootId }) => {
  const events = useMemo(
    () => [
      ...Object.entries(taskSchedulerEvents).map(
        ([key, value]) => ({ event: key, callback: value }),
        []
      ),
      {
        event: 'onGanttReady',
        callback: function () {
          const gantt = this;
          autoUpdateLag(gantt);
        },
      },
      {
        event: 'onBeforeTaskDisplay',
        callback: function (taskId, task) {
          const gantt = this;

          // Set task height
          task.bar_height =
            task.type === 'parent_task' ? gantt.config.parent_bar_height : gantt.config.bar_height;
          task.row_height =
            task.type === 'parent_task' ? gantt.config.parent_row_height : gantt.config.row_height;

          const isChildOfRoot = isChildOf(taskId, rootId, gantt);
          const isRoot = taskId == rootId;

          // Check if forecasted dates are within gantt window
          const isInTimeFrame = areIntervalsOverlapping(
            { start: task.forecasted_start_date, end: task.forecasted_end_date },
            { start: gantt.config.start_date, end: gantt.config.end_date },
            { inclusive: true }
          );

          return (isChildOfRoot || isRoot) && isInTimeFrame;
        },
      },
      {
        event: 'onBeforeRowDragMove',
        callback: function (taskId, parentId, toIndex) {
          const gantt = this;

          // Prevent creating new parent
          if (gantt.getTask(taskId).status === 'complete') return false;

          // Prevent creating parent task with row drag
          if (gantt.getTask(parentId).type === 'task') return false;

          return true;
        },
      },
      {
        event: 'onBeforeLinkAdd',
        callback: function (id, link) {
          const gantt = this;
          let isValid = true;
          const sourceTask = gantt.getTask(link.source);

          link.project_id = sourceTask?.project_id;
          isValid = !linkHasCircularDependency(link, gantt);

          if (!isValid) {
            gantt.alert({
              title: 'Not allowed',
              type: 'alert-error',
              text: 'Link creates a loop, try another path.',
            });
            return false;
          } else {
            return true;
          }
        },
      },
      {
        event: 'onBeforeLightbox',
        callback: () => false,
      },
    ],
    [rootId]
  );
  return events;
};
