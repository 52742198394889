import { defaultLayout } from './defaultLayout';

export const defaultConfig = {
  auto_types: true,
  auto_scheduling: true,
  date_format: '%Y-%m-%d',
  date_grid: '%m-%d-%Y',
  deepcopy_on_parse: true,
  work_time: true,
  correct_work_time: true,
  time_step: 60 * 24,
  open_tree_initially: true,
  static_background: true,
  static_background_cells: true,
  smart_rendering: true,
  grid_elastic_columns: true,
  smart_scales: true,
  multiselect: true,
  inline_editors_multiselect_open: true,
  keyboard_navigation: true,
  keyboard_navigation_cells: true,
  order_branch: 'marker',
  order_branch_free: true,
  scale_height: 35,
  task_height: 16,
  row_height: 35,
  bar_height: 16,
  min_duration: 24 * 60 * 60 * 1000, // (1 day)
  duration_unit: 'day',
  links: {
    finish_to_start: 'FS',
    start_to_start: 'SS',
    finish_to_finish: 'FF',
    start_to_finish: 'SF',
  },
  drag_progress: false,
  constraint_types: {
    ASAP: 'asap',
    ALAP: 'alap',
    SNET: 'snet',
    SNLT: 'snlt',
    FNET: 'fnet',
    FNLT: 'fnlt',
    MSO: 'mso',
    MFO: 'mfo',
  },
  layout: defaultLayout,
};
