import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { useState, useMemo } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import SearchBox from 'components/search/SearchBox';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import InputAdornment from '@mui/material/InputAdornment';
import { StyledMenu } from 'features/activityFeeds/assets/activityFeedStyle';
import { matchText } from 'features/jobwalk/components/UserFilterMenu';
import { useGetFieldValueQuery } from 'features/labels/api/fieldValues.api';
import { Label } from 'features/labels/components/Label';

export const ProjectTableFilterMenu = ({ handleSearch, filters, setFilters }) => {
  return (
    <Toolbar sx={{ position: 'sticky', left: '0px', borderBottom: '1px solid lightGray', p: 0 }}>
      <Grid item>
        <SearchBox
          bgcolor={'grey.200'}
          borderRadius={1}
          fullWidth={false}
          placeholder="Search By Name"
          onChange={handleSearch}
        />
      </Grid>
      <Grid container item xs justifyContent={'flex-end'} spacing={1}>
        <Grid item>
          <FilterDropdown
            label={<Label labelKey={'project_dimension_1'} />}
            name={'project_dimension_1'}
            selectedValue={filters.project_dimension_1}
            setFilters={setFilters}
          />
        </Grid>
        <Grid item>
          <FilterDropdown
            label={<Label labelKey={'project_dimension_2'} />}
            name={'project_dimension_2'}
            selectedValue={filters.project_dimension_2}
            setFilters={setFilters}
          />
        </Grid>
        <Grid item>
          <FilterDropdown
            label={<Label labelKey={'project_dimension_3'} />}
            name={'project_dimension_3'}
            selectedValue={filters.project_dimension_3}
            setFilters={setFilters}
          />
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export const FilterDropdown = ({ setFilters, name, selectedValue, label }) => {
  const { data: options = [], isLoading } = useGetFieldValueQuery(name);

  const [openMenu, setOpenMenu] = useState(false);
  const [searchText, setSearchText] = useState('');

  const displayedOptions = useMemo(
    () => options.filter((option) => matchText(option, searchText)),
    [searchText, options]
  );

  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleClose = () => {
    setOpenMenu(null);
    setSearchText('');
  };

  const handleSelect = (newValue) => {
    setFilters((prev) => ({
      ...prev,
      [name]: prev[name]?.includes(newValue)
        ? prev[name].filter((value) => value !== newValue)
        : [...(prev[name] || []), newValue],
    }));
  };

  const handleClear = () => {
    setFilters((prev) => ({
      ...prev,
      [name]: [],
    }));
  };

  const displayLabel = (
    <>
      Filter By {label} {selectedValue.length ? `: ${selectedValue.length} Selected` : ''}
    </>
  );

  return (
    <>
      <Chip
        color={selectedValue?.length ? 'secondary' : 'default'}
        label={isLoading ? '...loading' : displayLabel}
        variant={'filled'}
        onClick={handleClick}
      />
      <StyledMenu
        anchorEl={openMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        getContentAnchorEl={null}
        open={Boolean(openMenu)}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleClose}
      >
        <ListSubheader>
          <TextField
            autoFocus
            fullWidth
            placeholder="Type to search..."
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        <Box sx={{ overflowY: 'auto', maxHeight: 300 }}>
          {displayedOptions?.map((option) => (
            <ListItemButton key={option} sx={{ p: 1 }} onClick={(event) => handleSelect(option)}>
              <ListItemIcon>
                <Checkbox checked={selectedValue?.includes(option)} />
              </ListItemIcon>
              <ListItemText primary={option} />
            </ListItemButton>
          ))}
        </Box>

        <ListItemButton sx={{ pt: 0.5, p: 0 }}>
          <Button fullWidth onClick={handleClear}>
            Clear
          </Button>
        </ListItemButton>
      </StyledMenu>
    </>
  );
};
