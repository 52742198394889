import { addWeeks, startOfDay } from 'date-fns';
import { useMemo } from 'react';

export const useLookaheadMarkers = ({ targetsDate }) => {
  const markers = useMemo(
    () =>
      [
        targetsDate && {
          id: 'Targets',
          start_date: targetsDate,
          end_date: addWeeks(targetsDate, 1),
          css: 'target-range',
          text: 'Target Range',
        },
        {
          id: 'Today',
          start_date: startOfDay(new Date()),
          css: 'today',
          text: 'Today',
        },
      ].filter(Boolean),
    [targetsDate]
  );
  return markers;
};
