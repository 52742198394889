import { ConfirmDeleteDialog } from '@blackhyve/common';
import { theme } from '@blackhyve/common/src/AppTheme';
import { Delete, Edit, MoreVert } from '@mui/icons-material';
import { Box, ListItem, ListItemIcon, ListItemText, MenuList, ThemeProvider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Popover } from 'components/Popover';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteResourceMutation } from '../api/resources.api';
import { ResourceDialog } from './ResourceDialog';
import { Resource } from '../api/resources.models';

export function ResourceMoreOptions({
  resource,
  isGroup,
}: {
  resource: Resource;
  isGroup: boolean;
}) {
  const navigate = useNavigate();
  const [deleteResource] = useDeleteResourceMutation();
  const { workspaceId, resourceId: currentResourceId } = useParams();

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

  const handleDelete = () => {
    if (currentResourceId === resource.id.toLocaleString()) {
      navigate(`/workspace/${workspaceId}/projects`);
    }
    deleteResource({ resourceId: resource.id });
  };

  return (
    <>
      <Popover
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        trigger={
          <IconButton edge="end" size={'small'} sx={{ color: 'inherit' }}>
            <MoreVert />
          </IconButton>
        }
      >
        {({ handleClose }) => {
          return (
            <MenuList>
              <ListItem
                button
                onClick={() => {
                  setEditDialogOpen(true);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary={'Edit'} />
              </ListItem>
              <Box
                color={'error.main'}
                component={ListItem}
                onClick={() => {
                  setConfirmDeleteDialogOpen(true);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <Delete color={'error'} />
                </ListItemIcon>
                <ListItemText color={'error'} primary={'Delete'} />
              </Box>
            </MenuList>
          );
        }}
      </Popover>
      <ThemeProvider theme={theme}>
        <ConfirmDeleteDialog
          handleClose={() => setConfirmDeleteDialogOpen(false)}
          handleDelete={handleDelete}
          id={resource?.id}
          item={'resource'}
          open={confirmDeleteDialogOpen}
          plural={false}
        />
        <ResourceDialog
          handleClose={() => setEditDialogOpen(false)}
          isGroup={isGroup}
          open={editDialogOpen}
          resource={resource}
        />
      </ThemeProvider>
    </>
  );
}
