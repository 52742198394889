import { DatastoreMethods, GanttStatic, TreeDatastoreMethods } from '@blackhyve/dhtmlx-gantt';
import { Resource } from '../api/resources.models';

type Task = any;

export class ResourcesStore {
  private static _datastore: DatastoreMethods & TreeDatastoreMethods;
  private static _gantt: GanttStatic;

  constructor(gantt?: GanttStatic) {
    if (!ResourcesStore._gantt && !gantt) {
      throw new Error(
        '[ResourcesStore] no gantt instance was provided when initializing the singleton'
      );
    }
    if (gantt) {
      if (ResourcesStore._datastore) {
        console.log('[ResourcesStore] overwriting existing datastore');
        // ResourcesStore._datastore.destructor();
      }

      gantt.config.resources = true;

      gantt.config.resource_store = 'resource';
      gantt.config.resource_property = 'resources';
      gantt.config.process_resource_assignments = true;

      ResourcesStore._gantt = gantt;
      ResourcesStore._datastore = gantt.createDatastore({
        name: gantt.config.resource_store,
        type: 'treeDatastore',
        initItem: function (resource) {
          const resourceParents = resource.parents ? resource.parents[0] : gantt.config.root_id;
          resource.parent = resourceParents;
          resource.open = true;
          return resource;
        },
      });
    }

    return this;
  }

  get datastore() {
    return ResourcesStore._datastore;
  }

  get gantt() {
    return ResourcesStore._gantt;
  }

  isWorkTime(day) {
    return this.gantt.isWorkTime(day);
  }

  getResourceAssignments(resourceId) {
    let assignments;
    assignments = this.gantt.getResourceAssignments(resourceId);
    return assignments;
  }

  hasChild(id) {
    return this.datastore.hasChild(id);
  }

  getChildren(id) {
    return this.datastore.getChildren(id);
  }

  getCapacity(date: Date, resource: Resource) {
    if (this.datastore.hasChild(resource.id)) {
      return -1;
    }

    let val = date.valueOf();
    const r = this.datastore.getItem(resource.id) as any;
    return r.max_capacity;
  }

  getResourceDelta(start_date: Date, end_date: Date, resource: Resource, tasks: Task[]) {
    if (this.hasChild(resource.id)) {
      return this.rollupParentAllocationAndCapacity(start_date, end_date, resource);
    }
    return this.getAllocationAndCapacity(start_date, end_date, resource, tasks);
  }

  rollupParentAllocationAndCapacity(start_date: Date, end_date: Date, resource: Resource) {
    const childrenIds = Array.from(this.getChildren(resource.id)).filter(
      (id): id is number => typeof id === 'number'
    );

    return childrenIds.reduce(
      (acc, childId) => {
        const childResource = this.datastore.getItem(childId) as Resource;
        const childTasks = this.getTasksForResource(childId);

        const childAllocationAndCapacity = this.getAllocationAndCapacity(
          start_date,
          end_date,
          childResource,
          childTasks
        );

        return {
          allocation: acc.allocation + childAllocationAndCapacity.allocation,
          capacity: acc.capacity + childAllocationAndCapacity.capacity,
        };
      },
      { allocation: 0, capacity: 0 }
    );
  }

  getTasksForResource(resourceId: number): Task[] {
    const assignments = this.getResourceAssignments(resourceId);
    return assignments.map((assignment) => ResourcesStore._gantt.getTask(assignment.task_id));
  }

  getAllocationAndCapacity(start_date: Date, end_date: Date, resource: Resource, tasks: Task[]) {
    const cellDuration = this.gantt.calculateDuration({ start_date, end_date });
    const capacity = this.getCapacity(start_date, resource) * cellDuration;

    const allocation = tasks.reduce((total, task) => {
      const taskAllocation = this.getTaskAllocation(start_date, end_date, resource, task);
      return total + taskAllocation;
    }, 0);

    return { allocation, capacity };
  }

  getTaskAllocation(start_date: Date, end_date: Date, resource: Resource, task: Task) {
    const assignments = this.gantt.getResourceAssignments(resource.id, task.id);
    return assignments.reduce((total, assignment) => {
      const taskDetails = this.gantt.getTask(assignment.task_id);
      const dailyDemand = this.calculateDailyDemand(taskDetails);
      const daysInWindow = this.calculateDaysInWindow(taskDetails, start_date, end_date);

      return total + daysInWindow * dailyDemand;
    }, 0);
  }

  calculateDailyDemand(task: Task): number {
    const numResources = task.resources?.length || 1;
    return task.demand / task.work_days / numResources;
  }

  // TODO: take into account holidays and non-working days
  calculateDaysInWindow(task: Task, start: Date, end: Date): number {
    // TODO: account for forecasted start/end days
    const taskStart = +task.start_date;
    const taskEnd = +task.end_date;
    const windowStart = +start;
    const windowEnd = +end;

    const taskStartsAndEndsOutsideWindow = taskStart <= windowStart && taskEnd >= windowEnd;
    const taskEndsInsideWindow =
      taskStart <= windowStart && taskEnd >= windowStart && taskEnd < windowEnd;
    const taskStartsInsideWindow =
      taskEnd >= windowEnd && taskStart >= windowStart && taskStart < windowEnd;
    const taskCompletelyContainedWithinWindow = taskStart >= windowStart && taskEnd <= windowEnd;

    if (taskStartsAndEndsOutsideWindow) {
      return this.gantt.calculateDuration({ start_date: start, end_date: end });
    }

    if (taskEndsInsideWindow) {
      return this.gantt.calculateDuration({
        start_date: start,
        end_date: new Date(taskEnd),
      });
    }

    if (taskStartsInsideWindow) {
      return this.gantt.calculateDuration({
        start_date: new Date(taskStart),
        end_date: end,
      });
    }

    if (taskCompletelyContainedWithinWindow) {
      return this.gantt.calculateDuration({
        start_date: new Date(taskStart),
        end_date: new Date(taskEnd),
      });
    }

    return 0;
  }

  logDebugInfo(
    tasks: Task[],
    resource: Resource,
    info: { start_date: Date; end_date: Date; capacity: number; allocation: number }
  ) {
    if (tasks.length && resource.id === 1) {
      console.log({
        ...info,
        resource,
        tasks: tasks.map(({ work_days, demand, text }) => ({ work_days, demand, text })),
      });
    }
  }

  parse(resources: Resource[]) {
    return this.datastore.parse(resources);
  }
}
