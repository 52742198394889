import ReactQuill from 'react-quill';
import Box from '@mui/material/Box';
import parseISO from 'date-fns/parseISO';
import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import { formatTime } from '@blackhyve/utilities/dates';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import AvatarComponent from 'components/common/v3/AvatarComponent';
import CommentMenuOptions from 'features/comments/components/CommentMenuOption';


/**
 * Comment Card
 * @param {object} comment 
 * @param {number} comment.id
 * @param {string} comment.content
 * @param {[object]} comment.comments
 * @returns 
 */
const CommentCard = ({ comment }) => {

  const dateTime = formatTime(parseISO(comment?.created_at));
  const user = comment?.user;
  const isUserArchived = user?.deleted_at;

  return <>
    <ListItem alignItems='flex-start'>
      <ListItemAvatar>
        <AvatarComponent
          name={`${user?.first_name} ${user?.last_name}`}
          src={user?.profile_image?.thumb_url}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box alignItems={'center'} display={'flex'} gap={1}>
            <Typography sx={isUserArchived ? { fontWeight: 'bold', textDecoration: 'line-through' } : { fontWeight: 'bold', }}>
              {user?.first_name ? user?.first_name : 'Deleted User'} {user?.last_name}
              {' '}
            </Typography>
            <Typography
              color="text.secondary"
              component="span"
              sx={{ display: 'inline' }}
              variant="body2"
            >
              {dateTime}
            </Typography>
            <Box ml="auto">
              <CommentMenuOptions comment={comment} />
            </Box>
          </Box>
        }
        secondary={<ReactQuill
          bounds="quill"
          className={'comment'}
          readOnly={true}
          theme={'bubble'}
          value={comment?.comment}
        />
        }
      />
    </ListItem >
    <Divider component="li" variant="inset" />
  </>

}

export default CommentCard;



export const CommentCardSkeleton = () => {
  return <>
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Skeleton height={40} variant="circular" width={40} />
      </ListItemAvatar>
      <ListItemText
        primary={<Skeleton variant="text" width="60%" />}
        secondary={
          <>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
          </>
        }
      />
    </ListItem>
    <Divider />
  </>
}
