import { useEffect, useState } from 'react';
import TagDropdown from './TagDropdown';
import Button from '@mui/material/Button';
import { StyledDialog } from '@blackhyve/common';
import CircularProgress from '@mui/material/CircularProgress';

/**
 * @param {Boolean} open
 * @param {Object} handleClose
 * @param {Object} handleUpdate
 * @param {[Object]} existingTags
 * @returns
 */
const AssignTagDialog = ({ handleClose, open, existingTags, handleUpdate, isUpdating }) => {
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    existingTags.length ? setSelectedTags(existingTags) : setSelectedTags([]);
  }, [existingTags]);

  return (
    <StyledDialog
      handleClose={handleClose}
      open={open}
      title={'Assign Tags'}
      actions={
        <>
          <Button disabled={isUpdating} size="small" onClick={handleClose}>
            Close
          </Button>
          <Button
            color="primary"
            disabled={isUpdating}
            size="small"
            variant="contained"
            onClick={() => handleUpdate(selectedTags)}
          >
            Update
            {isUpdating && <CircularProgress size={20} sx={{ ml: 1 }} />}
          </Button>
        </>
      }
    >
      <TagDropdown selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
    </StyledDialog>
  );
};

export default AssignTagDialog;
