import { addYears, endOfWeek, format } from 'date-fns';

export const getDefaultZoomConfig = (gantt) => ({
  levels: [
    {
      name: 'years',
      scale_height: 35,
      min_column_width: 300,
      max_column_width: 300,
      scales: [
        {
          unit: 'year',
          step: 5,
          format: function (date) {
            const endDate = addYears(date, 5);
            return date.getFullYear() + ' - ' + endDate.getFullYear();
          },
        },
        { unit: 'year', step: 1, format: '%Y' },
      ],
    },
    {
      name: 'quarters',
      scale_height: 35,
      min_column_width: 250,
      max_column_width: 250,
      scales: [
        {
          unit: 'year',
          step: 1,
          format: '%Y',
        },
        {
          unit: 'quarter',
          step: 1,
          format: (date) => {
            return format(date, 'Qo');
          },
        },
      ],
    },
    {
      name: 'months',
      scale_height: 35,
      min_column_width: 200,
      max_column_width: 200,
      scales: [
        {
          unit: 'year',
          step: 1,
          format: '%Y',
        },
        {
          unit: 'month',
          step: 1,
          format: '%M',
        },
      ],
    },
    {
      name: 'weeks',
      scale_height: 35,
      min_column_width: 120,
      max_column_width: 120,
      scales: [
        {
          unit: 'month',
          step: 1,
          format: '%M',
        },
        {
          unit: 'week',
          format: function (date) {
            const endDate = endOfWeek(date);
            return (
              format(date, 'MMM') +
              '-' +
              format(date, 'e') +
              ' - ' +
              format(endDate, 'MMM') +
              '-' +
              format(endDate, 'e')
            );
          },
        },
      ],
    },

    {
      name: 'days',
      scale_height: 35,
      min_column_width: 35,
      max_column_width: 35,
      bar_height: 16,
      row_height: 30,
      scales: [
        {
          unit: 'week',
          step: 1,
          format: function (date) {
            const endDate = endOfWeek(date);
            return (
              format(date, 'MMM') +
              '-' +
              format(date, 'e') +
              ' - ' +
              format(endDate, 'MMM') +
              '-' +
              format(endDate, 'e')
            );
          },
        },
        {
          unit: 'day',
          step: 1,
          format: function (date) {
            return format(date, 'eeeeee') + ' ' + format(date, 'dd');
          },
        },
      ],
    },
  ],
  activeLevelIndex: 4,
  useKey: 'ctrlKey',
  trigger: 'wheel',
  element: function () {
    return gantt.$root.querySelector('.gantt_task');
  },
});
