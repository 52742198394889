import { Box, Card, CardActionArea, CardContent, Radio, Stack, Typography } from '@mui/material';

/**
 * Radio Card
 * @param {Object || String} label
 * @param {String} value
 * @param {String} description
 * @param {Object} onChange
 * @param {Boolean} isSelected
 * @returns
 */
export const RadioCard = ({
  label,
  value,
  description,
  onChange,
  isSelected,
  slots = {},
  slotProps = {},
  children,
  ...props
}) => {
  const {
    Label: LabelComponent = Typography,
    Description: DescriptionComponent = Typography,
    Radio: RadioComponent = Radio,
  } = slots;

  const { labelProps = {}, descriptionProps = {}, radioProps = {} } = slotProps;

  return (
    <Card elevation={3} {...props}>
      <CardActionArea onClick={onChange && (() => onChange({ target: { value } }))}>
        <CardContent sx={{ p: 1 }}>
          <Stack alignItems={'center'} direction={'row'}>
            <RadioComponent disableRipple checked={isSelected} {...radioProps} />
            <Box>
              <LabelComponent {...labelProps}>{label}</LabelComponent>
              <DescriptionComponent color={'textSecondary'} variant={'body2'} {...descriptionProps}>
                {description}
              </DescriptionComponent>
              {children}
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
