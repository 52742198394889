import TradeIcon from '@blackhyve/common/src/icons/Trade';
import { Tooltip } from '@mui/material';
import { ResourceType } from 'features/resources/api/resources.models';
import { concatenateParentAndChildNames } from 'features/resources/helpers/resourceHelper';
import { useMemo } from 'react';

export const ResourcesColumnCell = ({ task, gantt }) => {
  const allResources = Object.values(gantt.getDatastore('resource').pull);

  const parentResourceMap = useMemo(() => {
    return allResources.reduce((map, resource) => {
      if (resource.type === ResourceType.Group) {
        map[resource.id] = resource.name;
      }
      return map;
    }, {});
  }, [allResources]);

  const resourceNameMap = useMemo(() => {
    return allResources.reduce((map, resource) => {
      map[resource.id] = {
        originalName: resource.name,
        concatenatedName: concatenateParentAndChildNames({ resource, parentResourceMap }),
      };
      return map;
    }, {});
  }, [allResources, parentResourceMap]);

  const taskResources = task?.resources?.map((assignment) =>
    gantt.getDatastore('resource').getItem(assignment.resource_id)
  );

  if (task.id < 0) {
    return (
      <span className="cell_disabled" style={{ paddingTop: 10 }}>
        {TradeIcon()}
      </span>
    );
  }

  if (taskResources?.length === 1) {
    return resourceNameMap[taskResources[0]?.id]?.concatenatedName || taskResources[0]?.name;
  }

  if (taskResources?.length > 0) {
    return (
      <>
        {taskResources.map((resource, index) => {
          const { originalName, concatenatedName } = resourceNameMap[resource?.id] || {};
          const resourceNameArray = originalName?.split(' ') || [];

          return (
            <Tooltip arrow key={index} placement="top" title={concatenatedName}>
              <div
                className="company-avatar company-grid-icon"
                style={{
                  zIndex: taskResources?.length - index,
                  marginLeft: index > 0 ? '8px' : '0px',
                }}
              >
                {resourceNameArray?.length > 1
                  ? resourceNameArray.map((name) => name.slice(0, 1).toUpperCase()).join('')
                  : resourceNameArray[0]?.slice(0, 2).toUpperCase()}
              </div>
            </Tooltip>
          );
        })}
      </>
    );
  } else {
    return <span style={{ paddingTop: 10 }}>{TradeIcon()}</span>;
  }
};
