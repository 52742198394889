import { Button, DialogActions } from '@mui/material';
import Popover from '@mui/material/Popover';
import makeStyles from '@mui/styles/makeStyles';
import { createRef, forwardRef, useImperativeHandle, useState } from 'react';
import ganttStore from '../../ganttStore';
import { SelectResourcesAutocompleteList } from 'features/resources/components/SelectResourcesList';
import {
  useCreateResourceAssignmentsMutation,
  useGetAllResourcesQuery,
} from 'features/resources/api/resources.api';
import { isEqual } from 'lodash';

export const loadResourceMenuType = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  let resourceEditorRef;

  gantt.config.editor_types.resource = {
    show: function (id, column, config, placeholder) {
      const resources = Object.values(gantt.getDatastore('resource').pull);
      const task = gantt.getTask(id);
      const selectedResources = task?.resources?.map((assignment) =>
        gantt.getDatastore('resource').getItem(assignment.resource_id)
      );

      resourceEditorRef = createRef();
      gantt.config.external_render.renderElement(
        <ResourceMenuPopover
          anchorEl={placeholder}
          gantt={gantt}
          initialSelected={selectedResources ?? []}
          key={`${task.id}-resource-menu`}
          ref={resourceEditorRef}
          resources={resources}
          taskId={task.id}
          onClose={() => gantt.ext.inlineEditors.save()}
        ></ResourceMenuPopover>,
        placeholder
      );
    },
    hide: function () {
      // called when input is hidden
      // destroy any complex editors or detach event listeners from here
    },

    set_value: function (value, id, column, node) {
      // set input value
      if (resourceEditorRef.current) {
        resourceEditorRef?.current?.setValue(new Set(value || []));
      }
    },

    get_value: function (id, column, node) {
      return resourceEditorRef?.current?.getValue();
      // return input value
    },

    is_changed: function (value, id, column, node) {
      //called before save/close. Return true if new value differs from the original one
      //returning true will trigger saving changes, returning false will skip saving
      const currentValue = this.get_value() || [];
      const oldValue = value.resources || [];
      if (currentValue.length !== oldValue.length) {
        return true;
      }
      currentValue.sort();
      oldValue.sort();
      return !currentValue.every((element, index) => element.id === oldValue[index].id);
    },

    is_valid: function (value, id, column, node) {
      // validate, changes will be discarded if the method returns false
      return Array.isArray(this.get_value());
    },
    save: function (id, column, node) {
      const taskResources = this.get_value(id, column, node);
      const taskResourceAssignments = taskResources.map((resource) => ({
        task_id: id,
        resource_id: resource.id,
        value: 0,
      }));
      const task = gantt.getTask(id);
      task.resources = taskResourceAssignments;
      gantt.updateTask(id);
      resourceEditorRef?.current?.saveResourceAssignments(taskResourceAssignments);
    },
    focus: function (node) {},
  };
};

const useStyles = makeStyles(() => ({
  paper: {
    width: 400,
    overflowY: 'auto',
    borderRadius: '8px',
    padding: '10px 10px',
  },
}));

const ResourceMenuPopover = forwardRef(
  ({ initialSelected, anchorEl, onClose, taskId, gantt }, ref) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [selected, setSelected] = useState(initialSelected || []);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingUserResource, setIsLoadingUserResource] = useState(false);
    const [createResourceAssignments] = useCreateResourceAssignmentsMutation();
    const { data: fetchedResources = [] } = useGetAllResourcesQuery({});

    const handleClose = () => {
      setOpen(false);
      onClose(selected);
    };

    const handleSaveResourceAssignments = (updatedResourceAssignments) => {
      if (isEqual(updatedResourceAssignments, initialSelected)) {
        return;
      }
      createResourceAssignments({ taskId, resourceAssignments: updatedResourceAssignments })
        .unwrap()
        .then((data) => {
          gantt.batchUpdate(() => {
            data?.forEach(({ id, resourceAssignments }) => {
              if (gantt.isTaskExists(id)) {
                const task = gantt.getTask(id);
                task.resources = resourceAssignments;
                gantt.refreshTask(id);
              }
            });
          });
        });
    };

    const handleSubmit = async () => {
      handleClose();
    };

    const handleCancel = () => {
      setOpen(false);
      setSelected(initialSelected);
      onClose(initialSelected);
    };

    useImperativeHandle(ref, () => ({
      getValue: () => [...selected],
      setValue: setSelected,
      saveResourceAssignments: handleSaveResourceAssignments,
    }));

    return (
      <>
        {anchorEl && (
          <Popover
            anchorEl={anchorEl}
            classes={{ paper: classes.paper }}
            open={open}
            onClose={handleSubmit}
          >
            <SelectResourcesAutocompleteList
              isLoading={isLoading || isLoadingUserResource}
              resources={fetchedResources}
              selected={selected}
              setSelected={setSelected}
            />

            <DialogActions>
              <Button size="small" onClick={handleCancel}>
                Cancel
              </Button>
              <Button size="small" variant="contained" onClick={handleSubmit}>
                Update
              </Button>
            </DialogActions>
          </Popover>
        )}
      </>
    );
  }
);
