import { selectCurrentUser } from 'features/auth';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const PostHogIdentify = () => {
  const posthog = usePostHog();

  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    if (currentUser) {
      posthog?.identify(currentUser.id, {
        first_name: currentUser.first_name,
        last_name: currentUser.last_name,
        email: currentUser.email,
      });
    } else {
      posthog?.reset();
    }
  }, [posthog, currentUser]);
};
