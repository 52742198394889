import { useEffect, useRef } from 'react';

export const useOnTaskClick = ({ callback, className, gantt }) => {
  const eventId = useRef();
  useEffect(() => {
    const clickHandler = (taskId, event) => {
      if (taskId) {
        const task = gantt.getTask(taskId);
        if (
          task &&
          typeof event.target?.className === 'string' &&
          event?.target?.className?.includes(className)
        ) {
          callback(task);
        }
      }
      return true;
    };

    eventId.current = gantt.attachEvent('onTaskClick', clickHandler);

    return () => {
      gantt.detachEvent(eventId.current);
    };
  }, [callback, className, gantt]);
};
