import { Typography, Box, Button, Fab, Grid } from '@mui/material';
import { Resource } from '../api/resources.models';
import { ResourceMoreOptions } from './ResourceMoreOptions';
import { Add } from '@mui/icons-material';
import { ResourceDialog } from './ResourceDialog';
import { useState } from 'react';

export function ResourceGroups({
  resourceGroups,
  onGroupToggle,
  activeGroupIds,
}: {
  resourceGroups: Resource[];
  onGroupToggle: (groupId: number) => void;
  activeGroupIds: number[];
}) {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);
  const isGroupActive = (group: Resource) => activeGroupIds.includes(group.id);

  return (
    <>
      <Grid
        container
        alignItems="center"
        flexWrap={'nowrap'}
        justifyContent="space-between"
        marginBottom={2}
        // overflow={'hidden'}
        spacing={2}
      >
        <Grid item>
          <Typography variant="h6">Resource Groups</Typography>
        </Grid>
        <Grid item>
          <Fab
            color="secondary"
            size="small"
            sx={{ flexShrink: 0 }}
            variant="extended"
            onClick={() => setIsCreateDialogOpen(true)}
          >
            <Add /> Group
          </Fab>
        </Grid>
      </Grid>

      <Box sx={{ overflow: 'hidden', width: '100%' }}>
        {resourceGroups.map((group) => (
          <Box key={group.id} sx={{ marginBottom: 1, width: '100%' }}>
            <Button
              fullWidth
              color={isGroupActive(group) ? 'secondary' : 'inherit'}
              variant="contained"
              sx={{
                marginBottom: 1,
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
              onClick={() => onGroupToggle(group.id)}
            >
              <Box sx={{ flex: 1, textAlign: 'center' }}>{group.name}</Box>
              <ResourceMoreOptions isGroup={true} resource={group} />
            </Button>
          </Box>
        ))}
      </Box>

      <ResourceDialog
        handleClose={() => setIsCreateDialogOpen(false)}
        isGroup={true}
        open={isCreateDialogOpen}
      />
    </>
  );
}
