import CalendarIcon from '@blackhyve/common/src/icons/Calender';
import { addWorkdays, differenceInWorkdays, parseDate } from '@blackhyve/utilities/dates';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import RichTextEditor from 'components/common/v3/RichTextEditor';
import { ColorSelect } from 'components/form/ColorSelect';
import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import { selectCurrentUser } from 'features/auth';
import { GroupByCompanyListBoxComponent } from 'features/contacts/components/SelectContactList';
import {
  useGetProjectCompaniesQuery,
  useGetWorkspaceCompanyQuery,
} from 'features/projectCompanies/api/projectCompanies.api';
import { TradesAutocomplete } from 'features/trades/components/TradesAutocomplete';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'image', 'link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ color: [] }, { background: [] }],
];

const initialState = {
  scheduled_start: new Date(),
  scheduled_end: addWorkdays(new Date(), 7),
  forecasted_start: new Date(),
  forecasted_end: addWorkdays(new Date(), 7),
  effort_hours: null,
  work_days: 7,
  cal_days: differenceInDays(addWorkdays(new Date(), 7), new Date()),
  crew_size: null,
  dates_locked_by: false,
  constraint_type: 'asap',
  constraint_date: null,
  color: null,
  contacts: [],
  responsible_users: [],
  trades: [],
};

const constraintOptions = {
  ASAP: 'As Soon As Possible',
  ALAP: 'As Late As Possible',
  SNET: 'Start No Earlier Than',
  SNLT: 'Start No Later Than',
  FNET: 'Finish No Earlier Than',
  FNLT: 'Finish No Later Than',
  MSO: 'Must Start On',
  MFO: 'Must Finish On',
};

/**
 * Task Form Component
 * @param {*} param0
 * @returns
 */
const TaskFormComponent = ({ task, handleClose, handleTaskUpdate }) => {
  const { control, handleSubmit, watch, setValue, reset, setFocus } = useForm({
    defaultValues: initialState,
    mode: 'onBlur',
  });
  const { enqueueSnackbar } = useSnackbar();

  const [isUpdating, setIsUpdating] = useState(false);

  const { data: companies = [], isLoading: isLoadingCompanies } = useGetProjectCompaniesQuery({
    projectId: task?.project_id,
  });
  const { data: userCompany = { users: [] } } = useGetWorkspaceCompanyQuery({
    projectId: task?.project_id,
  });

  const currentUser = useSelector((state) => selectCurrentUser(state));

  const users = useMemo(() => {
    const users =
      currentUser.role === 'super_admin'
        ? [{ ...currentUser, isCurrentUser: true }, ...userCompany.users]
        : [...userCompany.users];
    return users;
  }, [userCompany, currentUser]);

  const companyEntities = Object.fromEntries(
    companies?.map((company) => [company.id, company]) || []
  );

  const contacts = companies
    ?.filter((company) => company.id !== userCompany.id)
    .flatMap((company) => company.users);

  const handleOnChangeDescription = (newDescription) => {
    setValue('description', newDescription);
  };

  const handleSave = async (data) => {
    const scheduledStart = data.scheduled_start ? format(data.scheduled_start, 'yyyy-MM-dd') : null;
    const scheduledEnd = data.scheduled_end ? format(data.scheduled_end, 'yyyy-MM-dd') : null;
    const taskPayload = {
      ...data,
      project_id: task.project_id,
      scheduled_start: scheduledStart,
      scheduled_end: scheduledEnd,
      duration_days: data.work_days,
      forecasted_start: data.forecasted_start
        ? format(data.forecasted_start, 'yyyy-MM-dd')
        : scheduledStart,
      forecasted_end: data.forecasted_end
        ? format(data.forecasted_end, 'yyyy-MM-dd')
        : scheduledEnd,
      constraint_date: data.constraint_date ? format(data.constraint_date, 'yyyy-MM-dd') : null,
    };

    try {
      setIsUpdating(true);

      // Handle both promise and non-promise return values
      const result = handleTaskUpdate(taskPayload);

      // If result is a promise, await it
      if (result && typeof result.then === 'function') {
        await result;
      }

      setIsUpdating(false);
      enqueueSnackbar('Task updated successfully', { variant: 'success' });
      handleClose();
    } catch (error) {
      setIsUpdating(false);
      enqueueSnackbar('Not able to update task, retry again', { variant: 'error' });
    }
  };

  useEffect(() => {
    setFocus('scheduled_start');
    reset({
      id: task?.id ?? null,
      description: task?.description ?? null,
      scheduled_start: task?.scheduled_start
        ? parseDate(task?.scheduled_start)
        : initialState.scheduled_start,
      scheduled_end: task?.scheduled_end
        ? parseDate(task?.scheduled_end)
        : initialState.scheduled_end,
      work_days: task?.duration_days ?? initialState.work_days,
      cal_days: task?.cal_days ?? initialState.cal_days,
      trades: task?.trades ?? initialState.trades,
      companies: task?.companies?.map((company) => company?.id) ?? initialState.companies,
      contacts: task?.contacts?.map((company) => company?.id) ?? initialState.contacts,
      responsible_users:
        task?.responsible_users?.map((user) => user?.id) ?? initialState.responsible_users,
      color: task?.color ?? initialState.color,
      crew_size: task?.crew_size ?? initialState.crew_size,
      constraint_date: task?.constraint_date
        ? parseDate(task?.constraint_date)
        : initialState.constraint_date,
      constraint_type: task?.constraint_type ?? initialState.constraint_type,
    });
  }, [task, reset, setFocus]);

  const watchScheduledStart = watch('scheduled_start');
  const watchCalDays = watch('cal_days');
  const datesLockedBy = watch('dates_locked_by');
  const watchConstraintType = watch('constraint_type');
  const watchDescription = watch('description');

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(handleSave)(e);
      }}
    >
      <Grid container pt={1} spacing={2}>
        <Grid container item xs={12}>
          <Grid item md={2} xs={12}>
            <FormLabel>Description*</FormLabel>
          </Grid>
          <Grid item md={10} xs={12}>
            <RichTextEditor
              className="drawer-comment"
              handleOnChange={handleOnChangeDescription}
              readOnly={false}
              text={watchDescription}
              toolbarOptions={toolbarOptions}
            />
          </Grid>
        </Grid>
        <Grid container item alignItems={'center'} md={6} xs={12}>
          <Grid item md={4} xs={5}>
            <FormLabel>Scheduled Start</FormLabel>
          </Grid>
          <Grid item md={8} xs={7}>
            <Controller
              control={control}
              disabled={task.status !== 'todo' || Boolean(datesLockedBy)}
              name="scheduled_start"
              render={({ field: { ref, ...field }, fieldState: { invalid, error } }) => (
                <DatePicker
                  {...field}
                  disabled={task?.status !== 'todo'}
                  error={invalid}
                  slotProps={{ textField: { size: 'small' }, helperText: error?.message }}
                  onChange={(newValue) => {
                    setValue(field.name, newValue);
                    if (isValid(newValue)) {
                      const scheduledEnd = addDays(newValue, watchCalDays);
                      setValue('scheduled_end', scheduledEnd);
                      if (task?.status !== 'active' && task?.type !== 'parent_task') {
                        setValue('forecasted_start', newValue);
                        setValue('forecasted_end', scheduledEnd);
                      }
                    }
                  }}
                />
              )}
              rules={{
                required: 'Scheduled start field is required',
                validate: {
                  isValidDate: (value) => isValid(value) || 'Invalid Date',
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid container item alignItems={'center'} md={6} xs={12}>
          <Grid item md={4} xs={5}>
            <FormLabel>Scheduled End</FormLabel>
          </Grid>
          <Grid item md={8} xs={7}>
            <Controller
              control={control}
              name="scheduled_end"
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  fullWidth
                  disabled={Boolean(datesLockedBy)}
                  error={!!error}
                  format="MM/dd/yyyy"
                  InputAdornmentProps={{ position: 'start' }}
                  inputVariant="outlined"
                  keyboardIcon={<CalendarIcon fontSize="small" style={{ paddingLeft: 0 }} />}
                  minDate={watchScheduledStart}
                  slotProps={{ textField: { size: 'small', helperText: error?.message } }}
                  value={field.value}
                  variant="inline"
                  InputProps={{
                    margin: 'dense',
                  }}
                  onChange={(newValue) => {
                    setValue(field.name, newValue);
                    const scheduledStartDate = watchScheduledStart;
                    if (isValid(newValue)) {
                      setValue('cal_days', differenceInDays(newValue, scheduledStartDate));
                      setValue('work_days', differenceInWorkdays(scheduledStartDate, newValue));
                      if (task?.status !== 'active' && task.type !== 'parent_task') {
                        setValue('forecasted_end', newValue);
                      }
                    }
                  }}
                />
              )}
              rules={{
                required: 'Schedule end field is required',
                validate: {
                  isValidDate: (value) => isValid(value) || 'Invalid Date',
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid container item alignItems={'center'} md={6} xs={12}>
          <Grid item md={4} xs={5}>
            <FormLabel>Work Days</FormLabel>
          </Grid>
          <Grid item md={8} xs={7}>
            <Controller
              control={control}
              name="work_days"
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  autoFocus
                  disabled={task?.status !== 'todo' || Boolean(datesLockedBy)}
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{ inputProps: { min: 0 } }}
                  inputRef={ref}
                  placeholder={'Work days'}
                  size="small"
                  type={'number'}
                  variant="outlined"
                  onChange={(event) => {
                    const newValue = parseInt(event.target.value);
                    const endDate = addWorkdays(watchScheduledStart, newValue);
                    const calDays = differenceInDays(endDate, watchScheduledStart);
                    setValue('work_days', newValue);
                    setValue('cal_days', calDays);
                    setValue('scheduled_end', endDate);
                    if (task.status !== 'active' && task.type !== 'parent_task') {
                      setValue('forecasted_end', endDate);
                    }
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item alignItems={'center'} md={6} xs={12}>
          <Grid item md={4} xs={5}>
            <FormLabel>Calendar Days</FormLabel>
          </Grid>
          <Grid item md={8} xs={7}>
            <Controller
              control={control}
              name="cal_days"
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  autoFocus
                  disabled={task.status !== 'todo' || Boolean(datesLockedBy)}
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{ inputProps: { min: 0 } }}
                  inputRef={ref}
                  placeholder={'Calendar days'}
                  size="small"
                  type={'number'}
                  variant="outlined"
                  onChange={(event) => {
                    const newValue = parseInt(event.target.value);
                    const endDate = addDays(watchScheduledStart, newValue);
                    const workDays = differenceInWorkdays(watchScheduledStart, endDate);
                    setValue('cal_days', newValue);
                    setValue('work_days', workDays);
                    setValue('scheduled_end', endDate);
                    if (task.status !== 'active' && task.type !== 'parent_task') {
                      setValue('forecasted_end', endDate);
                    }
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item alignItems={'center'} xs={12}>
          <Grid item md={2} xs={5}>
            <FormLabel>Responsible</FormLabel>
          </Grid>
          <Grid item md={10} xs={7}>
            <Controller
              control={control}
              name="responsible_users"
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  disableCloseOnSelect
                  fullWidth
                  multiple
                  isOptionEqualToValue={(option, value) => option.id === value}
                  options={users}
                  getOptionLabel={(option) =>
                    option?.first_name ??
                    (users?.length && users.find((user) => user.id === option)?.first_name)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={error}
                      helperText={error?.message}
                      placeholder="Select User"
                      size="small"
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <ListItem
                      {...props}
                      disablePadding
                      key={option.id}
                      secondaryAction={
                        option.isCurrentUser ? (
                          <Chip color="secondary" label="Logged in" size="small" />
                        ) : null
                      }
                    >
                      <ListItemIcon>
                        <Checkbox checked={selected} />
                      </ListItemIcon>
                      <ListItemText primary={`${option.first_name} ${option.last_name}`} />
                    </ListItem>
                  )}
                  onChange={(event, newValue) => {
                    field.onChange(newValue.map((user) => user?.id ?? user));
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item alignItems={'center'} xs={12}>
          <Grid item md={2} xs={5}>
            <FormLabel>Contacts</FormLabel>
          </Grid>
          <Grid item md={10} xs={7}>
            <Controller
              control={control}
              name="contacts"
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  disableCloseOnSelect
                  fullWidth
                  multiple
                  getOptionDisabled={(option) => (option.deleted_at ? true : false)}
                  groupBy={(option) => companyEntities[option.company_id].name}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  ListboxComponent={GroupByCompanyListBoxComponent}
                  loading={isLoadingCompanies}
                  options={contacts}
                  size="small"
                  getOptionLabel={(option) =>
                    option?.first_name ??
                    (contacts?.length && contacts.find((contact) => contact.id === option)?.name)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={error}
                      helperText={error?.message}
                      placeholder="Select Contacts"
                      size="small"
                      variant="outlined"
                    />
                  )}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <ListItemButton {...props}>
                        <ListItemIcon>
                          <Checkbox checked={selected} />
                        </ListItemIcon>
                        <ListItemText
                          style={option.deleted_at ? { textDecoration: 'line-through' } : {}}
                        >
                          {option?.first_name + ' ' + option?.last_name}
                        </ListItemText>
                      </ListItemButton>
                    );
                  }}
                  onChange={(event, newValue) => {
                    if (event.key !== 'Backspace') {
                      field.onChange(newValue.map((contact) => contact?.id ?? contact));
                    }
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item alignItems={'center'} xs={12}>
          <Grid item md={2} xs={5}>
            <FormLabel>Trade</FormLabel>
          </Grid>
          <Grid item md={10} xs={7}>
            <Controller
              control={control}
              name="trades"
              render={({ field }) => (
                <TradesAutocomplete
                  multiple
                  {...field}
                  onChange={(_event, newValue) => {
                    field.onChange(newValue ?? null);
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item alignItems={'center'} md={6} xs={12}>
          <Grid item md={4} xs={5}>
            <FormLabel>Crew</FormLabel>
          </Grid>
          <Grid item md={8} xs={7}>
            <Controller
              control={control}
              name="crew_size"
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  autoFocus
                  error={!!error}
                  helperText={error?.message}
                  inputRef={ref}
                  placeholder={'Crew Size'}
                  size="small"
                  type={'number'}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item alignItems={'center'} md={6} xs={12}>
          <Grid item md={4} xs={5}>
            <FormLabel>Color</FormLabel>
          </Grid>
          <Grid item md={8} xs={7}>
            <Controller
              control={control}
              name="color"
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <ColorSelect size={'small'} {...field} />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item alignItems={'center'} md={6} xs={12}>
          <Grid item md={4} xs={5}>
            <FormLabel>Constraint Type</FormLabel>
          </Grid>
          <Grid item md={8} xs={7}>
            <Controller
              control={control}
              name="constraint_type"
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Select
                  disableUnderline={true}
                  error={error}
                  input={<OutlinedInput margin="dense" />}
                  name="constraint_type"
                  size="small"
                  {...field}
                >
                  {Object.keys(constraintOptions).map((key) => {
                    return <MenuItem value={key}>{constraintOptions[key]}</MenuItem>;
                  })}
                </Select>
              )}
            />
          </Grid>
        </Grid>

        <Grid container item alignItems={'center'} md={6} xs={12}>
          <Grid item md={4} xs={5}>
            <FormLabel>Constraint Date</FormLabel>
          </Grid>
          <Grid item md={8} xs={7}>
            <Controller
              control={control}
              name="constraint_date"
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  disabled={watchConstraintType == 'ASAP' || watchConstraintType == 'ALAP'}
                  error={!!error}
                  format="MM/dd/yyyy"
                  helperText={error?.message}
                  InputAdornmentProps={{ position: 'start' }}
                  inputVariant="outlined"
                  keyboardIcon={<CalendarIcon fontSize="small" style={{ paddingLeft: 0 }} />}
                  minDate={watchScheduledStart}
                  slotProps={{ textField: { size: 'small' } }}
                  value={field.value}
                  variant="inline"
                  InputProps={{
                    margin: 'dense',
                  }}
                  rules={{
                    required:
                      watchConstraintType &&
                      watchConstraintType !== 'ASAP' &&
                      watchConstraintType !== 'ALAP'
                        ? `Scheduled end date is required as you have selected constraint type ${watchConstraintType}`
                        : false,
                    validate: {
                      isValidDate: (value) => isValid(value) || 'Invalid Date',
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item gap={1} xs={12}>
          <Button size="small" onClick={handleClose}>
            Close
          </Button>
          <LoadingButton loading={isUpdating} size="small" type="submit" variant="contained">
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default TaskFormComponent;
