import { FormLabel, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

export const NotesStep = ({ form, task }) => {
  const { control } = form;

  return (
    <Stack alignItems={'flex-start'}>
      <FormLabel>
        Actual Crew Size <Typography variant={'caption'}>({task.crew_size} scheduled) </Typography>
      </FormLabel>
      <Controller
        control={control}
        name={'actual_crew_size'}
        render={({ field, fieldState: { error, invalid } }) => (
          <TextField
            error={invalid}
            helperText={error?.message}
            placeholder={'Actual crew size'}
            type={'number'}
            {...field}
            inputProps={{ min: 0 }}
            InputProps={{
              endAdornment: (
                <Tooltip title={'Scheduled Crew Size'}>
                  <InputAdornment position="end">/ {task.crew_size}</InputAdornment>
                </Tooltip>
              ),
            }}
          />
        )}
        rules={{
          min: { value: 0, message: 'Cannot be a negative number' },
        }}
      />
      <FormLabel>Notes</FormLabel>
      <Controller
        control={control}
        name={'notes'}
        render={({ field, fieldState: { error, invalid } }) => (
          <TextField
            fullWidth
            multiline
            error={invalid}
            helperText={error?.message}
            maxRows={7}
            minRows={7}
            placeholder={'Enter notes for the jobwalk'}
            {...field}
          />
        )}
      />
    </Stack>
  );
};
