import { isSameDay } from 'date-fns';
import { useEffect, useMemo, useRef } from 'react';

// Helper function to compare arrays of workdays
const areWorkdaysEqual = (prevWorkdays, nextWorkdays) => {
  if (prevWorkdays.length !== nextWorkdays.length) return false;
  return prevWorkdays.every((day, index) => day === nextWorkdays[index]);
};

export const useGanttWorkdays = (gantt, workdays, holidays, options = {}) => {
  const previousWorkdays = useRef(workdays);

  // Need to memo based on values because every time tasks are updated the project gets updated causing tasks to update causing a loop
  const memoizedWorkdays = useMemo(() => {
    if (areWorkdaysEqual(previousWorkdays.current, workdays)) {
      return previousWorkdays.current;
    }
    previousWorkdays.current = workdays;
    return workdays;
  }, [workdays]);

  const { skip = false } = options;

  // Set workdays
  useEffect(() => {
    if (gantt && !gantt.$destroyed && !skip) {
      // Set workdays
      const calendarId = gantt.addCalendar({
        id: 'global',
        worktime: { days: memoizedWorkdays },
      });

      const calendar = gantt.getCalendar(calendarId);
      // Add holidays
      holidays.forEach((holiday) => {
        calendar.setWorkTime({ date: holiday, hours: false });
      });

      gantt.batchUpdate(function () {
        gantt.eachTask(function (task) {
          const newScheduledStartDate = gantt.getClosestWorkTime({
            dir: 'future',
            date: task.start_date,
            task: task,
          });
          const newScheduledEndDate = gantt.calculateEndDate({
            start_date: task.start_date,
            duration: task.work_days,
            task: task,
          });
          if (
            !isSameDay(task.start_date, newScheduledStartDate) ||
            !isSameDay(task.scheduled_end_date, newScheduledEndDate)
          ) {
            gantt.updateTask(task.id);
          }
        });
      });

      if (gantt.$root) {
        gantt.autoSchedule ? gantt.autoSchedule() : gantt.render();
      }
    }
  }, [gantt, memoizedWorkdays, skip, holidays]);
};
