import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { ActionBar } from 'components/navigation/ActionBar';
import CustomViewMenu from 'components/projectOverview/gantt/components/menus/CustomViewMenu';
import DisplayMenu from 'components/projectOverview/gantt/components/menus/DisplayMenu';
import GanttColumnSelector from 'components/projectOverview/gantt/components/menus/GanttColumnSelector';
import SearchMenu from 'components/projectOverview/gantt/components/menus/SearchMenu';
import ToolsMenu from 'components/projectOverview/gantt/components/menus/ToolsMenu';
import ZoomMenu from 'components/projectOverview/gantt/components/menus/ZoomLevelMenu';
import FilterMenu from 'components/projectOverview/gantt/components/menus/filters/FilterMenu.js';
import {
  areaFilter,
  companiesFilter,
  locationFilter,
  paceFilter,
  projectFilter,
  responsibleUserFilter,
  statusFilter,
  stuckPointFilters,
  timeFrameFilter,
  customTimeFrameFilter,
  tradeFilter,
  zoneFilter,
  smartTagFilter,
  resourceFilter,
} from 'components/projectOverview/gantt/components/menus/filters/filterUtils';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import { selectCurrentUserWorkspaceId } from 'features/auth';
import { selectAllCompanies } from 'features/companies/store/companiesSlice';
import GanttOnlineUsers from 'features/gantt/components/GanttOnlineUsers';
import { CollapseAll, ExpandAll } from 'features/gantt/menu/CollapseExpand';
import TodayOption from 'features/gantt/menu/Today';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ColorMenu from '../../gantt/menu/Color';
import { useGetProjectsQuery } from 'features/projects/store/project.api';
import { SaveStatus } from 'features/gantt/menu/SaveStatus';

const TaskPortfolioActionBar = ({ ganttId, isSaving }) => {
  const [loading, setLoading] = useState(true);

  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const { data: projects = [], isLoading: isProjectsLoading } = useGetProjectsQuery({
    workspaceId,
  });
  const projectIds = useMemo(() => projects?.map((project) => project.id), [projects]);
  const currentWorkspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const companyEntities = useSelector((state) => selectAllCompanies(state));

  const colorOptions = [
    'Default',
    'Task',
    'Critical Path',
    'Location',
    'Zone',
    'Area',
    'Project',
    'Company',
    'SmartTag',
    'Tag',
  ];

  const searchByOptions = [
    {
      name: 'Name',
      value: 'text',
      compareFunction: (task, query) => {
        if (query) {
          return task.text?.toLowerCase()?.includes(query?.toLowerCase());
        }
      },
    },
    {
      name: 'Company',
      value: 'company',
      compareFunction: (task, query) => {
        if (query && task?.companies?.length) {
          const companyNames = task?.companies?.map((id) => companyEntities[id]?.name);
          return companyNames.some((name) => name.toLowerCase().includes(query.toLowerCase()));
        }
      },
    },
  ];

  const gantt = ganttStore.getGantt(ganttId);

  useEffect(() => {
    gantt?.attachEvent('onGanttReady', function () {
      setLoading(false);
    });

    return gantt?.detachEvent('onGanttReady');
  }, [gantt]);

  const channelNames = projectIds?.map((projectId) => {
    return `workspaces.${currentWorkspaceId}.projects.${projectId}.tasks`;
  });

  return (
    <ActionBar px={0} py={0.5}>
      {isProjectsLoading || loading ? null : (
        <Grid container justifyContent="space-between">
          <Grid container item xs alignItems={'center'} flexWrap={'nowrap'} gap={0.5}>
            <Grid item>
              <SearchMenu ganttId={ganttId} searchByOptions={searchByOptions} />
            </Grid>
            <Divider />
            <Grid item>
              <FilterMenu
                ganttId={ganttId}
                filterOptions={{
                  companiesFilter,
                  responsibleUserFilter,
                  statusFilter,
                  locationFilter,
                  zoneFilter,
                  areaFilter,
                  timeFrameFilter,
                  customTimeFrameFilter,
                  projectFilter,
                  paceFilter,
                  tradeFilter,
                  stuckPointFilters,
                  smartTagFilter,
                  resourceFilter,
                }}
              />
            </Grid>
            {/* <Divider flexItem orientation="vertical" variant="middle" />
            <Grid item>
              <GroupBy ganttId={ganttId} options={groupByOptions} />
            </Grid> */}
            <Divider flexItem orientation="vertical" variant="middle" />
            <Grid item>
              <GanttColumnSelector ganttId={ganttId} />
            </Grid>
            <Grid item>
              <SaveStatus isSaving={isSaving} />
            </Grid>
          </Grid>
          <Grid
            container
            item
            alignItems={'center'}
            flexBasis={'auto'}
            flexGrow={0}
            justifyContent={'flex-end'}
            ml={'auto'}
            sx={{ maxWidth: '100%' }}
            width={'auto'}
          >
            <Grid item>
              <TodayOption ganttId={ganttId} />
            </Grid>
            <Divider flexItem orientation="vertical" variant="middle" />
            <Grid item>
              <ExpandAll gantt={gantt} projectId={'task-portfolio'} />
            </Grid>
            <Divider flexItem orientation="vertical" variant="middle" />
            <Grid item>
              <CollapseAll gantt={gantt} projectId={'task-portfolio'} />
            </Grid>
            <Divider flexItem orientation="vertical" variant="middle" />
            <Grid item>
              <ZoomMenu ganttId={ganttId} />
            </Grid>
            <Divider flexItem orientation="vertical" variant="middle" />
            <Grid item>
              <ToolsMenu ganttId={ganttId} />
            </Grid>
            <Divider flexItem orientation="vertical" variant="middle" />
            <Grid item>
              <DisplayMenu ganttId={ganttId} />
            </Grid>
            <Divider flexItem orientation="vertical" variant="middle" />
            <Grid item>
              <ColorMenu ganttId={ganttId} options={colorOptions} />
            </Grid>
            <Divider flexItem orientation="vertical" variant="middle" />
            <Grid item>
              <CustomViewMenu ganttId={ganttId} viewType="schedule" />
            </Grid>
            <Grid item>
              <GanttOnlineUsers channelNames={channelNames} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </ActionBar>
  );
};

export default TaskPortfolioActionBar;
