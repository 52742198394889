import { features, useFeatureFlag } from '@blackhyve/common/src/components/FeatureFlag';
import BudgetCode from '@blackhyve/common/src/icons/BudgetCode';
import SvgBuilding from '@blackhyve/common/src/icons/Building';
import GanttIcon from '@blackhyve/common/src/icons/GanttIcon';
import {
  Analytics,
  Assignment,
  DirectionsWalk,
  List,
  PendingActions,
  Person,
  Timeline,
} from '@mui/icons-material';
import { SvgIcon } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { EditContext } from 'components/common/v3/DisplayWithEditAccess';
import { ContentLayout } from 'components/layouts/ContentLayout';
import { SecondaryNav } from 'components/navigation/SecondaryNav';
import { SHOW_SNACKBAR } from 'constants/ActionTypes';
import { RequireRole, useRequireRole } from 'features/auth/components/RequireRole';
import { budgetsRoutes } from 'features/budgets/routes';
import Jobwalk from 'features/jobwalk/routes/Jobwalk';
import { locationRoutes } from 'features/locations/routes/index';
import { PlanningRoutes } from 'features/planningBoard/routes/index';
import { ProjectUsers } from 'features/projectCompanies';
import { stuckPointsRoutes } from 'features/stuckPoints';
import { useGetAllProjectStuckPointsQuery } from 'features/stuckPoints/store/stuckPoint.api';
import { useCanEditProject } from 'hooks/useCanEditProject';
import { useDispatch } from 'react-redux';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { ProjectsGantt } from '../../projectsGantt/routes/ProjectsGantt';
import ProjectActivityFeed from '../components/ProjectActivityFeed';
import ProjectNotificationLog from '../components/ProjectNotificationLog';
import { useGetProjectQuery } from '../store/project.api';
import { Projects } from './Projects';
import { ProductionBoardRoutes } from 'features/productionBoard/routes';
import { Label as LabelComponent } from 'features/labels/components/Label';
import ProcurementIcon from '@mui/icons-material/ShoppingCartOutlined';
import { procurementRoutes } from 'features/procurement';
import { analyticsRoutes } from 'features/analytics/routes';

const ProjectsLayout = () => {
  return (
    <ContentLayout
      header={
        <SecondaryNav
          title={'Projects'}
          tabs={[
            { to: '', label: 'List', end: true, icon: <List /> },
            {
              to: 'gantt',
              label: 'Gantt',
              icon: (
                <SvgIcon>
                  <GanttIcon viewBox={'-2 -2 20 20'} />
                </SvgIcon>
              ),
            },
          ]}
        >
          {/* <Tab end icon={<List />} to={''}>
            List
          </Tab>
          <Tab icon={<GanttIcon />} to={'gantt'}>
            Gantt
          </Tab> */}
        </SecondaryNav>
      }
    >
      <Outlet />
    </ContentLayout>
  );
};

const ProjectLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAnalyticsEnabled = useFeatureFlag(features.ANALYTICS);
  const isBudgetsEnabled = useFeatureFlag(features.BUDGETS);
  console.log(isBudgetsEnabled);
  const isNotContractorContact = useRequireRole({ roles: ['contractor_contact'], exclude: true });
  const projectId = Number(useParams().projectId) || undefined;
  const {
    data: projectDetails,
    isLoading: isLoadingProjectDetails,
    isError,
  } = useGetProjectQuery(projectId);
  const { data: openStuckPointCount } = useGetAllProjectStuckPointsQuery({ projectId });

  if (isError) {
    dispatch({
      type: SHOW_SNACKBAR,
      message: 'Project Not Found',
      variant: 'error',
    });
    navigate('/', { replace: true });
  }
  const canEdit = useCanEditProject(projectId);

  if (isLoadingProjectDetails) {
    return <CircularProgress className="loading" />;
  }
  const projectName = projectDetails?.name;

  return (
    <ContentLayout
      header={
        <SecondaryNav
          title={projectName}
          tabs={[
            {
              to: 'planning-board',
              label: 'Planning',
              icon: (
                <SvgIcon>
                  <GanttIcon viewBox={'-2 -2 20 20'} />
                </SvgIcon>
              ),
            },
            projectDetails?.production_schedule && {
              to: 'production-board',
              label: 'Production',
              icon: <Assignment />,
            },
            {
              to: 'procurements',
              label: 'Procurements',
              icon: (
                <SvgIcon>
                  <ProcurementIcon />
                </SvgIcon>
              ),
            },
            {
              to: 'locations',
              label: 'Locations',
              icon: (
                <SvgIcon>
                  <SvgBuilding height={'unset'} viewBox={'-2 -2 20 20'} width={'unset'} />
                </SvgIcon>
              ),
            },
            {
              to: 'users',
              label: 'Users',
              icon: <Person />,
            },
            {
              to: 'jobwalks',
              label: 'Jobwalks',
              icon: <DirectionsWalk />,
            },
            {
              to: 'stuck-points',
              label: (
                <>
                  <LabelComponent labelKey="stuck_point" plural={true} /> ({openStuckPointCount})
                </>
              ),
              icon: <PendingActions />,
            },
            { to: 'activity-feeds', label: 'Activity', icon: <Timeline /> },
            isAnalyticsEnabled && {
              to: 'analytics',
              label: 'Analytics',
              icon: <Analytics />,
            },
            projectDetails?.budgets_feature &&
              isBudgetsEnabled &&
              isNotContractorContact && { to: 'budgets', label: 'Cashflow', icon: <BudgetCode /> },
          ]}
        ></SecondaryNav>
      }
    >
      <EditContext.Provider value={canEdit}>
        <Outlet />
      </EditContext.Provider>
    </ContentLayout>
  );
};

export const projectsRoutes = [
  {
    path: '',
    element: <ProjectsLayout />,
    children: [
      { index: true, element: <Projects /> },
      { path: 'gantt', element: <ProjectsGantt /> },
    ],
  },
  {
    path: ':projectId',
    element: <ProjectLayout />,
    children: [
      { path: 'planning-board', children: PlanningRoutes },
      { path: 'production-board', children: ProductionBoardRoutes },
      // { path: 'dashboard', element: <Dashboard /> },
      // { path: 'flow-board', element: <FlowBoard /> },
      { path: 'locations', children: locationRoutes },
      { path: 'activity-feeds', element: <ProjectActivityFeed /> },
      { path: 'notification-log', element: <ProjectNotificationLog /> },
      { path: 'users', element: <ProjectUsers /> },
      { path: 'jobwalks', element: <Jobwalk /> },
      { path: 'analytics', children: analyticsRoutes },
      {
        path: 'budgets',
        children: budgetsRoutes,
        element: (
          <RequireRole
            exclude
            fallbackComponent={<Navigate replace to={'..'} />}
            roles={['contractor_contact']}
          >
            <Outlet />
          </RequireRole>
        ),
      },

      { path: 'stuck-points', children: stuckPointsRoutes },
      { path: 'procurements', children: procurementRoutes },
      { index: true, element: <Navigate replace to="planning-board" /> },
    ],
  },
  // { path: ':projectId/tasks', children: tasksRoutes },
  { path: '*', element: <Navigate to={'.'} /> },
];
