import { formatTaskToGanttTask } from '@blackhyve/utilities/gantt';
import { Info } from '@mui/icons-material';
import {
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  Stack,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer';
import Skeleton from '@mui/material/Skeleton';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import { useGetRecipesQuery } from 'features/recipes/api/recipe.api';
import RecipeDetailsDialog from 'features/recipes/components/RecipeDetailDialog';
import isEmpty from 'lodash/isEmpty';
import times from 'lodash/times';
import { Fragment, useMemo, useState } from 'react';
import FlowAiDialog from './FlowAiDialog';
import { useInsertRecipeMutation } from 'features/tasks/store/task.api';
import SearchBox from 'components/search/SearchBox';

/**
 * Recipe Drawer
 * @param {number} open
 * @param {Boolean} open
 * @param {Boolean} open
 * @param {Boolean} open
 * @returns JSX
 */
const RecipeDrawer = ({ ganttId, taskId, projectId, open, handleClose }) => {
  const gantt = ganttStore.getGantt(ganttId);
  const [searchQuery, setSearchQuery] = useState('');
  const {
    data: { data: recipes = [] } = {},
    isLoading: isLoadingRecipes
  } = useGetRecipesQuery(undefined, {
    skip: !open,
  });

  const filteredRecipes = useMemo(() => {
    return recipes?.filter((recipe) => recipe.name.toLowerCase().includes(searchQuery.toLowerCase()))
  }, [recipes, searchQuery])


  const [insertRecipe, { isLoading: isInsertingRecipe }] = useInsertRecipeMutation();

  const [selectedRecipeId, setSelectedRecipeId] = useState(undefined);
  const [openFlowAiDialog, setOpenFlowAiDialog] = useState(false);

  const onSelect = (id) => {
    selectedRecipeId == id ? setSelectedRecipeId(undefined) : setSelectedRecipeId(id);
  };

  const handleSave = () => {
    const scrollState = gantt.getScrollState();
    insertRecipe({
      taskId: taskId > 0 ? taskId : undefined,
      projectId,
      recipeId: selectedRecipeId,
    })
      .unwrap()
      .then((response) => {
        const tasks = !isEmpty(response?.entities?.task)
          ? Object.values(response.entities.task).map((task) => {
            const formattedTask = formatTaskToGanttTask(task);
            if (gantt.config.isTaskPortfolio) {
              formattedTask.parent = formattedTask.parent ? formattedTask.parent : formattedTask.project_id * -1
            }
            return formattedTask
          })
          : [];
        const dependencies = !isEmpty(response?.entities?.dependencies)
          ? Object.values(response.entities.dependencies)
          : [];
        gantt.parse({ tasks, links: dependencies });
        gantt.scrollTo(scrollState.x, scrollState.y);
        // Keep scroll position?
        onClose();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onClose = () => {
    setSelectedRecipeId(undefined);
    handleClose();
  };

  return (
    <Fragment>
      {isInsertingRecipe && (
        <Backdrop open={true} style={{ zIndex: 13 }} sx={{ color: '#fff' }}>
          <CircularProgress className="loading" />
        </Backdrop>
      )}
      {open && (
        <Drawer
          anchor={'right'}
          open={open}
          PaperProps={{ sx: { width: '300px', maxWidth: '90vw' } }}
          SlideProps={{ mountOnEnter: true, unmountOnExit: true }}
          variant={'persistent'}
        >
          <DialogTitle sx={{ borderBottom: '1px solid lightgrey', py: 1, px: 2 }}>
            <Stack gap={1}>
              <Typography color={"textSecondary"}>SELECT RECIPE</Typography>
              <SearchBox
                bgcolor={'grey.200'}
                borderRadius={1}
                fullWidth={false}
                placeholder="Search By Recipe Name"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Stack>
          </DialogTitle>
          <DialogContent sx={{ p: 0 }}>
            <List disablePadding>
              {isLoadingRecipes
                ? times(10, (row) => {
                  return (
                    <ListItem key={`skeleton-row-${row}`}>
                      <ListItemIcon>
                        <IconButton edge={'start'} size="large">
                          <Skeleton height={25} variant="circular" width={25} />
                        </IconButton>
                      </ListItemIcon>
                      <ListItemText>
                        <Skeleton width={'80%'} />
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <IconButton edge={'end'} size="large">
                          <Skeleton height={24} variant="circular" width={24} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })
                : filteredRecipes?.map((recipe) => (
                  <RecipeRow
                    isSelected={recipe.id === selectedRecipeId}
                    key={recipe.id}
                    recipe={recipe}
                    onSelect={onSelect}
                  />
                ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button type="small" onClick={handleClose}>
              Close
            </Button>
            <Button
              color="secondary"
              disabled={!selectedRecipeId}
              size="small"
              variant="contained"
              onClick={() => setOpenFlowAiDialog(true)}
            >
              Flow AI
            </Button>
            <Button
              color="primary"
              disabled={!selectedRecipeId}
              size="small"
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          </DialogActions>

          {openFlowAiDialog && (
            <FlowAiDialog
              ganttId={ganttId}
              handleClose={() => setOpenFlowAiDialog(false)}
              handleCloseRecipeDrawer={handleClose}
              open={openFlowAiDialog}
              projectId={projectId}
              recipeId={selectedRecipeId}
              taskId={taskId}
            />
          )}
        </Drawer>
      )}
    </Fragment>
  );
};

const RecipeRow = ({ recipe, isSelected, onSelect }) => {
  const [openDetailDialog, setOpenDetailDialog] = useState(false);

  const handleOpenDetailDialog = () => {
    setOpenDetailDialog(true);
  };

  const handleCloseDetailDialog = () => {
    setOpenDetailDialog(false);
  };

  return (
    <ListItem
      disablePadding
      secondaryAction={
        <IconButton edge={'end'} onClick={handleOpenDetailDialog}>
          <Info />
        </IconButton>
      }
    >
      <ListItemButton onClick={() => onSelect(recipe.id)}>
        <ListItemIcon>
          <Checkbox disableRipple checked={isSelected} edge={'start'} tabIndex={-1} />
        </ListItemIcon>
        <ListItemText primary={recipe.name}></ListItemText>
      </ListItemButton>
      {openDetailDialog && (
        <RecipeDetailsDialog
          handleClose={handleCloseDetailDialog}
          open={openDetailDialog}
          recipe={recipe}
        />
      )}
    </ListItem>
  );
};

export default RecipeDrawer;
