import { useModal } from 'components/common/ModalProvider';
import ConfirmDeleteDialog from 'components/common/popovers/ConfirmDeleteDialog';
import { JobwalkDialog } from 'features/jobwalk/components/JobwalkDialog';
import StuckPointDialog from 'features/stuckPoints/components/StuckPointDialog';

export const LOOKAHEAD_MODALS = {
  JOBWALK: 'jobwalkDialog',
  STUCK_POINT: 'stuckPointDialog',
  DELETE: 'deleteDialog',
};

export const LookaheadModals = () => {
  const { openModalId, openModalMeta, closeModal } = useModal();

  return (
    <>
      {/* Modals */}
      {openModalId === LOOKAHEAD_MODALS.JOBWALK && (
        <JobwalkDialog handleClose={closeModal} open={true} {...openModalMeta} />
      )}
      {openModalId === LOOKAHEAD_MODALS.STUCK_POINT && (
        <StuckPointDialog handleClose={closeModal} open={true} {...openModalMeta} />
      )}
      {openModalId === LOOKAHEAD_MODALS.DELETE && (
        <ConfirmDeleteDialog handleClose={closeModal} open={true} {...openModalMeta} />
      )}
    </>
  );
};
