import {
  Share,
  ShoppingCart,
  Settings,
  PlaylistAdd,
  PendingActions,
  DirectionsWalk,
  Comment,
  TrackChanges,
} from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { SectionLayout } from 'components/layouts/SectionLayout';
import { Label as LabelComponent } from 'features/labels/components/Label';
import { useMemo } from 'react';
import { CommentTab } from './CommentTab';
import { DependencyTab } from './DependencyTab';
import { DetailsTab } from './DetailsTab';
import { JobwalkTab } from './JobwalkTab';
import { ProcurementTab } from './ProcurementTab';
import { StepTab } from './StepTab';
import { StuckPointTab } from './StuckPointTab';
import { TargetsTab } from './TargetsTab';

/**
 * Production Board Task Details
 * @returns
 */
export const TaskDetails = ({ task = {}, isLoading, handleTaskUpdate, handleTaskDelete }) => {
  const { project_id: projectId, id: taskId } = task;

  const sections = useMemo(
    () => [
      {
        name: 'Details',
        id: 'details-tab',
        icon: <Settings />,
        component: (
          <DetailsTab
            handleTaskDelete={handleTaskDelete}
            handleTaskUpdate={handleTaskUpdate}
            task={task}
          />
        ),
      },
      {
        name: 'Steps',
        id: 'step-tab',
        icon: <PlaylistAdd />,
        component: <StepTab projectId={projectId} taskId={taskId} />,
      },
      {
        name: <LabelComponent labelKey="stuck_point" plural={true} />,
        id: 'stuckPoint-tab',
        icon: <PendingActions />,
        component: <StuckPointTab projectId={projectId} taskId={taskId} />,
      },
      {
        name: 'Comments',
        id: 'comment-tab',
        icon: <Comment />,
        component: <CommentTab taskId={taskId} />,
      },
      {
        name: 'Dependencies',
        id: 'dependency-tab',
        icon: <Share />,
        component: <DependencyTab taskId={taskId} />,
      },
      {
        name: 'Jobwalk',
        id: 'jobwalk-tab',
        icon: <DirectionsWalk />,
        component: <JobwalkTab taskId={taskId} />,
      },
      {
        name: 'Target',
        id: 'target-tab',
        icon: <TrackChanges />,
        component: <TargetsTab projectId={projectId} taskId={taskId} />,
      },
      {
        name: 'Procurements',
        id: 'procurement-tab',
        icon: <ShoppingCart />,
        component: <ProcurementTab taskId={taskId} />,
      },
    ],
    [handleTaskDelete, handleTaskUpdate, projectId, task, taskId]
  );

  return (
    <>
      {isLoading ? (
        <CircularProgress className={'loading'} />
      ) : (
        <SectionLayout sections={sections} />
      )}
    </>
  );
};
