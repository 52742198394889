import { StyledDialog } from '@blackhyve/common';
import { Alert, Backdrop, Button, CircularProgress } from '@mui/material';
import useSanitizedObject from 'hooks/useSantizedObject';
import { useForm } from 'react-hook-form';
import { Resource, ResourceType, ResourceUnit } from '../api/resources.models';
import { ResourceForm } from './ResourceForm';
import { useCreateResourceMutation, useUpdateResourceMutation } from '../api/resources.api';
import { isArray } from 'lodash';
import { useEffect } from 'react';

const defaultValues: Omit<Resource, 'id'> = {
  name: '',
  type: ResourceType.Labor,
  unit: ResourceUnit.Hours,
  max_capacity: 8,
  workday_calendar: {
    sunday: '0',
    monday: '1',
    tuesday: '1',
    wednesday: '1',
    thursday: '1',
    friday: '1',
    saturday: '0',
  },
  parents: [],
};

export function ResourceDialog({
  isGroup,
  open,
  handleClose,
  resource,
}: {
  isGroup: boolean;
  open: boolean;
  handleClose: any;
  resource?: Resource;
}) {
  const form = useForm({ defaultValues, values: useSanitizedObject(resource) });

  const isUpdate = Boolean(resource);
  const [createResource, { isLoading: isCreateLoading, isError: isCreateError }] =
    useCreateResourceMutation();
  const [updateResource, { isLoading: isUpdateLoading, isError: isUpdateError }] =
    useUpdateResourceMutation();

  const onSubmit = (data) => {
    data.parents = isArray(data.parents) ? data.parents : [data.parents];
    if (data.parents.includes('')) {
      data.parents = [];
    }

    if (isUpdate && resource?.id) {
      updateResource({ resourceId: resource?.id, data }).then(() => {
        handleClose();
      });
    } else {
      createResource({ data })
        .unwrap()
        .then(() => {
          handleClose();
          form.reset({ defaultValues });
        });
    }
  };

  useEffect(() => {
    if (!isUpdate && open) {
      form.reset(defaultValues);
    }
  }, [isUpdate, open, form]);

  return (
    <StyledDialog
      handleClose={handleClose}
      open={open}
      title={resource ? 'Update Resource' : 'Create Resource'}
      actions={
        <>
          <Button onClick={handleClose}>Close</Button>
          <Button variant="contained" onClick={form.handleSubmit(onSubmit)}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </>
      }
    >
      {(isCreateLoading || isUpdateLoading) && (
        <Backdrop open={true} style={{ zIndex: 13 }} sx={{ color: '#fff' }}>
          <CircularProgress />
        </Backdrop>
      )}
      {(isCreateError || isUpdateError) && (
        <Alert severity="error">An error occurred. Please try again.</Alert>
      )}
      <ResourceForm form={form} isGroup={isGroup} onSubmit={onSubmit} />
    </StyledDialog>
  );
}
