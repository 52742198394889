import { ArrowRightAlt } from '@mui/icons-material';
import { Box, FormLabel } from '@mui/material';

export const ValueWithLabel = ({ initialValue, value, label, ValueProps, ...props }) => {
  return (
    <Box {...props}>
      <Box component={FormLabel} whiteSpace={'nowrap'}>
        {label}
      </Box>
      <Box alignItems={'center'} display={'flex'}>
        {initialValue !== undefined && (
          <>
            <Box component={'span'} sx={{ textDecoration: 'line-through', color: 'GrayText' }}>
              {initialValue}
            </Box>
            <ArrowRightAlt fontSize="inherit" />
          </>
        )}
        <Box component={'span'} {...ValueProps}>
          {value}
        </Box>
      </Box>
    </Box>
  );
};
