// import { Popover } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React, { useState } from 'react';
import Popover from '../components/Popover';

const OptionsMenu = ({ triggerButton, children, variant = 'light', ...props }) => {
  const [anchorEl, setAnchorEl] = useState(undefined);

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const trigger = triggerButton ? (
    React.cloneElement(triggerButton, { onClick: handleOpen })
  ) : (
    <MoreHorizIcon className="moreHoriz" onClick={handleOpen} />
  );

  return (
    <>
      {trigger}
      <Popover
        variant={variant}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        {...props}
      >
        {children(handleClose)}
      </Popover>
    </>
  );
};

export default OptionsMenu;
