import { Container } from '@mui/material';
import { ContentLayout } from 'components/layouts/ContentLayout';
import { selectCurrentUserWorkspaceId } from 'features/auth';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProjectsTable } from '../components/ProjectsTable';

export const Projects = () => {
  const navigate = useNavigate();
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  return (
    <ContentLayout>
      <Container maxWidth="xl" sx={{ mt: 4 }}>
        <ProjectsTable
          rowProps={(project) => ({
            sx: { cursor: 'pointer' },
            role: 'button',
            onClick: () => navigate(`/workspace/${workspaceId}/projects/${project.id}`),
          })}
        />
      </Container>
    </ContentLayout>
  );
};
