import { Stack } from '@mui/material';
import { RadioCard } from 'components/inputs/RadioCard';
import { usePresets } from '../hooks/usePresets';

export const PresetStep = ({ value, onChange, task, form, holidays }) => {
  const presets = usePresets({ task, form, holidays });

  return (
    <>
      <Stack spacing={1}>
        {presets.map((preset) => (
          <RadioCard
            isSelected={value?.value === preset.value}
            key={preset.value}
            label={preset.label}
            value={preset}
            onChange={onChange}
          />
        ))}
      </Stack>
    </>
  );
};
