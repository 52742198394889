import { GanttStatic } from '@blackhyve/dhtmlx-gantt';
import { ResourcesPanel } from 'features/resources/helpers/setupResourcesGrid';

interface LayoutConfig {
  resources?: {
    enabled: boolean;
    visible: boolean;
  };
}

export function setupLayout(gantt: GanttStatic, ganttId: string, layoutConfig?: LayoutConfig) {
  const baseLayout = {
    css: 'gantt_container',
    rows: [
      {
        gravity: 2,
        cols: [
          {
            width: 600,
            group: 'grids',
            css: 'gantt_task_grid',
            rows: [
              {
                view: 'grid',
                scrollX: 'gridScrollX',
                scrollY: 'mainScrollY',
                scrollable: true,
              },
              { view: 'scrollbar', id: 'gridScrollX' },
            ],
          },
          { resizer: true, width: 1, group: 'resizer' },
          {
            rows: [
              { view: 'timeline', scrollY: 'mainScrollY', scrollX: 'mainScrollX' },
              { view: 'scrollbar', id: 'mainScrollX' },
            ],
          },
          { view: 'scrollbar', id: 'mainScrollY' },
        ],
      },
    ],
  };

  const upArrowSvg = '<path d="M7.41 15.41 12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path>';
  const downArrowSvg = '<path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6z"></path>';

  const toggleResourcesPanel = [
    {
      height: 35,
      cols: [
        {
          html: `
            <div 
              onclick="
                ganttStore.getGantt('${ganttId}').callEvent('toggleResourcesPanel', [${!layoutConfig?.resources?.visible}]);
                document.documentElement.style.setProperty('--gantt-frozen-column-scroll-left', '0px');
              "
            >
              <svg style="width: 18px; height: 18px; margin-right: 4px" viewBox="0 0 24 24" fill="none" stroke="rgba(0,0,0,0.87)" stroke-width="2">${
                layoutConfig?.resources?.visible ? downArrowSvg : upArrowSvg
              }</svg>
              RESOURCES
            </div>
          `,
          css: 'gantt_toggle_resources_panel',
        },
      ],
    },
  ];

  const resourcesPanelLayout = [
    {
      gravity: 1,
      id: 'resources',
      group: 'resources',
      config: {
        scale_height: 30,
        row_height: 30,

        columns: [
          {
            name: 'resource',
            label: 'Resource Name',
            tree: true,
            align: 'left',
            template: function (resource) {
              return resource.name;
            },
            resize: true,
          },
          {
            name: 'max_capacity',
            label: `Capacity`,
            align: 'center',
            template: function (resource) {
              const ganttZoomIndex = gantt.ext.zoom.getCurrentLevel();
              const ganttZoomLevels = gantt.ext.zoom.getLevels();
              const currentGanttZoomLevel = ganttZoomLevels[ganttZoomIndex];

              const workingDaysMapping = {
                days: 1,
                weeks: 5,
                months: 20,
                quarters: 60,
                years: 240,
              };

              const zoomLevelName = currentGanttZoomLevel.name;
              const singularZoomLevelName = zoomLevelName.substr(0, zoomLevelName.length - 1);
              const resourceCapacityMeasure = 'hrs';
              const zoomLevelInDays = workingDaysMapping[zoomLevelName] || 1;
              const capacity = (resource.max_capacity * zoomLevelInDays).toLocaleString();

              return `${capacity} ${resourceCapacityMeasure} / ${singularZoomLevelName}`;
            },
            resize: true,
          },
        ],
      },
      templates: {
        grid_row_class: ResourcesPanel.gridRowClass,
        task_row_class: ResourcesPanel.taskRowClass,
      },
      cols: [
        {
          width: 600,
          group: 'grids',
          rows: [
            {
              view: 'resourceGrid',
              scrollX: 'resourceGridScrollX',
              scrollY: 'resourceScrollY',
            },
            { view: 'scrollbar', id: 'resourceGridScrollX' },
          ],
        },
        { resizer: true, width: 1, group: 'resizer' },
        {
          view: 'resourceTimeline',
          scrollX: 'mainScrollX',
          scrollY: 'resourceScrollY',
        },
        { view: 'scrollbar', id: 'resourceScrollY', group: 'vertical' },
      ],
    },
  ];

  gantt.config.layout = {
    ...baseLayout,
    rows: baseLayout.rows
      .concat(layoutConfig?.resources?.enabled ? toggleResourcesPanel : ([] as any))
      .concat(layoutConfig?.resources?.visible ? resourcesPanelLayout : ([] as any)),
  };
}
