import { AnalyticsWindow } from './AnalyticsWindow';
import { ForecastedCrewSize } from './ForecastedCrewSize';

export const analyticsRoutes = [
  {
    path: '',
    element: <AnalyticsWindow />,
  },
  {
    path: 'crew-size',
    element: <ForecastedCrewSize />,
  },
];
