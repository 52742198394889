import { Grid, Popover, TextField } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({}));

/**
 *
 * @param {*} props
 * @param {HTMLElement} props.anchorEl - Anchor element for menu
 * @param {Function} props.handleClose - Handle closing of the menu
 * @param {String} props.labelText - Text for label
 * @param {String} props.placeholder - Placeholder text
 * @param {String} props.textBoxType - HTML input type
 * @param {Function} props.handleUpdate - Function for handling updates
 * @param {String | Number} props.value - Initial value
 * @returns {JSX}
 */
export const EntityUpdateMenu = (props) => {
  const classes = useStyles();
  const {
    anchorEl,
    handleClose,
    labelText,
    placeholder = '',
    textBoxType = 'text',
    handleUpdate,
    value,
    anchorOrigin,
    transformOrigin,
  } = props;

  return (
    <Popover
      anchorEl={anchorEl}
      classes={{ paper: classes.entityUpdatePaper }}
      open={Boolean(anchorEl)}
      anchorOrigin={
        anchorOrigin
          ? anchorOrigin
          : {
              vertical: 'center',
              horizontal: 'center',
            }
      }
      transformOrigin={
        transformOrigin
          ? transformOrigin
          : {
              horizontal: 'center',
              vertical: 'center',
            }
      }
      onClose={handleClose}
    >
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={5}>
          <p className={classes.timeEstimateLabel}>{labelText}</p>
        </Grid>
        <Grid item xs={6}>
          <TextField
            autoFocus
            fullWidth
            className={classes.textField}
            defaultValue={value}
            placeholder={placeholder}
            size="small"
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
            type={textBoxType}
            variant="outlined"
            onBlur={(event) => handleUpdate(event.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleUpdate(e.target.value);
                handleClose();
              }
            }}
          />
        </Grid>
      </Grid>
    </Popover>
  );
};

export default EntityUpdateMenu;
