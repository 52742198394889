import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';
import { uploadFile } from 'features/users';
import Backdrop from '@mui/material/Backdrop';
import ImageUploader from 'quill-image-uploader';
import ImageResize from 'quill-image-resize-module-react';
import CircularProgress from '@mui/material/CircularProgress';
import { useRef, useState, useMemo, Fragment, useCallback } from 'react';
import 'react-quill/dist/quill.snow.css';
import '../../../assets/css/rich-text-editor.css';

ReactQuill.Quill.register('modules/imageUploader', ImageUploader);
ReactQuill.Quill.register('modules/imageResize', ImageResize);

const DEFAULT_TOOLBAR_OPTIONS = [
  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'image', 'link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
];

const formats = [
  'header',
  'bold',
  'list',
  'bullet',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'align',
  'color',
  'background',
  'image',
  'link',
  'width',
];

const RichTextEditor = ({
  text,
  handleOnChange,
  readOnly = false,
  theme,
  className,
  toolbarOptions,
}) => {
  const quillRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const imageHandler = useCallback(() => {
    const editor = quillRef.current.getEditor();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      try {
        dispatch(uploadFile(formData))
          .unwrap()
          .then(async (response) => {
            const link = response?.url;
            const range = editor.getSelection();
            link && editor.insertEmbed(range, 'image', link);
            // Adding hak to enter new line after inserting image
            link &&
              setTimeout(() => {
                editor.insertText(range + 1, '\n', true);
              }, 1000);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    };
  }, [dispatch]);

  const modulesObj = useMemo(
    () => ({
      toolbar: {
        handlers: { image: imageHandler },
        container: toolbarOptions ? toolbarOptions : DEFAULT_TOOLBAR_OPTIONS,
      },
      clipboard: {
        matchVisual: false,
      },
      imageResize: {
        displaySize: true,
        parchment: ReactQuill.Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize'],
      },
    }),
    [imageHandler, toolbarOptions]
  );

  // useEffect(() => {
  //   quillRef?.current?.focus()
  // }, [])

  return (
    <Fragment>
      {loading && (
        <Backdrop open={true} style={{ zIndex: 13 }} sx={{ color: '#fff' }}>
          <CircularProgress className="loading" />
        </Backdrop>
      )}
      <ReactQuill
        bounds="quill"
        className={className}
        formats={formats}
        modules={modulesObj}
        readOnly={readOnly}
        ref={quillRef}
        theme={theme}
        value={text}
        onChange={handleOnChange}
      />
    </Fragment>
  );
};

export default RichTextEditor;
