import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  IconButton,
} from '@mui/material';

interface StyledDialogProps extends DialogProps {
  open: boolean;
  handleClose: () => void;
  actions?: JSX.Element;
  dialogTitleProps?: DialogTitleProps;
  dialogContentProps?: DialogContentProps;
}

const StyledDialog = ({
  open,
  handleClose,
  title = 'Dialog Title',
  children,
  actions,
  dialogTitleProps,
  dialogContentProps,
  ...props
}: StyledDialogProps) => {
  return (
    <Dialog
      fullWidth
      aria-labelledby={`dialog-${title}`}
      open={open}
      onClose={handleClose}
      {...props}
    >
      <DialogTitle {...dialogTitleProps}>{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: (theme) => theme.spacing(1),
          top: (theme) => theme.spacing(1),
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent {...dialogContentProps}>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default StyledDialog;
