import { useState } from 'react';
import Box from '@mui/material/Box';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Button, CircularProgress, Grid, Popover } from '@mui/material';
import { useGetAllResourcesQuery } from 'features/resources/api/resources.api';
import { SelectResourcesAutocompleteList } from 'features/resources/components/SelectResourcesList';


/**
 * Task bulk edit resource menu item
 * @param {object} handleUpdate
 * @returns
 */
const TaskBulkEditResourceMenuItem = ({ handleUpdate }) => {
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [selected, setSelected] = useState([]);

  const { data: fetchedResources = [], isLoading } = useGetAllResourcesQuery({});

  const handleSubmit = () => {
    handleUpdate(selected);
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(undefined);
    setSelected([]);
  };


  return (
    <>
      <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
        Resource
        {anchorEl ? <ArrowDropDown sx={{ fontSize: 18 }} /> : <ArrowDropUp sx={{ fontSize: 18 }} />}
      </Button>
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
        >
          <Box style={{ width: '500px', padding: '10px 10px' }}>
            <div style={{ maxHeight: '300px' }}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <SelectResourcesAutocompleteList
                  isLoading={isLoading}
                  resources={fetchedResources}
                  selected={selected}
                  setSelected={setSelected}
                />
              )}
            </div>
            <Grid container justifyContent={'flex-end'} spacing={1}>
              <Grid item>
                <Button color={'primary'} size={'small'} onClick={handleSubmit}>
                  Close
                </Button>
              </Grid>
              <Grid item>
                <Button color={'primary'} size={'small'} variant="contained" onClick={handleSubmit}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Popover>
      )}
    </>
  );
};



export default TaskBulkEditResourceMenuItem;
