import { gantt as globalGantt } from '@blackhyve/dhtmlx-gantt';
import '@blackhyve/dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';
import './Gantt.css';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import { isDate, isValid } from 'date-fns';
import addYears from 'date-fns/addYears';
import React, { Component, memo, useDeferredValue, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import '../../../components/projectOverview/gantt/gantt.css';
import { setupResourcesGrid } from 'features/resources/helpers/setupResourcesGrid';
import { setupLayout } from '../helpers/setupLayout';
import { groupBy } from 'lodash';

const isTestEnv = process.env.NODE_ENV === 'test';

const defaultConfig = {
  ...globalGantt.config,
  auto_types: true,
  auto_scheduling: true,
  auto_scheduling_strict: false,
  auto_scheduling_compatibility: false,
  date_format: '%Y-%m-%d',
  deepcopy_on_parse: true,
  date_grid: '%m-%d-%Y',
  round_dnd_dates: false,
  correct_work_time: true,
  work_time: true,
  time_step: 60 * 24,
  tooltip_offset_y: 20,
  tooltip_offset_x: 10,
  tooltip_timeout: 200,
  open_tree_initially: true,
  show_tasks_outside_timescale: true,
  show_unscheduled: true,
  drag_multiple: false,
  static_background: isTestEnv ? false : true,
  static_background_cells: isTestEnv ? false : true,
  smart_rendering: isTestEnv ? false : true,
  grid_elastic_columns: true,
  smart_scales: isTestEnv ? false : true,
  multiselect: true,
  inline_editors_multiselect_open: true,
  keyboard_navigation: true,
  keyboard_navigation_cells: true,
  order_branch: 'marker',
  order_branch_free: true,
  placeholder_task: { focusOnCreate: false },
  show_errors: false,
  scale_height: 35,
  task_height: 16,
  row_height: 35,
  bar_height: 16,
  min_duration: 24 * 60 * 60 * 1000, // (1 day)
  duration_unit: 'day',
  links: {
    finish_to_start: 'FS',
    start_to_start: 'SS',
    finish_to_finish: 'FF',
    start_to_finish: 'SF',
  },
  drag_progress: false,
  constraint_types: {
    ASAP: 'asap',
    ALAP: 'alap',
    SNET: 'snet',
    SNLT: 'snlt',
    FNET: 'fnet',
    FNLT: 'fnlt',
    MSO: 'mso',
    MFO: 'mfo',
  },
  show_markers: true,
  reorder_grid_columns: true,
  // external_render: {
  //   // checks the element is a React element
  //   isElement: (element) => {
  //     return React.isValidElement(element);
  //   },
  //   // renders the React element into the DOM
  //   renderElement: (element, container) => {
  //     const root = createRoot(container);
  //     root.render(element);
  //   },
  // },
};

const defaultConstants = {
  ...globalGantt.constants,
};

const defaultPlugins = {
  marker: true,
  export_api: false,
  auto_scheduling: true,
  click_drag: true,
  tooltip: true,
  keyboard_navigation: true,
  multiselect: true,
};

const defaultTemplates = (gantt) => ({
  ...globalGantt.templates,
  grid_date_format: function (date, column) {
    const gridDateToStr = gantt.date.date_to_str(gantt.config.date_grid);
    if (column === 'end_date' || column === 'forecasted_end_date') {
      return gridDateToStr(new Date(date.valueOf() - 1));
    } else {
      return gridDateToStr(date);
    }
  },
  format_date: function (date) {
    return date;
  },
});

/**
 * React wrapper for DHTMLX Gantt Chart
 * @param {String} ganttId
 * @param {[Object]} columns
 * @param {Object} constants
 */
export default class Gantt extends Component {
  constructor(props) {
    super(props);
    this.ganttContainer = null;
    this.state = {
      ganttReady: false,
      tasks: [],
      links: [],
      gantt: undefined,
      ganttId: undefined,
      isResourcesPanelVisible: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {}

  toggleResourcesPanelVisibility = () => {
    this.setState(
      (prevState) => ({
        isResourcesPanelVisible: !prevState.isResourcesPanelVisible,
      }),
      () => {
        const gantt = ganttStore.getGantt(this.props.ganttId);

        setupLayout(gantt, this.props.ganttId, {
          resources: {
            enabled: true,
            visible: this.state.isResourcesPanelVisible,
          },
        });

        gantt.init(this.ganttContainer);
      }
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.ganttReady !== this.state.ganttReady) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    const {
      ganttId,
      columns = [],
      constants,
      typeRenders = {},
      initDataProcessor,
      events = {},
      inlineEvents = {},
      zoomConfig,
      templates,
      config,
      data,
      plugins = {},
      layers = [],
      initMakers,
      resources,
      resourceAssignments,
      serverLists = [],
      onGanttReady,
    } = this.props;

    const gantt = ganttStore.createGantt(ganttId);

    gantt.plugins({ ...defaultPlugins, ...plugins });
    gantt.config = Object.assign(gantt.config, {
      ...defaultConfig,
      ...config,
      editor_types: gantt.config.editor_types,
    });
    gantt.constants = { ...defaultConstants, ...constants };
    if (zoomConfig) {
      gantt.ext.zoom.init(zoomConfig(gantt));
      gantt.ext.zoom.attachEvent('onAfterZoom', function (level, config) {
        if (
          isDate(gantt?._minDate) &&
          isDate(gantt?._maxDate) &&
          isValid(gantt._minDate) &&
          isValid(gantt._maxDate)
        ) {
          if (config.name === 'years') {
            gantt.config.start_date = addYears(gantt._minDate || gantt.constants.start_date, -1);
            gantt.config.end_date = addYears(gantt._maxDate || gantt.constants.end_date, 1);
          } else {
            gantt.config.start_date =
              gantt._minDate || gantt.constants.start_date || gantt._min_date;
            gantt.config.end_date = gantt._maxDate || gantt.constants.end_date || gantt._max_date;
          }
        }
      });
    }
    gantt.config.type_renderers = { ...gantt.config.type_renderers, ...typeRenders };
    gantt.config.columns = columns;
    gantt.showLightbox = (id) => {};
    columns.forEach((column) => {
      if (column?.editor_type) {
        gantt.config.editor_types[column?.editor?.type] = column.editor_type();
      }
    });
    gantt.constants = { ...gantt.constants, constants };

    gantt.templates = {
      ...defaultTemplates(gantt),
      ...(templates instanceof Function ? templates(gantt) : templates),
    };

    if (Array.isArray(events)) {
      events.forEach((eventObj) => {
        for (let event in eventObj) {
          gantt.attachEvent(event, eventObj[event]);
        }
      });
    } else {
      for (let event in events) {
        gantt.attachEvent(event, events[event]);
      }
    }

    const inlineEditors = gantt.ext.inlineEditors;
    for (let inlineEvent in inlineEvents) {
      inlineEditors.attachEvent(inlineEvent, inlineEvents[inlineEvent].bind(gantt));
    }

    setupLayout(gantt, ganttId, {
      resources: {
        enabled: resources && resources.length,
        visible: this.state.isResourcesPanelVisible,
      },
    });

    gantt.attachEvent('onGanttReady', function () {
      for (let layer in layers) {
        layers[layer].bind(this)();
        // this.addTaskLayer(taskLayerFunction);
      }
      if (onGanttReady) {
        onGanttReady();
      }
    });

    gantt.attachEvent('onParse', function () {
      if (initMakers) {
        initMakers(ganttId);
      }
    });

    initDataProcessor && initDataProcessor(gantt);

    this.setState({
      ganttReady: true,
      ganttId: ganttId,
      ...data,
    });

    // Setup resources
    if (resources) {
      gantt.attachEvent('toggleResourcesPanel', this.toggleResourcesPanelVisibility);
      const resourcesStore = setupResourcesGrid(gantt);
      resourcesStore.parse(resources);
    }
    gantt.init(this.ganttContainer);

    const groupedAssignments = groupBy(resourceAssignments, 'task_id');

    //assign customResources Id first...
    gantt.parse({
      tasks: data.tasks.map((t) => ({
        ...t,
        resources: groupedAssignments[t.id],
      })),
      links: data.links,
    });
  }

  componentWillUnmount() {
    const gantt = ganttStore.getGantt(this.props.ganttId);
    if (gantt) {
      if (gantt.ext.inlineEditors.save) {
        gantt.ext.inlineEditors.save();
      }
      document.documentElement.style.setProperty('--gantt-frozen-column-scroll-left', 0);
      gantt?.destructor();
    }
    console.log('Gantt Unmounted');
    ganttStore.deleteInstance(this.props.ganttId);
    this.setState({ ganttReady: false, tasks: [], links: [] });
  }

  render() {
    return (
      <>
        <div
          className={this.props.config.readonly ? 'read-only-gantt' : ''}
          id="gantt-container-blackhyve"
          style={{ width: '100%', height: '100%', overflow: 'hidden' }}
          ref={(input) => {
            this.ganttContainer = input;
          }}
        ></div>
        <ExternalRender ganttId={this.props.ganttId} ganttReady={this.state.ganttReady} />
      </>
    );
  }
}

const ExternalRender = ({ ganttId, ganttReady }) => {
  const gantt = ganttStore.getGantt(ganttId);
  const [componentList, setComponentList] = useState({});
  const deferredComponentList = useDeferredValue(componentList);

  useEffect(() => {
    if (gantt && ganttReady) {
      gantt.config.external_render = {
        // checks the element is a React element
        isElement: (element) => {
          return React.isValidElement(element);
        },
        // renders the React element into the DOM
        renderElement: (element, container) => {
          if (element.key) {
            container.innerHTML = '';
            setComponentList((prevState) => ({
              ...prevState,
              [element.key]: { element, container },
            }));
          } else {
            console.warn('Element needs a key: ', element);
          }
        },
        removeElement: (key) => {
          setComponentList((prevState) => {
            const clone = { ...prevState };
            delete clone[key];
            return clone;
          });
        },
      };
      if (!gantt.$destroyed) {
        gantt?.render();
      }
    }
  }, [gantt, ganttReady]);

  return <ComponentList components={deferredComponentList} />;
};

const ComponentList = memo(function SlowList({ components }) {
  return (
    <>
      {Object.entries(components).map(([key, { element, container }]) =>
        createPortal(element, container, key)
      )}
    </>
  );
});
