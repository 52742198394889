import { parseDate } from '@blackhyve/utilities/dates';
import { TrackChanges } from '@mui/icons-material';
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListSubheader,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { WeekPagination } from 'components/WeekPagination';
import { format, startOfWeek } from 'date-fns';
import { Status } from 'features/tasks';
import { groupBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useIntersectionObserver } from 'usehooks-ts';
import { useGetWeeklyWorkPlanPreviewQuery } from '../api/target.api';
import { TargetDetails } from './TargetDetails';
import { TargetMoreOptionsMenu } from './TargetMoreOptionsMenu';

const dateFormatOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

const startOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

export const PublishWeeklyWorkPlanForm = ({
  taskId,
  projectId,
  form,
  onSubmit,
  setDate,
  date,
  disableChangeDate = false,
}) => {
  const {
    data,
    isLoading: isLoadingWWP,
    isFetching: isFetchingWWP,
  } = useGetWeeklyWorkPlanPreviewQuery({
    date: format(date, 'yyyy-MM-dd'),
    projectId: projectId,
    parentId: taskId,
  });

  const { targets = emptyArray, message = '' } = useMemo(() => data || {}, [data]);

  useEffect(() => {
    if (!isLoadingWWP && !isFetchingWWP) {
      form.reset({ date: format(date, 'yyyy-MM-dd'), message: message || '' });
    }
  }, [date, form, isFetchingWWP, isLoadingWWP, message]);

  const { handleSubmit, control } = form;

  return (
    <Box>
      <WeekPagination
        date={date}
        disableChangeDate={disableChangeDate}
        min={startOfThisWeek}
        onWeekChange={setDate}
      />
      <>
        {isLoadingWWP ? (
          <Stack alignItems={'center'} boxSizing={'border-box'} p={4} width={1}>
            <CircularProgress />
          </Stack>
        ) : (
          <>
            {isFetchingWWP && (
              <Box my={1} position={'sticky'} top={0} width={1} zIndex={2}>
                <LinearProgress />
              </Box>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack pt={1} spacing={2}>
                <InputLabel htmlFor={'message'}>Message:</InputLabel>
                <Controller
                  control={control}
                  defaultValue={message}
                  name={'message'}
                  render={({ field, fieldState }) => (
                    <TextField
                      multiline
                      id={'messages'}
                      maxRows={10}
                      minRows={3}
                      placeholder={'Message'}
                      {...field}
                    />
                  )}
                />

                <Divider />
                <InputLabel>Targets:</InputLabel>
                <TargetsList date={date} form={form} targets={targets} />
              </Stack>
              <input hidden tabIndex={-1} type={'submit'} />
            </form>
          </>
        )}
      </>
    </Box>
  );
};
const emptyArray = [];

const initialTargetsToShow = 25;
const TargetsList = ({ form, targets, date }) => {
  const [targetsToShow, setTargetsToShow] = useState(initialTargetsToShow);
  const { ref: loadMoreTargetsRef } = useIntersectionObserver({
    onChange: (isIntersecting) => {
      if (isIntersecting) {
        setTargetsToShow((prev) => prev + initialTargetsToShow);
      }
    },
  });

  // useEffect(() => {
  //   if (!isLoadingTargets || !isFetchingTargets) {
  //     form.reset({
  //       targets: targets.reduce((defaultValues, target) => {
  //         defaultValues[`target_${target.id}`] = target.target_percent_complete;
  //         return defaultValues;
  //       }, {}),
  //     });
  //   }
  // }, [form, targets, date, isLoadingTargets, isFetchingTargets]);

  const grouped = useMemo(
    () =>
      groupBy(targets.slice(0, targetsToShow), (value) =>
        value.task.parents.map(({ name }) => name).join(' > ')
      ),
    [targets, targetsToShow]
  );

  return (
    <>
      {targets.length > 0 ? (
        <>
          <List disablePadding>
            {Object.entries(grouped).map(([groupKey, targets]) => (
              <ListItem disablePadding divider key={groupKey}>
                <List disablePadding sx={{ width: 1 }}>
                  <ListSubheader sx={{ fontWeight: 600, color: 'unset', fontSize: '1rem' }}>
                    {groupKey}
                  </ListSubheader>
                  <Divider />
                  {targets.map((target) => (
                    <ListItem
                      divider
                      key={target.id || target.task.id}
                      secondaryAction={<TargetMoreOptionsMenu target={target} />}
                    >
                      <TargetDetails publishDate={date} target={target} />
                    </ListItem>
                  ))}
                </List>
              </ListItem>
            ))}
          </List>
          {targetsToShow < targets.length && (
            <Stack alignItems={'center'} p={2} ref={loadMoreTargetsRef}>
              <CircularProgress />
            </Stack>
          )}
        </>
      ) : (
        <Alert severity={'info'}>No targets to publish</Alert>
      )}
    </>
  );
};

const TargetFormRow = ({ target, control }) => {
  return (
    <ListItem divider>
      <Stack
        alignItems={{ xs: 'left', sm: 'center' }}
        direction={{ xs: 'column', sm: 'row' }}
        flexWrap={'wrap'}
        spacing={1}
        width={1}
      >
        <Box flex={'1 1 0'}>
          <TargetDetails target={target} />
        </Box>
        {/* <Controller
          control={control}
          defaultValue={target.target_percent_complete}
          name={`targets.target_${target.id}`}
          render={({ field: { onChange, value, ...field }, fieldState }) => (
            <TextField
              {...field}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message}
              label={'Target Percent Complete'}
              size={'small'}
              value={Math.round(value * 100)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Percent />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                const value = Math.max(Math.min(parseFloat(e.target.value) / 100, 1), 0) || 0;
                onChange(value);
              }}
              onFocus={(event) => {
                event.target.select();
              }}
            />
          )}
        /> */}
      </Stack>
    </ListItem>
  );
};

const TargetPreviewDetails = ({ target }) => {
  return (
    <Stack alignItems={'center'} direction={'row'} spacing={2}>
      <Stack alignItems={'center'}>
        <TrackChanges />
        {target.target_percent_complete * 100}%
      </Stack>
      <Stack>
        <Stack direction={'column'}>
          <Stack alignItems={'center'} direction={'row'} spacing={1}>
            <Typography fontWeight={'bold'}>{target?.name}</Typography>
            <Status
              py={0}
              slotProps={{ typography: { fontSize: '.6rem' } }}
              value={target?.status}
            />
          </Stack>
          <Typography component={'span'} variant={'subtitle2'}>
            {target?.parents?.map(({ name }) => name).join(' > ')}
          </Typography>
        </Stack>
        <Stack alignItems={'center'} direction={'row'} spacing={1}>
          <Typography component={'span'} variant={'subtitle2'}>
            {`${parseDate(target?.scheduled_start).toLocaleDateString(undefined, dateFormatOptions)} - ${parseDate(target?.scheduled_end).toLocaleDateString(undefined, dateFormatOptions)}`}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
