import { Resource } from '../api/resources.models';

export function concatenateParentAndChildNames({
  resource,
  parentResourceMap,
}: {
  resource: Resource;
  parentResourceMap: any;
}) {
  const parentNames = resource.parents
    ?.map((parentId) => parentResourceMap[parentId])
    .filter(Boolean);

  return parentNames?.length ? `${parentNames.join(', ')} - ${resource.name}` : resource.name;
}
