import { useEffect } from 'react';

export const useGanttGroupBy = (gantt, groupBy, options = {}) => {
  const { skip = false } = options;
  // Group by
  useEffect(() => {
    // Group by
    if (groupBy && gantt && !gantt.$destroyed && !skip) {
      gantt.plugins({ grouping: true });
      const scrollState = gantt.getScrollState();
      gantt.groupBy(groupBy);
      gantt.scrollTo(scrollState.x, scrollState.y);
    }
  }, [gantt, groupBy, skip]);
};
