import { useState } from 'react';
import Button from '@mui/material/Button';
import EntityUpdateMenu from '../../EntityUpdateMenu';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

export const TaskBulkEditDemandMenuItem = (props) => {

  const { handleUpdate } = props;

  const [anchorEl, setAnchorEl] = useState(undefined);
  const [demand, setDemand] = useState('');

  const handleClose = () => {
    if (demand !== '') {
      handleUpdate(demand);
    }
    setAnchorEl(undefined);
    setDemand('');
  };

  return (
    <>
      <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
        Demand
        {anchorEl ? (
          <ArrowDropDown  style={{ fontSize: 18 }}/>
        ) : (
          <ArrowDropUp  style={{ fontSize: 18 }}/>
        )}
      </Button>
      {anchorEl ? (
        <EntityUpdateMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleUpdate={handleUpdate}
          labelText={'Demand:'}
          textBoxType={'number'}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
        />
      ) : null}
    </>
  );
};


