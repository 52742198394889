import { Container, CircularProgress } from '@mui/material';
import { TaskDetails } from 'features/tasks/components/TaskDetails/TaskDetails';
import {
  useDeleteTasksMutation,
  useGetTaskQuery,
  useUpdateTaskMutation,
} from 'features/tasks/store/task.api';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * Production Board Task Details
 * @returns
 */
const ProductionBoardTaskDetails = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const workspaceId = Number(useParams().workspaceId) || undefined;
  const taskId = Number(useParams().taskId) || undefined;
  const projectId = Number(useParams().projectId) || undefined;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const [deleteTasks, { isLoading: isDeletingTask }] = useDeleteTasksMutation();
  const [updateTasks, { isLoading: isUpdatingTask }] = useUpdateTaskMutation();

  const {
    data: task,
    isLoading: isLoadingTask,
    isFetching: isFetchingTask,
    isError: isErrorFetchingTask,
  } = useGetTaskQuery({ taskId, include: 'tags' });

  const handleDeleteTask = (taskId) => {
    return deleteTasks({ tasks: [taskId] }).unwrap();
  };

  const handleUpdateTask = (updatedTaskValues) => {
    return updateTasks({
      task: {
        id: taskId,
        project_id: task.project_id,
        ...updatedTaskValues,
        tags: updatedTaskValues?.tags.map((tag) => tag.id),
      },
    }).unwrap();
  };

  useEffect(() => {
    if (isErrorFetchingTask) {
      enqueueSnackbar('Task not found', { variant: 'error' });
      navigate(`/workspace/${workspaceId}/projects/${projectId}`);
    }
  }, [isErrorFetchingTask, projectId, workspaceId, enqueueSnackbar, navigate]);

  return (
    <Container maxWidth="xl" sx={{ mt: 2 }}>
      {isLoadingTask ? (
        <CircularProgress className={'loading'} />
      ) : (
        <TaskDetails
          handleTaskDelete={handleDeleteTask}
          handleTaskUpdate={handleUpdateTask}
          isLoading={isLoadingTask}
          task={task}
        />
      )}
    </Container>
  );
};

export default ProductionBoardTaskDetails;
