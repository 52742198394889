import { gantt as globalGantt } from '@blackhyve/dhtmlx-gantt';
import '@blackhyve/dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';
import { endOfDay, startOfDay } from 'date-fns';
import React, { Component, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { EditContext } from '../../common/v3/DisplayWithEditAccess';
import './gantt.css';
import { defaultTaskRender, setTypeRender } from './ganttConfig/bar/barRender';
import { initGanttColumns } from './ganttConfig/column/columnConfig';
import ganttStore from './ganttConfig/ganttStore';
import { setGanttLayout } from './ganttConfig/layout';
import initMakers from './ganttConfig/marker';
import { initSetting as initDefaultSetting } from './ganttConfig/setting';
import { setZoomConfig } from './ganttConfig/zoom';

/**
 * React wrapper for DHTMLX Gantt Chart
 * @param {String|Number}} ganttId - Id of gantt instance
 * @param {[Object]} tasks - array of task objects
 * @param {[Object|string]} [columns] - list of columns
 * @param {Number} projectId - project id
 * @param {Function} [taskRender] - function to render task type
 * @param {Function} [projectRender] - function to render project type
 * @param {Function} [initSettings] - function to initialize gantt settings
 * @param {Date} ganttStartDate - start date for gantt
 * @param {Date} ganttEndDate - end date for gantt
 * @param {[Object]} [taskCalendars] - array of calendars for tasks to use
 * @param {Function} [layoutConfig] - function to initialize the gantt layout
 * @param {Boolean} [isFrozenColumn] - is the first column of the gantt grid sticky
 * @param {Function} [initDataProcessor] - function to initialize the data processor for the gantt to use
 * @param {Function} [registerEventListeners] - function to initialize additional event listeners
 * @param {Function} [registerInlineEventListeners] - function to initialize additional inline editor event listeners
 * @param {Object} [zoomConfig] - object containing zoom settings
 */
export default class Gantt extends Component {
  static contextType = EditContext;

  constructor(props) {
    super(props);
    this.ganttContainer = null;
    this.state = { ganttReady: false };
  }

  componentDidUpdate() {
    // gantt.parse(this.props.tasks);
    // gantt.render();
    console.log('Component Updated');
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.ganttReady !== this.state.ganttReady;
  }

  componentDidMount() {
    const {
      ganttId,
      tasks,
      columns,
      projectId,
      taskRender,
      initSettings,
      ganttStartDate,
      ganttEndDate,
      projectRender,
      taskCalendars,
      layoutConfig,
      isFrozenColumn,
      initDataProcessor,
      registerEventListeners,
      registerInlineExtListeners,
      zoomConfig,
      setTemplates,
      initTooltip,
    } = this.props;

    const gantt = ganttId
      ? ganttStore.getGantt(ganttId) || ganttStore.createGantt(ganttId)
      : globalGantt;

    if (ganttStartDate && ganttEndDate) {
      gantt.constants['project'] = {
        start_date: startOfDay(new Date(ganttStartDate + ' 00:00:00')),
        end_date: endOfDay(new Date(ganttEndDate + ' 00:00:00')),
      };
    }

    initSettings ? initSettings(this.context, ganttId) : initDefaultSetting(this.context, ganttId);

    if (registerEventListeners) {
      registerEventListeners();
    }

    setZoomConfig(zoomConfig, ganttId);

    layoutConfig ? setGanttLayout(layoutConfig, ganttId) : setGanttLayout(null, ganttId);
    taskRender
      ? setTypeRender('task', taskRender, ganttId)
      : setTypeRender('task', defaultTaskRender, ganttId);
    projectRender
      ? setTypeRender('project', projectRender, ganttId)
      : setTypeRender('project', defaultTaskRender, ganttId);

    if (setTemplates) {
      setTemplates(ganttId);
    }
    // SETTING COLUMNS
    if (columns) {
      const ganttColumns = initGanttColumns(ganttId);
      gantt.config.columns = columns.map((column) =>
        typeof column === 'string' ? (ganttColumns[column] ? ganttColumns[column] : {}) : column
      );
    }

    if (isFrozenColumn) {
      gantt.attachEvent('onGanttReady', function () {
        const el = document.querySelector('.gantt_task_grid');
        if (el) {
          el.addEventListener('scroll', function () {
            document.documentElement.style.setProperty(
              '--gantt-frozen-column-scroll-left',
              el.scrollLeft + 'px'
            );
          });
        }
      });
    }

    this.setState({ ganttReady: true });
    gantt.init(this.ganttContainer);
    if (registerInlineExtListeners) {
      registerInlineExtListeners();
    }

    // if (initDataProcessor) {
    //   initDataProcessor(projectId, ganttId);
    // } else {
    //   initGanttDataProcessor(projectId, ganttId);
    // }

    gantt.parse(tasks);
    initMakers(ganttId);

    if (initTooltip) {
      initTooltip(ganttId);
    }

    // window.addEventListener('click', this.windowOnClick, false);
  }

  componentWillUnmount() {
    const gantt = ganttStore.getGantt(this.props.ganttId);
    if (gantt && gantt.ext.inlineEditors.save) {
      gantt?.ext?.inlineEditors?.save();
    }
    document.documentElement.style.setProperty('--gantt-frozen-column-scroll-left', 0);
    document.documentElement.style.setProperty('--gantt-frozen-column-resizer-left', 0);
    if (this.props.removeDataProcessor) {
      this.props.removeDataProcessor();
    } else {
      // removeDataProcessorDefault();
    }
    if (this.props.removeEventListeners) {
      this.props.removeEventListeners();
    }
    gantt?.destructor();
    ganttStore.deleteInstance(this.props.ganttId);
    this.setState({ ganttReady: false });
    // window.removeEventListener('click', this.windowOnClick, false);
  }

  render() {
    return (
      <>
        <div
          id="gantt-container-blackhyve"
          style={{ width: '100%', height: '100%', overflow: 'hidden' }}
          ref={(input) => {
            this.ganttContainer = input;
          }}
        ></div>
        <ExternalRender ganttId={this.props.ganttId} ganttReady={this.state.ganttReady} />
      </>
    );
  }
}

const ExternalRender = ({ ganttId, ganttReady }) => {
  const gantt = ganttStore.getGantt(ganttId);
  const [componentList, setComponentList] = useState({});

  useEffect(() => {
    if (gantt && ganttReady) {
      gantt.config.external_render = {
        // checks the element is a React element
        isElement: (element) => {
          return React.isValidElement(element);
        },
        // renders the React element into the DOM
        renderElement: (element, container) => {
          if (element.key) {
            container.innerHTML = '';
            setComponentList((prevState) => ({
              ...prevState,
              [element.key]: { element, container },
            }));
          } else {
            console.warn('Element needs a key: ', element);
          }
        },
        removeElement: (key) => {
          setComponentList((prevState) => {
            const clone = { ...prevState };
            delete clone[key];
            return clone;
          });
        },
      };
      if (!gantt.$destroyed) {
        gantt?.render();
      }
    }
  }, [gantt, ganttReady]);
  return (
    <>
      {Object.entries(componentList).map(([key, { element, container }]) =>
        createPortal(element, container, key)
      )}
    </>
  );
};
