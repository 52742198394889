import { addDays } from 'date-fns';

export const defaultTemplates = {
  grid_date_format: function (date, column) {
    if (column === 'end_date') {
      return addDays(date, 1).toLocaleDateString();
    } else {
      return date.toLocaleDateString();
    }
  },
};
