import { Button, Popover as MuiPopover } from '@mui/material';
import React, { useState } from 'react';

export const Popover = ({ trigger, children, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(undefined);

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const triggerElement = trigger ? (
    React.cloneElement(trigger, { onClick: handleOpen })
  ) : (
    <Button onClick={handleClose}>Open</Button>
  );
  return (
    <>
      {triggerElement}
      <MuiPopover anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} {...props}>
        {typeof children === 'function' ? children({ handleClose }) : children}
      </MuiPopover>
    </>
  );
};
