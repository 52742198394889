import { formatGanttTaskToTask } from '@blackhyve/utilities/gantt';
import {
  useCreateDependenciesMutation,
  useDeleteDependenciesMutation,
  useUpdateDependenciesMutation,
} from 'features/dependencies/api/dependency.api';
import useGanttDataProcessor from 'features/gantt/hooks/useGanttDataProcessor';
import {
  useCreateTasksMutation,
  useDeleteTasksMutation,
  useUpdateTasksMutation,
} from 'features/tasks/store/task.api';

export const useTaskGanttDataProcessor = ({ formatter = formatGanttTaskToTask } = {}) => {
  const [
    updateTasks,
    {
      isLoading: isUpdatingTasks,
      isError: isUpdatingTasksError,
      isSuccess: isUpdatingTasksSuccess,
    },
  ] = useUpdateTasksMutation();
  const [
    deleteTasks,
    {
      isLoading: isDeletingTasks,
      isError: isDeletingTasksError,
      isSuccess: isDeletingTasksSuccess,
    },
  ] = useDeleteTasksMutation();
  const [
    createTasks,
    {
      isLoading: isCreatingTasks,
      isError: isCreatingTasksError,
      isSuccess: isCreatingTasksSuccess,
    },
  ] = useCreateTasksMutation();
  const [
    updateLinks,
    {
      isLoading: isUpdatingLinks,
      isError: isUpdatingLinksError,
      isSuccess: isUpdatingLinksSuccess,
    },
  ] = useUpdateDependenciesMutation();
  const [
    deleteLinks,
    {
      isLoading: isDeletingLinks,
      isError: isDeletingLinksError,
      isSuccess: isDeletingLinksSuccess,
    },
  ] = useDeleteDependenciesMutation();
  const [
    createLinks,
    {
      isLoading: isCreatingLinks,
      isError: isCreatingLinksError,
      isSuccess: isCreatingLinksSuccess,
    },
  ] = useCreateDependenciesMutation();

  const isSaving =
    isUpdatingTasks ||
    isDeletingTasks ||
    isUpdatingLinks ||
    isDeletingLinks ||
    isCreatingTasks ||
    isCreatingLinks;

  const isError =
    isUpdatingTasksError ||
    isDeletingTasksError ||
    isUpdatingLinksError ||
    isDeletingLinksError ||
    isCreatingTasksError ||
    isCreatingLinksError;

  const isSuccess =
    isUpdatingTasksSuccess ||
    isDeletingTasksSuccess ||
    isUpdatingLinksSuccess ||
    isDeletingLinksSuccess ||
    isCreatingTasksSuccess ||
    isCreatingLinksSuccess;

  const { initDataProcessor, processChanges, hasPendingUpdates } = useGanttDataProcessor({
    createTasks,
    createLinks,
    updateTasks,
    updateLinks,
    deleteTasks,
    deleteLinks,
    isSaving,
    isSuccess,
    isError,
    formatter,
  });

  return { initDataProcessor, isSaving, isError, isSuccess, processChanges, hasPendingUpdates };
};
