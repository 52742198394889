import { ArrowBack, ArrowForward, Check, Close, MenuOpen } from '@mui/icons-material';
import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Fab,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ContentLayout } from 'components/layouts';
import { addWeeks, isAfter, isBefore, startOfWeek } from 'date-fns';
import { PublishTargetsDrawer } from 'features/targets/components/PublishTargetsDrawer';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLookaheadGantt } from '../hooks/useLookaheadGantt';
import ModalProvider from 'components/common/ModalProvider';
import { LookaheadModals } from '../components/LookaheadModals';
import { LookaheadTaskDetailsDrawer } from '../components/LookaheadTaskDetailsDrawer';
import { Popover } from 'components/Popover';

const startOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

const generateGradient = (colors) =>
  colors
    ? `linear-gradient(to right, ${colors
        .map((color, index, array) => {
          const start = (index / array.length) * 100;
          const end = ((index + 1) / array.length) * 100;
          return `${color} ${start}% ${end}%`;
        })
        .join(', ')})`
    : undefined;

const colorByOptions = [
  { label: 'Default', getColor: (task) => task.color },
  {
    label: 'Tag',
    getColor: (task) =>
      task.tags
        ? generateGradient(task.tags.map((tag) => tag.color).filter((color) => color))
        : undefined,
  },
  {
    label: 'Company',
    getColor: (task) =>
      task.companies
        ? generateGradient(task.companies.map((company) => company.color).filter((color) => color))
        : undefined,
  },
];

export const Lookahead = () => {
  const { taskId, projectId } = useParams();
  const [isTargetsOpen, setIsTargetOpen] = useState(false);
  const [targetsDate, setTargetsDate] = useState(startOfThisWeek);
  const [ganttDate, setGanttDate] = useState(addWeeks(startOfThisWeek, -1));
  const [colorBy, setColorBy] = useState(colorByOptions[0]);

  const {
    ref: ganttRef,
    isLoading: isLoadingGantt,
    gantt,
    portals,
    Gantt,
  } = useLookaheadGantt({
    taskId,
    projectId,
    startDate: ganttDate,
    targetsDate: isTargetsOpen ? targetsDate : undefined,
    colorBy: colorBy.getColor,
  });

  const showToday = () => {
    if (isBefore(startOfThisWeek, ganttDate) || isAfter(startOfThisWeek, addWeeks(ganttDate, 8))) {
      setGanttDate(startOfThisWeek);
    }
    gantt.showDate(new Date());
  };

  return (
    <ModalProvider>
      {isLoadingGantt ? (
        <CircularProgress />
      ) : (
        <ContentLayout
          header={
            <Stack alignItems={'center'} borderBottom={'1px solid #c4c4c4'} direction={'row'}>
              <Stack alignItems={'center'} direction={'row'} flex={1} justifyContent={'flex-start'}>
                <Popover trigger={<Button size={'small'}>Color By: {colorBy.label}</Button>}>
                  <List disablePadding>
                    {colorByOptions.map((colorByOption) => (
                      <ListItemButton
                        key={colorByOption.label}
                        onClick={() => setColorBy(colorByOption)}
                      >
                        <ListItemIcon>{colorByOption === colorBy && <Check />}</ListItemIcon>
                        <ListItemText primary={colorByOption.label} />
                      </ListItemButton>
                    ))}
                  </List>
                </Popover>
              </Stack>
              <Stack alignItems={'center'} direction={'row'} flex={1} justifyContent={'center'}>
                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                  <Button color={'secondary'} size={'small'} onClick={showToday}>
                    Today
                  </Button>
                  <IconButton onClick={() => setGanttDate((prevDate) => addWeeks(prevDate, -1))}>
                    <ArrowBack />
                  </IconButton>
                  <Typography>{ganttDate.toLocaleDateString()}</Typography>
                  <IconButton onClick={() => setGanttDate((prevDate) => addWeeks(prevDate, 1))}>
                    <ArrowForward />
                  </IconButton>
                </Stack>
              </Stack>
              <Stack alignItems={'center'} direction={'row'} flex={1} justifyContent={'flex-end'}>
                <Tooltip title={`${isTargetsOpen ? 'Close' : 'Open'} Targets`}>
                  <Button
                    color={'primary'}
                    size={'small'}
                    startIcon={<MenuOpen />}
                    onClick={() => setIsTargetOpen((prev) => !prev)}
                  >
                    Targets
                  </Button>
                </Tooltip>
              </Stack>
            </Stack>
          }
        >
          <Stack
            direction={'row'}
            height={'100%'}
            overflow={'hidden'}
            position={'relative'}
            width={'100%'}
          >
            <Stack
              flexGrow={1}
              minWidth={0} // This ensures the box can shrink below its content size
            >
              <Box
                flex={1}
                overflow={'hidden'}
                ref={ganttRef}
                sx={{
                  '.gantt_task_content': { justifyContent: 'flex-start' },
                  '.target-range': {
                    pointerEvents: 'none',
                    backgroundColor: (theme) => alpha(theme.palette.secondary.light, 0.3),
                  },
                  '.today': { backgroundColor: (theme) => theme.palette.secondary.main },
                  '.hidden': { backgroundColor: 'transparent' },
                  '.eight-weeks-outs': {
                    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.1),
                  },
                  '.seven-weeks-outs': {
                    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.2),
                  },
                  '.six-weeks-outs': {
                    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.3),
                  },
                  '.five-weeks-outs': {
                    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.4),
                  },
                  '.four-weeks-outs': {
                    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.5),
                  },
                  '.three-weeks-outs': {
                    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.6),
                  },
                  '.two-weeks-outs': {
                    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.7),
                  },
                  '.one-weeks-outs': {
                    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.8),
                  },
                  '.zero-weeks-outs': {
                    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.9),
                  },
                  '.gantt_cell': {
                    fontSize: 16,
                  },
                }}
              >
                {portals}
              </Box>
              <LookaheadModals />
              <LookaheadTaskDetailsDrawer gantt={gantt} />
            </Stack>

            <Box position="relative" sx={{ flexShrink: 0 }}>
              <PublishTargetsDrawer
                date={targetsDate}
                handleClose={() => setIsTargetOpen(false)}
                isOpen={isTargetsOpen}
                projectId={projectId}
                setDate={setTargetsDate}
                taskId={taskId}
              >
                <Fab
                  color={isTargetsOpen ? 'default' : 'primary'}
                  size="small"
                  variant="contained"
                  sx={{
                    position: 'absolute',
                    left: isTargetsOpen ? '-20px' : '-40px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                  onClick={() => setIsTargetOpen((prev) => !prev)}
                >
                  {isTargetsOpen ? <Close /> : <MenuOpen />}
                </Fab>
              </PublishTargetsDrawer>
            </Box>
          </Stack>
        </ContentLayout>
      )}
    </ModalProvider>
  );
};

export default Lookahead;
