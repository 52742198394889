import { statusColor } from 'features/tasks';

const cascadingTagIcon = `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiChip-icon MuiChip-iconSmall MuiChip-iconColorDefault css-i4bv87-MuiSvgIcon-root" style="font-size: .8em" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="KeyboardDoubleArrowDownOutlinedIcon"><path d="M18 6.41 16.59 5 12 9.58 7.41 5 6 6.41l6 6z"></path><path d="m18 13-1.41-1.41L12 16.17l-4.59-4.58L6 13l6 6z"></path></svg>`;
const smartTagIcon = `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiChip-icon MuiChip-iconSmall MuiChip-iconColorDefault css-i4bv87-MuiSvgIcon-root" style="font-size: .8em" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="TipsAndUpdatesOutlinedIcon"><path d="M7 20h4c0 1.1-.9 2-2 2s-2-.9-2-2m-2-1h8v-2H5zm11.5-9.5c0 3.82-2.66 5.86-3.77 6.5H5.27c-1.11-.64-3.77-2.68-3.77-6.5C1.5 5.36 4.86 2 9 2s7.5 3.36 7.5 7.5m-2 0C14.5 6.47 12.03 4 9 4S3.5 6.47 3.5 9.5c0 2.47 1.49 3.89 2.35 4.5h6.3c.86-.61 2.35-2.03 2.35-4.5m6.87-2.13L20 8l1.37.63L22 10l.63-1.37L24 8l-1.37-.63L22 6zM19 6l.94-2.06L22 3l-2.06-.94L19 0l-.94 2.06L16 3l2.06.94z"></path></svg>`;
const normalTagIcon = `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiChip-icon MuiChip-iconSmall MuiChip-iconColorDefault css-i4bv87-MuiSvgIcon-root" style="font-size: .8em" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LabelOutlinedIcon"><path d="M17.63 5.84C17.27 5.33 16.67 5 16 5L5 5.01C3.9 5.01 3 5.9 3 7v10c0 1.1.9 1.99 2 1.99L16 19c.67 0 1.27-.33 1.63-.84L22 12zM16 17H5V7h11l3.55 5z"></path></svg>`;
const generatedTagIcon = `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiChip-icon MuiChip-iconSmall MuiChip-iconColorDefault css-i4bv87-MuiSvgIcon-root" style="font-size: .8em" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="AutoAwesomeIcon"><path d="m19 9 1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25zm-7.5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12zM19 15l-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25z"></path></svg>`;

// Helper function to get text color based on background color
const getTextColor = (backgroundColor) => {
  // Simplified contrast check - you may want to implement a proper getContrastRatio function
  const isLightColor =
    backgroundColor.toLowerCase().includes('fff') ||
    backgroundColor.toLowerCase().includes('light') ||
    backgroundColor === '#ffffff';
  return isLightColor ? '#000' : '#fff';
};

const createTaskHtml = (task, gantt) => {
  if (task.type !== 'task') return task.text;

  const taskEndPos = gantt.posFromDate(task.end_date);

  const statusPill = `
    <div style="border-radius: 4px; background-color: ${statusColor[task.status]}; 
         color: ${getTextColor(statusColor[task.status])}; padding: .1em 8px; font-size: 0.8em; line-height: 100%;">
      ${task.status}
    </div>`;

  const progressPill = `
    <div style="border-radius: 4px; background-color: lightgrey; padding: .1em 8px; 
         display: flex; align-items: center; gap: 4px; font-size: 0.8em; line-height: 100%;">
      <svg viewBox="0 0 24 24" style="border:0.5px solid; font-size:inherit; width:1em; height:1em; fill: currentColor;">
        <path d="M7.5 11C9.43 11 11 9.43 11 7.5S9.43 4 7.5 4 4 5.57 4 7.5 5.57 11 7.5 11m0-5C8.33 6 9 6.67 9 7.5S8.33 9 7.5 9 6 8.33 6 7.5 6.67 6 7.5 6M4.0025 18.5832 18.59 3.9955l1.4142 1.4143L5.4167 19.9974zM16.5 13c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5m0 5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5"></path>
      </svg>
      <div>${task.progress * 100}%</div>
    </div>`;

  const companiesHtml = task.companies
    ? `
    <div style="display: flex; flex-direction: row; gap: 4px; font-size: 0.8em; line-height: 100%;">
      ${task.companies
        .map(
          (company) => `
        <div style="display: flex; flex-direction: row; gap: 4px; align-items: center; outline: 1px solid; outline-offset: -1px; border-radius: 16px; padding: .1em 4px;">
          <svg viewBox="0 0 16 16" style="width:1em;height:1em">
            <path stroke="currentColor" d="M15 9C15 6.49219 13.46 4.34625 11.2753 3.44937L10 6V2.5C10 2.36739 9.94732 2.24021 9.85355 2.14645C9.75979 2.05268 9.63261 2 9.5 2H6.5C6.36739 2 6.24021 2.05268 6.14645 2.14645C6.05268 2.24021 6 2.36739 6 2.5V6L4.72469 3.44937C2.54 4.34625 1 6.49219 1 9V11H15V9ZM15.5 12H0.5C0.367392 12 0.240215 12.0527 0.146447 12.1464C0.0526784 12.2402 0 12.3674 0 12.5L0 13.5C0 13.6326 0.0526784 13.7598 0.146447 13.8536C0.240215 13.9473 0.367392 14 0.5 14H15.5C15.6326 14 15.7598 13.9473 15.8536 13.8536C15.9473 13.7598 16 13.6326 16 13.5V12.5C16 12.3674 15.9473 12.2402 15.8536 12.1464C15.7598 12.0527 15.6326 12 15.5 12Z" fill="currentColor"></path>
          </svg>
          <span>${company.name}</span>
        </div>
      `
        )
        .join('')}
    </div>`
    : '';

  const datesHtml = `<div style="font-size: .8em">${task.forecasted_start_date.toLocaleDateString()} - ${task.forecasted_end_date.toLocaleDateString()}</div>`;

  const tagsHtml =
    task.tags &&
    `
    <div style="display: flex; flex-direction: row; gap: 4px; align-items: center; font-size: 0.8em; line-height: 100%;">
      ${task.tags
        .map(
          (tag) => `
        <div style="display: flex; flex-direction: row; gap: 4px; align-items: center; border-radius: 16px; background-color: ${tag.color}; padding: .1em 4px; ${!tag.color ? 'outline: 1px solid; outline-offset: -1px;' : ''}">
          ${tag.type === 'smart' ? smartTagIcon : tag.type === 'cascading' ? cascadingTagIcon : tag.type === 'generated' ? generatedTagIcon : normalTagIcon}
          ${tag.name}
        </div>
      `
        )
        .join('')}
    </div>`;

  return `
    <div style="display: flex; flex-direction: column; height: 100%; gap: 2px; font-size: ${task.bar_height - 2}px;">
      <div style="display: flex; flex-direction: row; align-items: center; gap: 4px; font-size: .5em; line-height: 100%">
        ${statusPill}
        ${progressPill}
        <div style="font-weight: 600; font-size: 0.875em;">${task.text}</div>
      </div>
      <div style="display: flex; flex-direction: row; gap: 4px; font-size: .5em; line-height: 100%">
        ${datesHtml}
        ${tagsHtml}
        ${companiesHtml}
      </div>
    </div>`;
};

function categorizeHiddenLinksByDirection(task, gantt) {
  const leftLinks = [];
  const rightLinks = [];

  function processLinks(linkIds, isSource) {
    if (!linkIds) return;

    linkIds.forEach((linkId) => {
      const link = gantt.getLink(linkId);
      const taskIdToCheck = isSource ? link.target : link.source;
      const linkType = isSource ? link.type[0] : link.type[1];

      if (!gantt.isTaskVisible(taskIdToCheck)) {
        if (linkType === 'S') {
          leftLinks.push(link);
        } else {
          rightLinks.push(link);
        }
      }
    });
  }

  // Process both incoming and outgoing links
  processLinks(task.$target, false);
  processLinks(task.$source, true);

  return {
    leftLinks,
    rightLinks,
  };
}

export const templates = (gantt) => ({
  task_text: (startDate, endDate, task) => {
    // console.log(startDate, endDate, task);
    // const div = document.createElement('div');
    // const root = createRoot(div);
    // flushSync(() => {
    //   root.render(<LookaheadBar task={task} />);
    // });
    // const html = div.innerHTML;
    // root.unmount();
    return '';
  },

  leftside_text: function (start, end, task) {
    const { leftLinks } = categorizeHiddenLinksByDirection(task, gantt);
    return leftLinks.length > 0 ? `+${leftLinks.length} links` : '';
  },

  rightside_text: function (start, end, task) {
    const { rightLinks } = categorizeHiddenLinksByDirection(task, gantt);
    // return rightLinks.length > 0 ? `+${rightLinks.length} links` : '';
    return task.type === 'parent' ? task.text : createTaskHtml(task, gantt);
  },

  task_class: function (start, end, task) {
    let classes = [];
    if (task?.status === 'complete') classes.push('complete_task');
    if (task?.status === 'active' && task.type !== 'parent_task') classes.push('active_task');
    if (task.type === 'parent_task') classes.push('gantt_project gantt_bar_project');
    if (task?.dates_locked_by) classes.push('scheduled_dates_locked');
    if (gantt?.config?.readonly || task?.readonly) classes.push('readonly');

    return classes.join(' ');
  },

  grid_row_class: function (start, end, task) {
    let classes = [];
    if (gantt?.config?.readonly || task?.readonly) classes.push('readonly');
    if (task.status === 'complete') classes.push('completed_task');
    if (task.$virtual) classes.push('group_row');
    if (task.type === 'parent_task') classes.push('gantt_project_grid_row');
    if (task.empty_row) return 'empty_row';
    return classes.join(' ');
  },

  timeline_cell_class: function (task, date) {
    if (!gantt.isWorkTime({ date, task })) return 'weekend';
    return '';
  },
});
