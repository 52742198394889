import { useCallback, useContext, createContext, useState } from 'react';

export const ModalContext = createContext(true);

const ModalProvider = ({ children }) => {
  const [openModalId, setOpenModalId] = useState(undefined);
  const [openModalMeta, setOpenModalMeta] = useState(undefined);

  const openModal = useCallback((modalId, modalMeta) => {
    setOpenModalId(modalId);
    setOpenModalMeta(modalMeta);
  }, []);

  const closeModal = useCallback(() => {
    setOpenModalId(undefined);
    setOpenModalMeta(undefined);
  }, []);

  return (
    <ModalContext.Provider value={{ openModalId, openModalMeta, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);

export default ModalProvider;
