import { differenceInDays } from 'date-fns';

const barLeftOffset = 3;
const barWidthOffset = 2 * barLeftOffset;

const forecastedDatesLayer = {
  id: 'forecastedDatesLayer',
  topmost: true,
  renderer: {
    render: function (task, view, config, pos) {
      const gantt = view.$gantt;
      const startDate = task.actual_start_date || task.forecasted_start_date;
      const endDate = task.actual_end_date || task.forecasted_end_date;
      // get pos of forecasted or dates
      const sizes = gantt.getTaskPosition(task, startDate, endDate);
      var el = document.createElement('div');
      const isNegativePace = task.end_date < endDate;
      el.className = 'targetEndDate ' + (isNegativePace ? 'orange' : 'green');
      el.style.left = sizes.left + barLeftOffset + 'px';
      el.style.width = sizes.width - barWidthOffset + 'px';
      el.style.top =
        sizes.top +
        (task.row_height || config.row_height) / 2 +
        (task.bar_height || config.bar_height) / 2 +
        'px';
      el.style.height = 5 + 'px';
      el.style.zIndex = 1;
      el.style.borderRadius = 2;
      el.dataset.id = task.id;
      return el;
    },
    getRectangle: function (task, view, resourceConfig, gantt) {
      const startDate = task.actual_start_date || task.forecasted_start_date;
      const endDate = task.actual_end_date || task.forecasted_end_date;
      return gantt.getTaskPosition(task, startDate, endDate);
    },
  },
  filter: function (task) {
    return task.type === 'parent_task' || task.status !== 'todo';
  },
};

const scheduledDatesLayer = {
  id: 'scheduledDatesLayer',
  topmost: true,
  renderer: {
    render: function (task, view, config, pos) {
      const gantt = view.$gantt;
      const startDate = task.scheduled_start_date || task.start_date;
      const endDate = task.scheduled_end_date;
      const isParent = task.type === 'parent_task';
      // get pos of forecasted or dates
      const sizes = gantt.getTaskPosition(task, startDate, endDate);
      const el = document.createElement('div');

      // same class name as regular tasks have
      el.className =
        task.type === 'parent_task'
          ? 'gantt_task_line gantt_project gantt_bar_project'
          : 'gantt_task_line';

      // same HTML elements as regular tasks have
      el.innerHTML =
        '<div class="gantt_task_progress_wrapper"><div class="gantt_task_progress" style="width: ' +
        task.progress * 100 +
        '%"></div></div>' +
        `<div class="gantt_task_content" style='${isParent && 'height:' + task.bar_height * 0.7 + 'px'}'>` +
        gantt.templates.task_text(startDate, endDate, task) +
        '</div>' +
        '<div class="gantt_task_drag task_left task_start_date" data-bind-property="start_date"></div>' +
        '<div class="gantt_task_drag task_right task_end_date" data-bind-property="end_date"></div>';
      el.setAttribute('task_id', task.id);
      el.setAttribute('data-task-id', task.id);

      el.style.left = sizes.left + barLeftOffset + 'px';
      el.style.width = sizes.width - barWidthOffset + 'px';
      el.style.top =
        sizes.top +
        (task.row_height || config.row_height) / 2 -
        (task.bar_height || config.bar_height) / 2 +
        'px';
      el.style.height = sizes.height + 'px';

      el.style.backgroundImage = task.$color;
      return el;
    },
    getRectangle: function (task, view, resourceConfig, gantt) {
      const startDate = task.scheduled_start_date || task.start_date;
      const endDate = task.scheduled_end_date;
      return gantt.getTaskPosition(task, startDate, endDate);
    },
  },
  events: [
    {
      event: 'onTaskDrag',
      callback: function (id, mode, task, original, event) {
        task.scheduled_end_date = task.end_date;

        return true;
      },
    },
    {
      event: 'onBeforeTaskChanged',
      callback: function (taskId, mode, original) {
        const gantt = this;
        const task = gantt.getTask(taskId);

        if (mode === gantt.config.drag_mode.resize) {
          // Correct scheduled end date
          task.scheduled_end_date = gantt.getClosestWorkTime({
            date: task.end_date,
            dir: 'past',
            task: task,
          });
        } else if (mode === gantt.config.drag_mode.move) {
          // Preserve task duration while moving
          task.scheduled_end_date = gantt.calculateEndDate({
            start_date: task.start_date,
            duration: gantt.calculateDuration({
              start_date: original.start_date,
              end_date: original.scheduled_end_date,
              task: task,
            }),
            task: task,
          });
        }

        // If todo and not a parent task keep forecasted dates in sync
        if (task.type !== 'parent_task' && task.status === 'todo') {
          task.forecasted_start_date = task.start_date;
          task.forecasted_end_date = task.scheduled_end_date;
        }

        //Update duration fields
        task.work_days = this.calculateDuration({
          start_date: task.start_date,
          end_date: task.scheduled_end_date,
          task: task,
        });
        task.cal_days = differenceInDays(task.scheduled_end_date, task.start_date);

        // Reset end date
        task.end_date =
          task.autoschedule_date === 'schedule'
            ? task.scheduled_end_date
            : task.forecasted_end_date;

        return true;
      },
    },
    {
      event: 'onBeforeTaskDrag',
      callback: function (id, mode, e) {
        let task = this.getTask(id);
        if (task.status === 'complete' || task?.dates_locked_by) {
          return false;
        }
        return true;
      },
    },
  ],
};

export const layers = [forecastedDatesLayer, scheduledDatesLayer];
