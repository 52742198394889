import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Share from '@mui/icons-material/Share';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

export const DependencyTab = ({ taskId }) => {
  return (
    <Grid container gap={1}>
      <Grid container item alignItems={'center'} xs={12}>
        <IconButton disableRipple size="large">
          <Share fontSize="inherit" />
        </IconButton>
        <Typography sx={{ fontWeight: '600' }} variant="h6">
          Dependencies
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Alert severity="info">Coming Soon</Alert>
      </Grid>
    </Grid>
  );
};

export default DependencyTab;
