import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import AssignTagDialog from 'features/tags/components/AssignTagDialog';
import TagList from 'features/tags/components/TagList';
import { useDebounce } from 'hooks/useDebounce';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';

/**
 * Tags Tab
 * @param {[Tag]} tags
 * @param {Number} taskId
 * @param {Number} projectId
 * @returns
 */
export const TagsTab = ({ tags, handleTaskUpdate }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedTags, setSelectedTags] = useState([]);
  const [openAssignTagDialog, setOpenAssignTagDialog] = useState(false);
  const [isUpdatingTask, setIsUpdatingTask] = useState(false);
  const pendingChangesCount = useRef(0);

  useEffect(() => {
    // Don't refresh selected tasks if pending updates
    if (pendingChangesCount.current === 0) {
      tags.length ? setSelectedTags(tags) : setSelectedTags([]);
    }
  }, [tags]);

  const handleOnDeleteTag = async (tagId) => {
    const remainingTags = selectedTags.filter(({ id }) => id !== tagId);
    setSelectedTags(remainingTags);

    try {
      setIsUpdatingTask(true);
      pendingChangesCount.current += 1;
      // Handle both promise and non-promise return values
      const result = handleTaskUpdate({
        tags: remainingTags,
      });
      // If result is a promise, await it
      if (result && typeof result.then === 'function') {
        await result;
      }

      setOpenAssignTagDialog(false);
      setIsUpdatingTask(false);
      pendingChangesCount.current -= 1;
    } catch (error) {
      setIsUpdatingTask(false);
      pendingChangesCount.current -= 1;
      enqueueSnackbar('Error updating tags', { variant: 'error' });
      throw error;
    }
    return remainingTags;
  };

  const handleUpdateTags = async (tags) => {
    try {
      setIsUpdatingTask(true);
      pendingChangesCount.current += 1;
      // Handle both promise and non-promise return values
      const result = handleTaskUpdate({
        tags: tags,
      });
      // If result is a promise, await it
      if (result && typeof result.then === 'function') {
        await result;
      }

      setOpenAssignTagDialog(false);
      setIsUpdatingTask(false);
      pendingChangesCount.current -= 1;
    } catch (error) {
      setIsUpdatingTask(false);
      pendingChangesCount.current -= 1;
      enqueueSnackbar('Error updating tags', { variant: 'error' });
      throw error;
    }
  };

  const filteredTags = useMemo(() => {
    return selectedTags?.filter((tag) => tag.type !== 'generated') || [];
  }, [selectedTags]);

  return (
    <>
      <TagList tags={selectedTags} onDelete={handleOnDeleteTag} />
      <Button
        size="small"
        startIcon={<Add />}
        variant="text"
        onClick={() => setOpenAssignTagDialog(true)}
      >
        Assign Tag
      </Button>
      {openAssignTagDialog && (
        <AssignTagDialog
          existingTags={filteredTags}
          handleClose={() => setOpenAssignTagDialog(false)}
          handleUpdate={handleUpdateTags}
          isUpdating={isUpdatingTask}
          open={openAssignTagDialog}
        />
      )}
    </>
  );
};

export default TagsTab;
