import {
  Avatar,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListItemButton,
  TextField,
} from '@mui/material';
import Box from '@mui/system/Box';
import { Clear } from '@mui/icons-material';
import { Autocomplete } from '@mui/material';
import { Resource, ResourceType } from '../api/resources.models';
import { useMemo } from 'react';
import { concatenateParentAndChildNames } from '../helpers/resourceHelper';

export const SelectResourcesAutocompleteList = ({
  selected,
  setSelected,
  disabled,
  resources,
  isLoading,
}: {
  selected: Resource[];
  setSelected: any;
  disabled: boolean;
  resources: Resource[];
  isLoading: boolean;
}) => {
  const parentResourceMap = useMemo(() => {
    return resources.reduce((map, resource) => {
      if (resource.type === ResourceType.Group) {
        map[resource.id] = resource.name;
      }
      return map;
    }, {});
  }, [resources]);
  return (
    <Box display={'flex'} flexDirection={'column'} height={'100%'}>
      <Autocomplete
        disableClearable
        disableCloseOnSelect
        fullWidth
        multiple
        autoFocus={false}
        disabled={disabled}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        limitTags={0}
        loading={isLoading}
        options={resources}
        renderTags={() => null}
        value={selected}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder="Select Resources"
            size="small"
            variant="outlined"
          />
        )}
        renderOption={(props, option, { selected }) => {
          const concatenatedResourceName = concatenateParentAndChildNames({
            resource: option,
            parentResourceMap,
          });
          const { key, ...otherProps } = props;
          return (
            <ListItemButton key={key} {...(otherProps as any)}>
              <ListItemIcon>
                <Checkbox checked={selected} />
              </ListItemIcon>
              <ListItemText>{concatenatedResourceName}</ListItemText>
            </ListItemButton>
          );
        }}
        onChange={(event: any, newValue) => {
          if (event.key !== 'Backspace') {
            setSelected(newValue);
          }
        }}
      />
      <Box dense component={List} maxHeight={'25vh'} sx={{ overflowY: 'auto', padding: '5px' }}>
        {selected?.map((resource) => {
          const concatenatedResourceName = concatenateParentAndChildNames({
            resource,
            parentResourceMap,
          });
          return (
            <ListItem disableGutters key={resource.id}>
              <ListItemAvatar>
                <Avatar />
              </ListItemAvatar>
              <ListItemText primary={concatenatedResourceName} />

              {!disabled && (
                <ListItemSecondaryAction>
                  <IconButton
                    size="large"
                    onClick={() => {
                      setSelected(
                        selected.filter((selectedResource) => selectedResource.id !== resource.id)
                      );
                    }}
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
      </Box>
    </Box>
  );
};
