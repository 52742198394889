import { useState } from 'react';
import times from 'lodash/times';
import List from '@mui/material/List';
import Grid from "@mui/material/Grid";
import Alert from '@mui/material/Alert';
import { Virtuoso } from 'react-virtuoso';
import { useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import { selectCurrentUser } from 'features/auth';
import LoadingButton from '@mui/lab/LoadingButton';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import CommentCard, { CommentCardSkeleton } from './CommentCard';
import RichTextEditor from 'components/common/v3/RichTextEditor';
import AvatarComponent from 'components/common/v3/AvatarComponent';
import { useCreateCommentMutation, useGetCommentsQuery } from 'features/comments/store/comment.api';


const toolbarOptions = [
  ['bold', 'underline', 'strike', 'blockquote', 'image', 'link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [],
];


/**
 * Comment Tab
 * @returns 
 */
const CommentContainer = ({ entityId, entityType, entityTag }) => {

  const [comment, setComment] = useState('');
  const currentUser = useSelector((state) => selectCurrentUser(state));
  const [createComment, { isLoading: isCommentGettingLoaded }] = useCreateCommentMutation();

  const { data: listOfComments = [], isLoading: isLoadingComments, isFetching: isFetchingComments, } =
    useGetCommentsQuery({ entityId, entityType, order_by: 'created_at,asc;' });


  const handleOnChangeComment = (newValue) => setComment(newValue);
  const handleCreateComment = () => {
    const newCommentObj = {
      entityType,
      entityId,
      entityTag,
      comment
    }
    createComment(newCommentObj)
      .then(() => {
        setComment('');
      })
  }

  const containerHeight = listOfComments?.length * 100;

  return <Grid container gap={1} pt={0}>
    {isFetchingComments && !isLoadingComments && <LinearProgress sx={{ width: '100%' }} />}
    <Grid container item sx={{ height: containerHeight ? `${containerHeight}px` : 'auto', maxHeight: 'calc(100vh - 305px)', overflowY: 'auto' }} xs={12}>
      <List sx={{ width: '100%' }}>
        {isLoadingComments && times(5, (row) => <CommentCardSkeleton key={row} />)}
        {listOfComments?.length ? <Virtuoso
          data={listOfComments}
          style={{ height: '100%', width: '100%', opacity: isFetchingComments ? 0.3 : 1 }}
          itemContent={(index) => {
            const comment = listOfComments[index];
            return <CommentCard comment={comment} key={comment?.id} />
          }}
        /> :
          <ListItem>
            <ListItemText sx={{ textAlign: 'center' }}>
              <Alert severity='info'>No Comments Added</Alert>
            </ListItemText>
          </ListItem>}
      </List>
    </Grid>
    <Grid container item px={2} spacing={2} xs={12}>
      <Grid item xs='auto'>
        <AvatarComponent
          name={`${currentUser?.first_name} ${currentUser.last_name}`}
          src={currentUser?.profile_image?.thumb_url}
        />
      </Grid>
      <Grid item xs>
        <RichTextEditor
          className="add-comment"
          handleOnChange={handleOnChangeComment}
          readOnly={false}
          text={comment}
          toolbarOptions={toolbarOptions}
        />
        <LoadingButton loading={isCommentGettingLoaded} sx={{ mt: 1, ml: 'auto' }} variant="contained" onClick={handleCreateComment}>
          Add Comment
        </LoadingButton>
      </Grid>
    </Grid>
  </Grid >

}


export default CommentContainer;