import { useEffect } from 'react';

export const useGanttServerLists = (gantt, serverLists, options = {}) => {
  const { skip } = options;
  useEffect(() => {
    if (gantt && serverLists && !skip) {
      Object.entries(serverLists).forEach(([key, list]) => {
        gantt.serverList(key, list);
      });
    }
  }, [gantt, serverLists, skip]);
};
