import { AppTheme } from '@blackhyve/common';
import { ThemeProvider } from '@mui/material';

import { SnackbarProvider } from 'notistack';
import { RouterProvider } from 'react-router-dom';
import { appRouter } from 'routes';
import './App.css';
import './CustomApp.css';
import SnackBarContainer from './containers/SnackBarContainer';
import { UpdateChecker } from './features/updateChecker/components/UpdateChecker';
import './styles/app.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { PostHogIdentify } from 'features/posthog/components/PostHogIdentify';

window.pendo.initialize({
  visitor: {
    id: 'VISITOR-UNIQUE-ID',
  },
});

const App = () => {
  return (
    <ThemeProvider theme={AppTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} maxSnack={3}>
          <RouterProvider router={appRouter} />
          <UpdateChecker />
          <PostHogIdentify />
          <SnackBarContainer />
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
