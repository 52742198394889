import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DirectionsWalk from '@mui/icons-material/DirectionsWalk';

export const JobwalkTab = ({ taskId }) => {
  return (
    <Grid container gap={1}>
      <Grid container item alignItems={'center'} xs={12}>
        <IconButton disableRipple size="large">
          <DirectionsWalk fontSize="inherit" />
        </IconButton>
        <Typography sx={{ fontWeight: '600' }} variant="h6">
          Jobwalk
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Alert severity="info">Coming Soon</Alert>
      </Grid>
    </Grid>
  );
};
