import { memo, useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import ProjectGroupByMenu from 'features/projects/components/ProjectGroupByMenu';
import { FilterDropdown } from 'features/projects/components/ProjectTableFilterMenu';
import { Label } from 'features/labels/components/Label';
import { isEmpty } from 'lodash';


const ProjectGanttHeader = ({ ganttId }) => {
  const gantt = ganttStore.getGantt(ganttId);
  const [filters, setFilters] = useState({
    project_dimension_1: [],
    project_dimension_2: [],
    project_dimension_3: [],
  });

  const generateServerList = (value) => {
    let array = [];
    gantt.eachTask((task) => {
      if (task.$virtual) {
        return;
      }
      array.push(task[value]);
    });
    array = [...new Set(array)];
    const groupCollection = array.map((item) => {
      return item ? { key: item, label: item } : { key: null, label: 'None' };
    });
    gantt.serverList(`${value}`, groupCollection);
  };

  const handleGroupBy = (value) => {
    if (!value) {
      gantt.groupBy(false);
    }
    generateServerList(value);
    gantt.groupBy({
      groups: gantt.serverList(value),
      relation_property: value,
      group_id: 'key',
      group_text: 'label',
    });
    gantt.sort('start_date');
  };

  const handleBeforeTaskDisplay = useCallback(
    (id, task) => {
      if (isEmpty(filters)) return true;
  
      return Object.entries(filters).every(([key, filterValues]) => 
        filterValues.length === 0 || filterValues.includes(task[key])
      );
    },
    [filters]
  );

  useEffect(() => {
    if (!gantt) return;
  
    const onBeforeDisplayEvent = gantt.attachEvent('onBeforeTaskDisplay', handleBeforeTaskDisplay);
    
    return () => {
      gantt.detachEvent(onBeforeDisplayEvent);
    };
  }, [gantt, handleBeforeTaskDisplay]);

  useEffect(()=>{
    gantt.render();
  }, [filters, gantt])

  return (
    <Grid container sx={{ py: 0.5 }} wrap={'nowrap'}>
      <Grid container item alignItems="center"  xs={'auto'}>
        <ProjectGroupByMenu handleUpdate={handleGroupBy} />
      </Grid>
      <Grid container item alignItems="center" gap={1} justifyContent={'flex-end'}>
        <FilterDropdown
          label={<Label labelKey={'project_dimension_1'} />}
          name={'project_dimension_1'}
          selectedValue={filters.project_dimension_1}
          setFilters={setFilters}
        />
        <FilterDropdown
          label={<Label labelKey={'project_dimension_2'} />}
          name={'project_dimension_2'}
          selectedValue={filters.project_dimension_2}
          setFilters={setFilters}
        />
        <FilterDropdown
          label={<Label labelKey={'project_dimension_3'} />}
          name={'project_dimension_3'}
          selectedValue={filters.project_dimension_3}
          setFilters={setFilters}
        />
      </Grid>

    </Grid>
  );
};

export default memo(ProjectGanttHeader);
