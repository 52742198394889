
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import TabList from '@mui/lab/TabList';
import { useSnackbar } from 'notistack';
import Paper from '@mui/material/Paper';
import TabPanel from '@mui/lab/TabPanel';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import Edit from '@mui/icons-material/Edit';
import TabContext from '@mui/lab/TabContext';
import Container from '@mui/material/Container';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import CommentIcon from '@mui/icons-material/Comment';
import ProcurementTaskList from './ProcurementTaskList';
import { parseDate } from '@blackhyve/utilities/dates';
import LinearProgress from '@mui/material/LinearProgress';
import SvgTaskIcon from '@blackhyve/common/src/icons/Task';
import ProcurementFormDialog from './ProcurementFormDialog';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetProcurementQuery } from '../store/procurement.api';
import CommentContainer from 'features/comments/components/CommentContainer';
import DisplayWithEditAccess from 'components/common/v3/DisplayWithEditAccess';
import { CircularProgress, Divider, useMediaQuery, useTheme } from '@mui/material';


export const PROCUREMENT_STATUS_COLORS = {
  'Submittals': 'error',
  'Approved': 'primary',
  'Ordered': 'warning',
  'Shipped': 'info',
  'Delivered': 'secondary',
}


/**
 * Procurement Details
 * @returns 
 */
const ProcurementDetail = () => {

  const theme = useTheme();
  const id = Number(useParams().procurementId) || undefined;
  const projectId = Number(useParams().projectId) || undefined;

  const { enqueueSnackbar } = useSnackbar();

  const [showTab, setShowTab] = useState('1');
  const [isEditing, setIsEditing] = useState(false);

  const navigate = useNavigate();
  const { data: procurement, isLoading, isFetching, isError, error } = useGetProcurementQuery(id)

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleChangeTab = (event, newValue) => setShowTab(newValue);

  const handleCloseEditing = () => setIsEditing(false);


  useEffect(() => {
    if (isError) {
      if (error.status === 404) {
        enqueueSnackbar('Procurement not found', { variant: 'error' });
      } else {
        enqueueSnackbar('An error occurred while loading', { variant: 'error' });
      }
      navigate('/', { replace: true });
    }
  }, [isError, error, enqueueSnackbar, navigate]);


  return <Container maxWidth="md" sx={{ mt: 4 }}>
    {isLoading ? <CircularProgress className='loading' /> :
      <>
        {isFetching && <LinearProgress sx={{ width: "100%" }} />}
        <Grid container component={Paper} elevation={3} p={2}>
          <Grid container item gap={1} sx={{ paddingTop: '0px !important' }} xs={12}>
            <Typography sx={{ fontWeight: 'bold' }} variant={isSmallScreen ? 'h5' : 'h4'}>
              {procurement?.name}
            </Typography>
            <Box alignItems={'center'} display="flex" gap={1}>
              <Chip color={PROCUREMENT_STATUS_COLORS[procurement?.status]} label={procurement?.status} variant="contained" />
            </Box>
            <Box alignItems={'center'} display={'flex'} ml="auto">
              <DisplayWithEditAccess>
                <Button
                  size="small"
                  startIcon={<Edit />}
                  variant="outlined"
                  onClick={() => setIsEditing(true)}
                >
                  Edit
                </Button>
              </DisplayWithEditAccess>
            </Box>
          </Grid>
          <Grid container mt={1} spacing={1}>
            <Grid container item xs={12}>
              <DetailRow label="Order No" value={procurement?.order_number} />
            </Grid>

            <DetailRow label="Category" value={procurement?.category} />
            <DetailRow label="Cost" value={procurement?.cost ? `$${procurement.cost}` : '-'} />

            <Grid container item xs={12}>
              <Grid item xs={2}>
                <FormLabel>Description</FormLabel>
              </Grid>
              <Grid item xs={10}>
                <Typography color="textSecondary">
                  {procurement?.description || '-'}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <DetailRow label="Order Date" value={procurement?.order_date ? parseDate(procurement.order_date).toLocaleDateString() : ''} />
            <DetailRow label="Lead Time" value={procurement?.lead_time_weeks ? `${procurement.lead_time_weeks} Weeks` : '-'} />
            <DetailRow label="Ship Date" value={procurement?.ship_date ? parseDate(procurement.ship_date).toLocaleDateString() : ''} />
            <DetailRow label="Transit Time" value={procurement?.transit_time_days ? `${procurement.transit_time_days} Days` : '-'} />

            <Grid container item xs={12}>
              <DetailRow label="Arrival Date" sm={6} value={procurement?.arrival_date ? parseDate(procurement.arrival_date).toLocaleDateString() : ''} />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <DetailRow label="Vendor" value={procurement?.vendor} />
            <DetailRow label="Vendor Contact" value={procurement?.vendor_contact} />
            <DetailRow label="Responsible" value={procurement?.responsible_users?.length ? procurement?.responsible_users?.map((user) => user.name).join(', ') : '-'} />
            <DetailRow label="Leader" value={procurement?.leader_users?.length ? procurement?.leader_users?.map((user) => user.name).join(', ') : '-'} />

          </Grid>
        </Grid>
        <Grid container component={Paper} elevation={3} p={2} sx={{ mt: 2 }} >
          <Box style={{ width: '100%' }}>
            <TabContext value={showTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChangeTab}>
                  <Tab icon={<SvgTaskIcon />} iconPosition="start" label="Tasks" sx={{ paddingLeft: 0, minHeight: '50px', }} value="1" />
                  <Tab icon={<CommentIcon />} iconPosition="start" label="Comments" sx={{ paddingLeft: 0, minHeight: '50px', }} value="2" />
                </TabList>
              </Box>
              <TabPanel sx={{ paddingX: 1, paddingY: 1 }} value="1">
                <ProcurementTaskList procurementId={procurement?.id} />
              </TabPanel>
              <TabPanel sx={{ paddingX: 0, paddingY: 1 }} value="2">
                <CommentContainer entityId={procurement?.id} entityTag="Procurement" entityType="procurements" />
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </>
    }
    {isEditing &&
      <ProcurementFormDialog
        handleClose={handleCloseEditing}
        open={true}
        procurement={procurement}
        projectId={projectId}
      />}
  </Container >
}


const DetailRow = ({ label, value, xs = 4, sm = 6 }) => (
  <Grid container item sm={sm} xs={12}>
    <Grid item xs={xs}>
      <FormLabel>{label}</FormLabel>
    </Grid>
    <Grid item xs={12 - xs}>
      <Typography>{value || '-'}</Typography>
    </Grid>
  </Grid>
);


export default ProcurementDetail;