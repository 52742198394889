import { Verified } from '@mui/icons-material';

const {
  Stack,
  FormLabel,
  Divider,
  Button,
  CircularProgress,
  Alert,
  Typography,
  Fade,
} = require('@mui/material');
const { ChangesPreview } = require('./ChangesPreview');

export const SubmitStep = ({
  handleSubmit,
  task,
  form,
  isSuccess,
  isLoading,
  isError,
  handleReset,
}) => {
  if (isLoading)
    return (
      <Fade in={true} timeout={500}>
        <Stack alignItems={'center'} spacing={1}>
          <CircularProgress size={'4em'} />
          <Typography textAlign={'center'}>Submitting Jobwalk...</Typography>
        </Stack>
      </Fade>
    );

  return (
    <Stack useFlexGap alignItems={'center'} gap={1}>
      {isSuccess ? (
        <>
          <Verified color={'success'} sx={{ fontSize: '4em' }} />
          <Typography textAlign={'center'}>Jobwalk Submitted Successfully</Typography>
          <Button
            size={'small'}
            sx={{ margin: 'auto' }}
            variant={'contained'}
            onClick={handleReset}
          >
            Restart
          </Button>
        </>
      ) : (
        <>
          {isError && (
            <Alert severity={'error'} sx={{ width: 1, boxSizing: 'border-box' }}>
              An error occurred while submitting
            </Alert>
          )}
          <Typography fontWeight={'bold'}>Confirm Changes</Typography>
          <Divider flexItem />
          <ChangesPreview form={form} task={task} />
          <Button size={'large'} variant={'contained'} onClick={handleSubmit}>
            Submit
          </Button>
        </>
      )}
    </Stack>
  );
};
