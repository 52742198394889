import { formatGanttTaskToTask, formatTaskToGanttTask } from '@blackhyve/utilities/gantt';

export function formatTask(task) {
  return {
    ...formatTaskToGanttTask(task),
    $open: true,
  };
}

export function undoFormatTask(formattedTask) {
  return formatGanttTaskToTask(formattedTask);
}
