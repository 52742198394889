import { FormLabel, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { formatDate, isValid, startOfDay } from 'date-fns';
import { Controller } from 'react-hook-form';

export const SkipDetailsStep = ({ form, task, holidays = [] }) => {
  const today = startOfDay(new Date());
  const workdayCalendar = task.workday_calendar;
  const { control } = form;
  return (
    <Stack useFlexGap gap={1}>
      <Stack gap={0.5}>
        <FormLabel>When was this info collected?</FormLabel>
        <Controller
          control={control}
          name={'effective_date'}
          render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
            <DatePicker
              disableFuture
              inputRef={ref}
              label={'Effective Date'}
              shouldDisableDate={(date) =>
                workdayCalendar[
                  date.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase()
                ] === '0' || holidays.includes(formatDate(date, 'yyyy-MM-dd'))
              }
              slotProps={{
                actionBar: { actions: ['today'] },
                textField: {
                  fullWidth: true,
                  size: 'small',
                  error: invalid,
                  helperText: error?.message,
                },
              }}
              {...field}
            />
          )}
          rules={{
            required: 'Field is required',
            validate: {
              isDate: (v) => isValid(v) || 'Invalid Date',
              isBeforeToday: (value) => +value <= +today || 'Cannot be in the future',
              isNotWeekend: (value) =>
                workdayCalendar[
                  value.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase()
                ] === '1' || 'Cannot be on a non working day',
            },
          }}
        />
      </Stack>
    </Stack>
  );
};
