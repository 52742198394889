import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
} from '@mui/material';
import { format, startOfWeek } from 'date-fns';
import { usePublishWeeklyWorkPlanMutation } from 'features/targets/api/target.api';
import { PublishWeeklyWorkPlanForm } from 'features/targets/components/PublishWeeklyWorkPlanForm';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

const Slide = styled(Box)({
  transition: 'width .3s ease',
});

const startOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

export const PublishTargetsDrawer = ({
  projectId,
  taskId,
  date: _date,
  setDate: _setDate,
  handleClose,
  isOpen,
  drawerWidth = '600px',
  children,
} = {}) => {
  const [publishWwp, { isLoading: isPublishingTargets }] = usePublishWeeklyWorkPlanMutation();

  const [date, setDate] = useState(startOfThisWeek);

  const form = useForm({ defaultValues: { message: '' } });

  const onSubmit = async (formValues) => {
    const changedTargets = form.formState.dirtyFields.targets
      ? Object.keys(form.formState.dirtyFields.targets).map((formTargetId) => ({
          id: formTargetId.slice(7),
          percent_complete: formValues.targets[formTargetId],
        }))
      : [];

    publishWwp({
      ...formValues,
      date: format(_date || date, 'yyyy-MM-dd'),
      projectId,
      parentId: taskId,
      targets: changedTargets,
    })
      .unwrap()
      .then((result) => handleClose(false));
  };
  return (
    <Slide
      square
      component={Paper}
      flexShrink={0}
      height={1}
      overflow={'hidden'}
      right={0}
      style={{ width: isOpen ? drawerWidth : 0 }}
      top={0}
      zIndex={1}
      // position={'absolute'}
    >
      {children}
      <Box
        display={'flex'}
        flexDirection={'column'}
        height={'100%'}
        maxHeight={'100%'}
        overflow={'auto'}
        width={drawerWidth}
      >
        <DialogTitle>Targets</DialogTitle>
        <DialogContent sx={{ flex: 1 }}>
          <Box pt={2} px={2}>
            <PublishWeeklyWorkPlanForm
              date={_date || date}
              form={form}
              projectId={projectId}
              setDate={_setDate || setDate}
              taskId={taskId}
              onSubmit={onSubmit}
            />
          </Box>
        </DialogContent>
        {/* <Box square bottom={0} component={Paper} p={1}  zIndex={1}> */}
        <DialogActions>
          <LoadingButton
            loading={isPublishingTargets}
            variant={'contained'}
            onClick={() => form.handleSubmit(onSubmit)()}
          >
            Publish
          </LoadingButton>
        </DialogActions>
        {/* </Box> */}
      </Box>
    </Slide>
  );
};
