import Box from '@mui/material/Box';
import format from 'date-fns/format';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { ConfirmDeleteDialog } from '@blackhyve/common';
import { useNavigate, useParams } from 'react-router-dom';
import ProcurementDataGridToolbar from './ProcurementDataGridToolbar';
import { getProcurementDataGridColumns } from '../utils/procurementDataGridColumns';
import {
  useCreateProcurementMutation,
  useDeleteProcurementMutation,
  useUpdateProcurementMutation,
} from '../store/procurement.api';

/**
 * Procurement Grid Table
 * @param {Array} rows
 * @param {Object} filters
 * @param {Number} total
 * @param {Object} setFilters
 * @param {Boolean} isLoading
 * @param {Boolean} isFetching
 * @returns
 */
const ProcurementDataGridTable = ({
  rows,
  filters,
  setFilters,
  isLoading,
  isFetching,
  total,
  disableToolBar = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const workspaceId = Number(useParams().workspaceId) || undefined;
  const projectId = Number(useParams().projectId) || undefined;
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

  const [tempRows, setTempRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const [createProcurement] = useCreateProcurementMutation();
  const [updateProcurement] = useUpdateProcurementMutation();
  const [deleteProcurement] = useDeleteProcurementMutation();

  const handleUpdate = async (updatedRow) => {
    const orderDate = updatedRow.order_date ? format(updatedRow.order_date, 'yyyy-MM-dd') : null;
    const shipDate = updatedRow.ship_date ? format(updatedRow.ship_date, 'yyyy-MM-dd') : null;
    const arrivalDate = updatedRow.arrival_date
      ? format(updatedRow.arrival_date, 'yyyy-MM-dd')
      : null;
    const tags = updatedRow?.tags?.length ? updatedRow.tags.map((tag) => tag?.id ?? tag) : [];

    const apiToCall = updatedRow?.isNew ? createProcurement : updateProcurement;
    if (updatedRow.isNew) {
      delete updatedRow.id;
    }
    const response = await apiToCall({
      ...updatedRow,
      projectId,
      order_date: orderDate,
      ship_date: shipDate,
      tags,
      arrival_date: arrivalDate,
    });

    if (response.error && response.error?.data?.errors) {
      const errorMessage = Object.values(response.error?.data?.errors).flat().join(', ');
      enqueueSnackbar(`Error while saving: ${errorMessage}`, { variant: 'error' });
      return false;
    }

    if (updatedRow?.isNew) {
      setTempRows([]);
    }
    enqueueSnackbar('Saved successfully', { variant: 'success' });
    return updatedRow;
  };

  const handleOnProcessRowUpdateError = (errors) => {
    console.log(errors);
    if (!isEmpty(errors)) {
      const errorMessage = Object.values(errors).flat().join(', ');
      enqueueSnackbar(`Error while saving: ${errorMessage}`, { variant: 'error' });
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => setRowModesModel(newRowModesModel);

  const handleDetailPage = (id) => {
    navigate(`/workspace/${workspaceId}/projects/${projectId}/procurements/${id}`);
  };

  const handleConfirmDelete = (id) => {
    setConfirmDeleteDialogOpen(id);
  };

  const handleDelete = () => {
    console.log('confirmDeleteDialogOpen =>', confirmDeleteDialogOpen);
    deleteProcurement(confirmDeleteDialogOpen);
  };

  const listOfRows = useMemo(() => {
    return [...tempRows, ...rows];
  }, [tempRows, rows]);

  const columns = getProcurementDataGridColumns(
    rowModesModel,
    setRowModesModel,
    listOfRows,
    setTempRows,
    handleConfirmDelete,
    handleDetailPage
  );

  return (
    <Box display={'flex'} flexDirection={'column'} minHeight={'250px'} style={{ width: '100%' }}>
      <DataGrid
        disableColumnFilter
        disableRowSelectionOnClick
        showCellVerticalBorder
        showColumnVerticalBorder
        columns={columns}
        density="standard"
        editMode="row"
        filterMode="server"
        getRowId={(row) => row?.id}
        loading={isLoading || isFetching}
        paginationMode="server"
        paginationModel={{ pageSize: filters.per_page, page: filters.page }}
        processRowUpdate={handleUpdate}
        rowCount={total}
        rowModesModel={rowModesModel}
        rows={listOfRows}
        sortingMode="server"
        sortingOrder={['desc', 'asc']}
        slotProps={{
          loadingOverlay: {
            variant: 'linear-progress',
            noRowsVariant: 'circular-progress',
          },
          toolbar: {
            projectId,
            setTempRows,
            tempRows,
            setRowModesModel,
            rowModesModel,
            setFilters,
            filters,
          },
        }}
        slots={{
          toolbar: disableToolBar ? undefined : ProcurementDataGridToolbar,
        }}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
            backgroundColor: 'rgba(25, 118, 210, 0.08)',
          },
          boxShadow: 2,

          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
        }}
        onProcessRowUpdateError={handleOnProcessRowUpdateError}
        onRowModesModelChange={handleRowModesModelChange}
        onPaginationModelChange={(params) => {
          setFilters((prevFilters) => ({
            ...prevFilters,
            page: params.page,
            per_page: params.pageSize,
          }));
        }}
        onSortModelChange={(sortParams) => {
          const sortField = sortParams[0];
          setFilters((prevFilters) => ({
            ...prevFilters,
            order_by: `${sortField?.field},${sortField?.sort}`,
            page: 1,
          }));
        }}
      />
      {confirmDeleteDialogOpen && (
        <ConfirmDeleteDialog
          handleClose={() => setConfirmDeleteDialogOpen(false)}
          handleDelete={handleDelete}
          item={'procurement'}
          open={confirmDeleteDialogOpen}
        />
      )}
    </Box>
  );
};

export default ProcurementDataGridTable;
