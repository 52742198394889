import { isValid } from 'date-fns';
import { useWatch } from 'react-hook-form';

const { parseDate, differenceInWorkdays } = require('@blackhyve/utilities/dates');
const { Stack } = require('@mui/material');
const { ValueWithLabel } = require('components/ValueChange');

export const ChangesPreview = ({ task, form, ...props }) => {
  const { control } = form;
  const forecastedEndDate = useWatch({ control: control, name: 'forecasted_end' });
  const pr = useWatch({ control: control, name: 'pr' });
  const daysAdded = isValid(forecastedEndDate)
    ? differenceInWorkdays(parseDate(task.forecasted_end), forecastedEndDate)
    : undefined;
  const pace = useWatch({ control: control, name: 'pace' });
  const percentComplete = useWatch({ control: control, name: 'percent_complete' });

  if (!forecastedEndDate && !pr && !pace && !percentComplete) {
    return 'Jobwalk will be recorded but the task will not be updated';
  }

  return (
    <Stack
      alignSelf={'center'}
      columnGap={2}
      direction={'row'}
      rowGap={1}
      {...props}
      flexWrap={'wrap'}
      overflow={'auto'}
    >
      <ValueWithLabel
        initialValue={parseDate(task.forecasted_end)?.toLocaleDateString()}
        label={'Forecasted End'}
        value={forecastedEndDate?.toLocaleDateString() || 'N/a'}
        ValueProps={{
          color:
            +forecastedEndDate <= +parseDate(task.scheduled_end) ? 'success.main' : 'error.main',
        }}
      />
      <ValueWithLabel
        label={'Duration Added'}
        value={daysAdded !== undefined ? `${daysAdded} ${daysAdded === 1 ? 'day' : 'days'}` : 'N/a'}
        ValueProps={{ color: daysAdded <= 0 ? 'success.main' : 'error.main' }}
      />
      <ValueWithLabel
        initialValue={task.pace}
        label={'Pace'}
        value={pace !== undefined && !isNaN(pace) ? pace : 'N/a'}
        ValueProps={{ color: pace >= 0 ? 'success.main' : 'error.main' }}
      />
      <ValueWithLabel
        initialValue={parseFloat(task.pr * 100).toFixed(0) + '%'}
        label={'PR'}
        value={pr !== undefined ? parseFloat(pr * 100).toFixed(0) + '%' : 'N/a'}
        ValueProps={{ color: pr >= 1 ? 'success.main' : 'error.main' }}
      />
      <ValueWithLabel
        initialValue={parseFloat(task.percent_complete * 100).toFixed(0) + '%'}
        label={'% Complete'}
        value={
          percentComplete !== undefined ? parseFloat(percentComplete * 100).toFixed(0) + '%' : 'N/a'
        }
        ValueProps={{
          color: task.percent_complete < percentComplete ? 'success.main' : 'error.main',
        }}
      />
    </Stack>
  );
};
