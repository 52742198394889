import { addWorkdays, getClosestWorkingTime } from '@blackhyve/utilities/dates';
import { Divider, FormLabel, Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { isAfter, isBefore, isSameDay, isValid, startOfDay } from 'date-fns';
import { Controller } from 'react-hook-form';
import { useSetFormValues } from '../hooks/useSetFormValues';
import { ChangesPreview } from './ChangesPreview';

export const CustomDetailsStep = ({ task, form = {}, holidays = [] }) => {
  const { control, setValue, getValues, trigger } = form;
  const today = startOfDay(new Date());
  const workdayCalendar = task.workday_calendar;
  const { setFormValues } = useSetFormValues({ form, task, holidays });

  const rules = {
    isDate: (value) => isValid(value) || 'Invalid Date',
    isNotWeekend: (value) =>
      workdayCalendar[value?.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase()] ===
        '1' || 'Cannot be on a non working day',
    isBeforeForecasted: (value, values) =>
      +value <= +values.forecasted_end || 'Cannot be later than forecasted end',
    isNotFuture: (value) => +value <= +today || 'Cannot be in the future',
    isAfterEffective: (value, values) =>
      isAfter(value, values.effective_date) ||
      isSameDay(value, values.effective_date) ||
      'Cannot be before effective date',
    isAfterStart: (value, values) =>
      isAfter(value, values.actual_start) ||
      isSameDay(value, values.actual_start) ||
      'Cannot be before actual start date',
  };

  return (
    <Stack useFlexGap spacing={1}>
      <Stack spacing={0.5}>
        <FormLabel>When did this task start?</FormLabel>
        <Controller
          control={control}
          name={'actual_start'}
          render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
            <DatePicker
              disableFuture
              inputRef={ref}
              label={'Actual Start'}
              shouldDisableDate={(date) => rules.isNotWeekend(date) !== true}
              slotProps={{
                textField: { size: 'small', error: invalid, helperText: error?.message },
              }}
              {...field}
            />
          )}
          rules={{
            required: 'Field is required',
            validate: {
              isDate: rules.isDate,
              isNotFuture: rules.isNotFuture,
              isNotWeekend: rules.isNotWeekend,
              isBeforeForecasted: rules.isBeforeForecasted,
            },
            onChange: (event) => {
              const newStartDate = event.target.value;
              const { effective_date, forecasted_end } = getValues();
              setFormValues({
                startDate: newStartDate,
                endDate: isAfter(forecasted_end, newStartDate)
                  ? forecasted_end
                  : getClosestWorkingTime(newStartDate, 'future', { workdayCalendar, holidays }),
                effectiveDate: isAfter(effective_date, newStartDate)
                  ? effective_date
                  : getClosestWorkingTime(newStartDate, 'future', { workdayCalendar, holidays }),
              });
              trigger('actual_start');
            },
          }}
        />
      </Stack>
      <Stack spacing={0.5}>
        <FormLabel>When did/will this task finish?</FormLabel>
        <Stack useFlexGap direction={{ md: 'row' }} justifyContent={'space-between'} spacing={1}>
          <Controller
            control={control}
            name={'forecasted_end'}
            render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
              <DatePicker
                inputRef={ref}
                label={'Forecasted End'}
                shouldDisableDate={(date) => rules.isNotWeekend(date) !== true}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                    error: invalid,
                    helperText: error?.message,
                  },
                }}
                {...field}
              />
            )}
            rules={{
              required: 'Field is required',
              validate: {
                isDate: rules.isDate,
                isNotWeekend: rules.isNotWeekend,
                isAfterEffective: rules.isAfterEffective,
                isAfterStart: rules.isAfterStart,
              },
              onChange: (event) => {
                const newEndDate = event.target.value;
                const { actual_start, effective_date } = getValues();
                setFormValues({
                  startDate: isBefore(newEndDate, actual_start)
                    ? getClosestWorkingTime(newEndDate, 'past', { workdayCalendar, holidays })
                    : actual_start,
                  endDate: newEndDate,
                  effectiveDate: isAfter(effective_date, newEndDate)
                    ? getClosestWorkingTime(newEndDate, 'past', { workdayCalendar, holidays })
                    : effective_date,
                });
                trigger('forecasted_end');
              },
            }}
          />
          <Typography marginTop={{ md: 1 }}>Or</Typography>
          <Controller
            control={control}
            name={'days_remaining'}
            render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
              <TextField
                fullWidth
                error={invalid}
                helperText={error?.message}
                inputRef={ref}
                label={'Days Remaining'}
                size={'small'}
                type={'number'}
                {...field}
              />
            )}
            rules={{
              required: 'Field is required',
              validate: {
                isGreaterThanZero: (value) => value >= 0 || 'Must Be Greater Than 0',
                isAfterEffective: (value, values) =>
                  rules.isAfterEffective(values.forecasted_end, values),
                isAfterStart: (value, values) => rules.isAfterStart(values.forecasted_end, values),
                isNotWeekend: (value, values) => rules.isNotWeekend(values.forecasted_end, values),
              },
              onChange: (event) => {
                const newDaysRemaining = Math.max(event.target.value, 0);
                setValue('days_remaining', newDaysRemaining, { shouldValidate: true });
                const { actual_start, effective_date } = getValues();
                const newEndDate = addWorkdays(effective_date, newDaysRemaining, {
                  holidays,
                  workdayCalendar,
                });
                setFormValues({
                  startDate: actual_start,
                  endDate: newEndDate,
                  effectiveDate: isAfter(effective_date, newEndDate)
                    ? getClosestWorkingTime(newEndDate, 'past', { workdayCalendar, holidays })
                    : effective_date,
                });
              },
            }}
          />
        </Stack>
      </Stack>
      <Stack spacing={0.5}>
        <FormLabel>When was this info collected?</FormLabel>
        <Controller
          control={control}
          name={'effective_date'}
          render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
            <DatePicker
              disableFuture
              inputRef={ref}
              label={'Effective Date'}
              shouldDisableDate={(date) => rules.isNotWeekend(date) !== true}
              slotProps={{
                actionBar: { actions: ['today'] },
                textField: {
                  fullWidth: true,
                  size: 'small',
                  error: invalid,
                  helperText: error?.message,
                },
              }}
              {...field}
            />
          )}
          rules={{
            required: 'Field is required',
            validate: {
              isDate: rules.isDate,
              isNotFuture: rules.isNotFuture,
              isNotWeekend: rules.isNotWeekend,
              isBeforeForecasted: rules.isBeforeForecasted,
              isAfterStart: rules.isAfterStart,
            },
            onChange: (event) => {
              const newEffectiveDate = event.target.value;
              const { actual_start, forecasted_end } = getValues();
              setFormValues({
                startDate: isBefore(newEffectiveDate, actual_start)
                  ? getClosestWorkingTime(newEffectiveDate, 'past', { workdayCalendar, holidays })
                  : actual_start,
                endDate: isAfter(newEffectiveDate, forecasted_end)
                  ? getClosestWorkingTime(newEffectiveDate, 'future', { workdayCalendar, holidays })
                  : forecasted_end,
                effectiveDate: newEffectiveDate,
              });
              trigger('effective_date');
            },
          }}
        />
      </Stack>
      <Divider flexItem />
      <ChangesPreview alignSelf={'flex-start'} flex={1} form={form} task={task} />
    </Stack>
  );
};
