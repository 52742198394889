import '@gooddata/sdk-ui-filters/styles/css/main.css';
import '@gooddata/sdk-ui-charts/styles/css/main.css';
import '@gooddata/sdk-ui-geo/styles/css/main.css';
import '@gooddata/sdk-ui-pivot/styles/css/main.css';
import '@gooddata/sdk-ui-kit/styles/css/main.css';
import '@gooddata/sdk-ui-ext/styles/css/main.css';
import '@gooddata/sdk-ui-dashboard/styles/css/main.css';
import '../css/analytics.css';
import {
  Dashboard,
  DashboardStoreAccessorRepository,
  changeAttributeFilterSelection,
  singleEventTypeHandler,
} from '@gooddata/sdk-ui-dashboard';
import { idRef } from '@gooddata/sdk-model';
import { useBackend } from './BackendProvider';
import { BackendProvider, WorkspaceProvider } from '@gooddata/sdk-ui';
import { useParams } from 'react-router-dom';

const GoodDataDashboard = ({ workspaceId, dashboardId, isGlobal }) => {
  const { projectId } = useParams();
  const backend = useBackend();

  let dashboard = idRef(dashboardId);

  let eventHandlers = [];
  if (!isGlobal) {
    eventHandlers = [
      singleEventTypeHandler('GDC.DASH/EVT.INITIALIZED', (e) => {
        console.log('Dashboard event fired:', e);
        if (
          e?.payload?.dashboard?.filterContext?.filters[0]?.attributeFilter?.localIdentifier &&
          DashboardStoreAccessorRepository.isAccessorInitializedForDashboard(dashboardId)
        ) {
          DashboardStoreAccessorRepository.getDashboardDispatchForDashboard(dashboardId)(
            changeAttributeFilterSelection(
              e.payload.dashboard.filterContext.filters[0].attributeFilter.localIdentifier,
              [projectId],
              'IN'
            )
          );
        }
      }),
    ];
  }

  return (
    <>
      <BackendProvider backend={backend}>
        <WorkspaceProvider workspace={workspaceId}>
          <Dashboard
            dashboard={dashboard}
            eventHandlers={eventHandlers}
            onStateChange={DashboardStoreAccessorRepository.getOnChangeHandlerForDashboard(
              dashboardId
            )}
          />
        </WorkspaceProvider>
      </BackendProvider>
    </>
  );
};

export default GoodDataDashboard;
