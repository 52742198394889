import { Grid, InputLabel, MenuItem, Select, TextField, OutlinedInput } from '@mui/material';
import { Controller } from 'react-hook-form';
import { ResourceType, ResourceUnit } from '../api/resources.models';
import { capitalize } from 'lodash';
import { useGetAllResourceGroupsQuery } from '../api/resources.api';
import { useEffect } from 'react';
import { WorkDaysComponent } from 'components/common/v3/WorkDaysComponent';

export function ResourceForm({
  form,
  isGroup,
  onSubmit,
}: {
  form: any;
  isGroup: boolean;
  onSubmit: any;
}) {
  const { handleSubmit, control, setValue } = form;
  const { data: groupResources = [] } = useGetAllResourceGroupsQuery({});

  useEffect(() => {
    if (isGroup) {
      setValue('type', ResourceType.Group);
    }
  }, [isGroup, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item xs={9}>
          <InputLabel htmlFor="name">Name</InputLabel>
          <Controller
            control={control}
            name="name"
            rules={{ required: 'Name is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                autoFocus
                fullWidth
                error={!!error}
                helperText={error?.message}
                placeholder="Resource Name"
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>

        {!isGroup && (
          <Grid item xs={3}>
            <InputLabel htmlFor="type">Type</InputLabel>
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <Select {...field} displayEmpty fullWidth size="small" variant="outlined">
                  <MenuItem value={ResourceType.Labor}>{capitalize(ResourceType.Labor)}</MenuItem>
                  <MenuItem value={ResourceType.Equipment}>
                    {capitalize(ResourceType.Equipment)}
                  </MenuItem>
                </Select>
              )}
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <InputLabel htmlFor="unit">Unit</InputLabel>
          <Controller
            control={control}
            name="unit"
            render={({ field }) => (
              <Select {...field} displayEmpty fullWidth size="small" variant="outlined">
                <MenuItem value={ResourceUnit.Hours}>{capitalize(ResourceUnit.Hours)}</MenuItem>
              </Select>
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel htmlFor="max_capacity">Daily Capacity</InputLabel>
          <Controller
            control={control}
            name="max_capacity"
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                placeholder="Daily Capacity"
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Select Working Days</InputLabel>
          <Grid container spacing={1}>
            <Controller
              control={control}
              name="workday_calendar"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <WorkDaysComponent disabled={false} workdayCalendar={value} onChange={onChange} />
              )}
            />
          </Grid>
        </Grid>

        {!isGroup && (
          <Grid item xs={12}>
            <InputLabel>This Resource Belongs To:</InputLabel>
            <Controller
              control={control}
              name="parents"
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  displayEmpty
                  fullWidth
                  error={!!error}
                  input={<OutlinedInput margin="dense" />}
                  size="medium"
                >
                  <MenuItem value={''}>None</MenuItem>
                  {groupResources?.map((resource) => (
                    <MenuItem key={resource.id} value={resource.id}>
                      {resource.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
        )}
      </Grid>
    </form>
  );
}
