import {} from '@blackhyve/common';
import CompanyIcon from '@blackhyve/common/src/icons/CompanyIcon';
import TradeIcon from '@blackhyve/common/src/icons/Trade';
import { parseDate } from '@blackhyve/utilities/dates';
import { Delete } from '@mui/icons-material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DescriptionIcon from '@mui/icons-material/Description';
import Edit from '@mui/icons-material/Edit';
import GroupsIcon from '@mui/icons-material/Groups';
import PaletteIcon from '@mui/icons-material/Palette';
import PersonIcon from '@mui/icons-material/Person';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { LoadingButton } from '@mui/lab';
import { TextField, useMediaQuery, useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ConfirmDeleteDialog from 'components/common/popovers/ConfirmDeleteDialog';
import DisplayWithEditAccess from 'components/common/v3/DisplayWithEditAccess';
import { OptionsMenu, OptionsMenuItem } from 'components/menus/OptionsMenu';
import { Status } from 'features/tasks';
import { Pace } from 'features/tasks/components/Pace';
import { ProductionRating } from 'features/tasks/components/ProductionRating';
import { Progress } from 'features/tasks/components/Progress';
import { useGetTradesQuery } from 'features/trades/api/trades.api';
import isEmpty from 'lodash/isEmpty';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';
import TagsTab from './TagsTab';
import TaskFormComponent from './TaskFormComponent';

const parseAndFormatDate = (dateString) => parseDate(dateString)?.toLocaleDateString();

const formFields = [
  {
    label: 'Scheduled Dates',
    icon: <DateRangeIcon fontSize="inherit" />,
    content: (task) => (
      <Typography>
        {task?.scheduled_start ? parseAndFormatDate(task.scheduled_start) : ''} -{' '}
        {task?.scheduled_end ? parseAndFormatDate(task.scheduled_end) : '-'}
      </Typography>
    ),
  },
  {
    label: 'Forecasted Dates',
    icon: <DateRangeIcon fontSize="inherit" />,
    content: (task) => (
      <Typography>
        {task?.forecasted_start ? parseAndFormatDate(task.forecasted_start) : ''} -{' '}
        {task?.forecasted_end ? parseAndFormatDate(task.forecasted_end) : '-'}
      </Typography>
    ),
  },
  {
    label: 'Responsible',
    icon: <PersonIcon fontSize="inherit" />,
    content: (task) => (
      <>
        {task?.responsible_users?.map((user) => (
          <Chip
            avatar={<Avatar alt={user?.name} src={user?.profile_image?.original_url} />}
            key={user?.id}
            label={user?.name}
            variant="outlined"
          />
        ))}
      </>
    ),
  },
  {
    label: 'Contacts',
    icon: <CompanyIcon fontSize="inherit" />,
    content: (task) => (
      <>
        {task?.contacts?.length
          ? task.contacts.map((contact) => (
              <Chip
                avatar={<CompanyIcon />}
                key={contact?.id}
                label={contact?.name}
                variant="outlined"
                sx={{
                  '& .MuiChip-avatar': {
                    height: 20,
                    width: 20,
                    color: 'grey.500',
                  },
                }}
              />
            ))
          : ''}
      </>
    ),
  },
  {
    label: 'Crew',
    icon: <GroupsIcon fontSize="inherit" />,
    content: (task) => <Typography>{task?.crew_size}</Typography>,
  },
  {
    label: 'Trades',
    icon: <TradeIcon fontSize="inherit" />,
    content: (task, meta) => (
      <Typography>
        {!isEmpty(meta?.tradeEntities) && task?.trades?.length
          ? task.trades?.map((tradeId) => meta?.tradeEntities[tradeId]?.name)?.join(', ')
          : ''}
      </Typography>
    ),
  },
  {
    label: 'Color',
    icon: <PaletteIcon fontSize="inherit" />,
    content: (task) => <Box sx={{ bgcolor: task.color, height: '8px', p: 1, width: '8px' }}></Box>,
  },
  {
    label: 'Constraint Type',
    icon: <ZoomInMapIcon fontSize="inherit" />,
    content: (task) => <Typography>{task?.constraint_type}</Typography>,
  },
  {
    label: 'Constraint Date',
    icon: <CalendarMonthIcon fontSize="inherit" />,
    content: (task) => (
      <Typography>
        {task?.constraint_date ? parseAndFormatDate(task.constraint_date) : '-'}
      </Typography>
    ),
  },
];

/**
 * Detail Tab Component
 * @param {Object} task
 * @returns
 */
export const DetailsTab = ({ task, handleTaskUpdate, handleTaskDelete }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [taskName, setTaskName] = useState(task.name);
  const [isUpdatingTaskName, setIsUpdatingTaskName] = useState(false);
  const [isTaskDeleting, setIstaskDeleting] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleUpdateName = async () => {
    if (task.name === taskName) {
      setIsEditingName(false);
      return;
    }

    try {
      setIsUpdatingTaskName(true);

      // Handle both promise and non-promise return values
      const result = handleTaskUpdate({
        id: task.id,
        project_id: task.project_id,
        name: taskName,
      });

      // If result is a promise, await it
      if (result && typeof result.then === 'function') {
        await result;
      }

      setIsUpdatingTaskName(false);
      enqueueSnackbar('Task updated successfully', { variant: 'success' });
      setIsEditingName(false);
    } catch (error) {
      setIsUpdatingTaskName(false);
      enqueueSnackbar('Not able to update task, retry again', { variant: 'error' });
    }
  };

  const handleOnDelete = async () => {
    try {
      setIstaskDeleting(true);

      // Handle both promise and non-promise return values
      const result = handleTaskDelete(task.id);

      // If result is a promise, await it
      if (result && typeof result.then === 'function') {
        await result;
      }

      setIstaskDeleting(false);
      navigate(`..`);
      enqueueSnackbar('Task deleted successfully', { variant: 'success' });
    } catch {
      setIstaskDeleting(false);
      enqueueSnackbar('Delete failed', { variant: 'error' });
    }
  };

  const handleOnKeyDown = (event) => (event.keyCode === 13 ? handleUpdateName() : null);

  const handleCloseEdit = () => setIsEditing(false);

  const { data: { entities: tradeEntities = {} } = {} } = useGetTradesQuery();

  return (
    <>
      {isEditingName ? (
        <Grid container item alignItems={'center'} gap={1}>
          <Grid item xs>
            <TextField
              autoFocus
              fullWidth
              size={'small'}
              value={taskName}
              variant={'outlined'}
              onChange={(event) => setTaskName(event.target.value)}
              onKeyDown={handleOnKeyDown}
            />
          </Grid>
          <Grid container item gap={1} xs="auto">
            <LoadingButton
              loading={isUpdatingTaskName}
              size="small"
              variant="contained"
              onClick={handleUpdateName}
            >
              Save
            </LoadingButton>
            <Button size="small" variant="outlined" onClick={() => setIsEditingName(false)}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Box mb={1}>
          <Grid container item alignItems={'center'} gap={1} xs={12}>
            <Typography sx={{ fontWeight: 'bold' }} variant={isSmallScreen ? 'h5' : 'h4'}>
              {task?.name}
            </Typography>
            <Box alignItems={'center'} display="flex" gap={1}>
              <Status value={task?.status} />
              <Progress value={task?.percent_complete} />
              <ProductionRating value={task?.pr} />
              <Pace value={task?.pace} />
            </Box>
            <DisplayWithEditAccess>
              <Grid item ml={'auto'}>
                <OptionsMenu>
                  {({ handleClose }) => (
                    <>
                      <OptionsMenuItem
                        icon={<Edit />}
                        onClick={() => {
                          handleClose();
                          setIsEditingName(true);
                        }}
                      >
                        Edit
                      </OptionsMenuItem>
                      <OptionsMenuItem
                        icon={<Delete color={'error'} />}
                        slotProps={{ listItemText: { primaryTypographyProps: { color: 'error' } } }}
                        onClick={() => {
                          handleClose();
                          setIsConfirmDeleteOpen(true);
                        }}
                      >
                        Delete
                      </OptionsMenuItem>
                    </>
                  )}
                </OptionsMenu>
              </Grid>
            </DisplayWithEditAccess>
          </Grid>
          <Grid container item gap={0.5} xs={12}>
            <TagsTab handleTaskUpdate={handleTaskUpdate} tags={task.tags} />
          </Grid>

          <ConfirmDeleteDialog
            handleClose={() => setIsConfirmDeleteOpen(false)}
            handleDelete={handleOnDelete}
            isDeleting={isTaskDeleting}
            item={'task'}
            open={isConfirmDeleteOpen}
          />
        </Box>
      )}
      <Grid container item component={Paper} elevation={3} gap={1.5} pb={2} pt={1} px={2}>
        {isEditing ? (
          <TaskFormComponent
            handleClose={handleCloseEdit}
            handleTaskUpdate={handleTaskUpdate}
            task={task}
          />
        ) : (
          <>
            <Grid container item xs={12}>
              <Grid container item xs={12}>
                <Grid container item justifyContent="space-between" xs={12}>
                  <Box alignItems={'center'} display={'flex'}>
                    {!isSmallScreen ? (
                      <IconButton sx={{ py: 0 }}>
                        <DescriptionIcon />
                      </IconButton>
                    ) : null}
                    <FormLabel>Description:</FormLabel>
                  </Box>
                  <DisplayWithEditAccess>
                    <Button
                      size="small"
                      startIcon={<Edit />}
                      variant="outlined"
                      onClick={() => setIsEditing(true)}
                    >
                      Edit
                    </Button>
                  </DisplayWithEditAccess>
                </Grid>
                <Grid item xs={12}>
                  {isEmpty(task?.description) ? null : (
                    <ReactQuill
                      bounds="quill"
                      className={'comment'}
                      readOnly={true}
                      theme={'bubble'}
                      value={task?.description}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {formFields?.map((field, index) => (
              <Grid container item alignItems="center" key={index} xs={12}>
                <Grid container item alignItems="center" md={4} xs={6}>
                  {!isSmallScreen ? <IconButton sx={{ py: 0 }}>{field.icon}</IconButton> : null}
                  <FormLabel>{field.label}:</FormLabel>
                </Grid>
                <Grid item md={8} xs={6}>
                  {field.content(task, { tradeEntities })}
                </Grid>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </>
  );
};

export default DetailsTab;
