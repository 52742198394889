import { NoDataAvailableComponent } from '@blackhyve/common';
import { formatTaskToGanttTask } from '@blackhyve/utilities/gantt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DragIndicator from '@mui/icons-material/DragIndicator';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useModal } from 'components/common/ModalProvider';
import SkeletonListComponent from 'components/common/v3/SkeletonListComponent';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import { useGetLbsQuery } from 'features/locations/store/location.api';
import { useInsertRecipeWithFlowAIMutation } from 'features/tasks/store/task.api';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import { memo, useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const useStyles = makeStyles((theme) => ({
  locationContainer: {
    border: '.5px solid #b3b3b3',
    backgroundColor: '#fff',
    borderRadius: '2px',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(3),
  },
  zoneContainer: {
    borderLeft: '.5px solid #b3b3b3',
    borderRight: '.5px solid #b3b3b3',
    borderBottom: '.5px solid #b3b3b3',
    borderRadius: '.5px',
    backgroundColor: '#fff',
    '&:first-child': {
      borderTop: '.5px solid #b3b3b3',
    },
    boxShadow: theme.shadows[2],
  },
  areaContainer: {
    borderTop: '.5px solid #b3b3b3',
    borderBottom: '.5px solid transparent',
  },
}));

export const transformRawData = (rawData) => {
  return rawData.map((location) => {
    return {
      id: location.id,
      name: location.name,
      isSelected: true,
      type: 'location',
      children: location.zones.map((zone) => {
        return {
          id: zone.id,
          name: zone.name,
          isSelected: true,
          type: 'zone',
          parentId: location.id,
          children: zone.areas.map((area) => {
            return {
              id: area.id,
              name: area.name,
              isSelected: true,
              type: 'area',
              parentId: zone.id,
            };
          }),
        };
      }),
    };
  });
};

// const traverseAndUpdate = (data, id, isSelected) => {
//   return data.forEach((item) => {
//     if (item.id == id) {
//       item.isSelected = isSelected;
//       return item;
//     }

//     if (item.children && item.children.length) {
//       item.children = traverseAndUpdate(item.children, id, isSelected);
//     }

//     return item;
//   });
// };

const updateChildrenSelection = (data, isSelected) => {
  for (const item of data) {
    item.isSelected = isSelected;
    if (item.children) {
      updateChildrenSelection(item.children, isSelected);
    }
  }
};

const updateSelection = (data, targetId, type, isSelected) => {
  const updatedData = cloneDeep(data);

  function updateParentSelection(data, parentId, isSelected) {
    for (const item of data) {
      if (item.id == parentId) {
        // Update the parent item
        item.isSelected = isSelected;
        // Recursively update the parent's parent
        if (item.parentId) {
          updateParentSelection(updatedData, item.parentId, isSelected);
        }
        return;
      }

      if (item?.children?.length) {
        updateParentSelection(item.children, parentId, isSelected);
      }
    }
  }

  //   function updateChildrenSelection(data, isSelected) {
  //     for (const item of data) {
  //       item.isSelected = isSelected;
  //       if (item.children) {
  //         updateChildrenSelection(item.children, isSelected);
  //       }
  //     }
  //   }

  function updateSelectionRecursively(data, targetId, type, isSelected) {
    for (const item of data) {
      if (item.id == targetId && item.type == type) {
        item.isSelected = isSelected;

        // Recursively update parents
        if (item.parentId && isSelected) {
          updateParentSelection(updatedData, item.parentId, isSelected);
        } else if (item.children && !isSelected) {
          updateChildrenSelection(item.children, isSelected);
        }
        return;
      }

      if (item.children) {
        // Recursively update children
        updateSelectionRecursively(item.children, targetId, type, isSelected);
      }
    }
  }

  updateSelectionRecursively(updatedData, targetId, type, isSelected);
  return updatedData;
};

const rearrangeData = (data, parentId, parentType, sourceIndex, destinationIndex) => {
  return data.map((item) => {
    if (item.id === parentId && item.type === parentType) {
      const [movedItem] = item.children.splice(sourceIndex, 1);
      item.children.splice(destinationIndex, 0, movedItem);
    } else if (item.children) {
      return {
        ...item,
        children: rearrangeData(item.children, parentId, parentType, sourceIndex, destinationIndex),
      };
    }
    return item;
  });
};

const filterData = (data) => {
  return data?.filter((item) => {
    if (item.isSelected) {
      if (item.children) {
        item.children = filterData(item.children);
      }
      return item;
    }
  });
};

const getPriority = (data) => {
  const apiData = { locations: {}, zones: {}, areas: {} };

  const selectPriority = (data) => {
    data.forEach((item, index) => {
      if (item.type === 'location') {
        apiData.locations[item.id] = index + 1;
      }
      if (item.type === 'zone') {
        apiData.zones[item.id] = index + 1;
      }
      if (item.type === 'area') {
        apiData.areas[item.id] = index + 1;
      }
      if (item.children) {
        selectPriority(item.children);
      }
    });
  };

  selectPriority(data);

  return Object.fromEntries(Object.entries(apiData).filter(([key, value]) => !isEmpty(value)));
};

/**
 * Flow AI Dialog
 * @param {Boolean} open
 * @param {Object} handleClose
 * @param {String} projectId
 * @param {String} ganttId
 * @returns
 */
const FlowAiDialog = ({ open, handleClose, projectId, ganttId, taskId, recipeId }) => {
  const { closeModal } = useModal();
  const [locations, setLocations] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const gantt = ganttStore.getGantt(ganttId);
  const { enqueueSnackbar } = useSnackbar();
  const { data: lbsData, isLoading: isLoadingLbs } = useGetLbsQuery({ projectId }, { skip: !open });
  const [insertRecipeWithFlowAI, { isLoading: isInsertingRecipe }] =
    useInsertRecipeWithFlowAIMutation();

  const handleUpdatePriority = (data) => {
    if (!data?.destination?.droppableId) {
      return;
    }
    const [type] = data.type.split('-');
    const parentId = parseInt(data.destination.droppableId.split('-')[1], 10);
    const parentType = data.destination.droppableId.split('-')[0];
    const sourceIndex = data.source.index;
    const destinationIndex = data.destination.index;
    let rearrangedData = cloneDeep(locations);
    if (type !== 'location') {
      rearrangedData = rearrangeData(
        rearrangedData,
        parentId,
        parentType,
        sourceIndex,
        destinationIndex
      );
    } else {
      const [movedItem] = rearrangedData.splice(sourceIndex, 1);
      rearrangedData.splice(destinationIndex, 0, movedItem);
    }
    setLocations(rearrangedData);
  };

  const handleSelectCheckbox = useCallback((id, type, isSelected) => {
    setLocations((prevData) => updateSelection([...prevData], id, type, isSelected));
  }, []);

  const handleSelectAll = () => {
    const selectAllStatus = !selectAll;
    setLocations((prevData) => {
      const prevLocations = cloneDeep(prevData);
      updateChildrenSelection(prevLocations, selectAllStatus);
      return prevLocations;
    });
    setSelectAll(selectAllStatus);
  };

  const handleAddFlowAITasks = () => {
    const filteredData = filterData(cloneDeep(locations));
    const lbsWithPriorities = getPriority(filteredData);

    if (isEmpty(lbsWithPriorities)) {
      enqueueSnackbar('No location selected, please select locations', { variant: 'error' });
      return;
    }

    const scrollState = gantt.getScrollState();
    insertRecipeWithFlowAI({
      taskId,
      recipeId,
      projectId,
      ...lbsWithPriorities,
    })
      .unwrap()
      .then((response) => {
        const tasks = !isEmpty(response?.entities?.task)
          ? Object.values(response.entities.task).map((task) => formatTaskToGanttTask(task))
          : [];
        const dependencies = !isEmpty(response?.entities?.dependencies)
          ? Object.values(response.entities.dependencies)
          : [];

        gantt.parse({
          data: tasks,
          links: dependencies,
        });
        gantt.getTask(taskId).$open = true;
        gantt.refreshTask(taskId);
        gantt.scrollTo(scrollState.x, scrollState.y);
        gantt.autoSchedule(taskId);
        // Close Recipe Modal
        closeModal();
        handleClose();
      })
      .catch((error) => {
        console.log('Error =>', error);
      });
  };

  useEffect(() => {
    if (!isEmpty(lbsData)) {
      setLocations(transformRawData(lbsData));
    }
  }, [lbsData]);

  return (
    <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle style={{ paddingBottom: '9px', paddingLeft: '20px' }}>
        PRIORITIZE YOUR LOCATIONS
      </DialogTitle>
      <DialogContent style={{ padding: 20, paddingLeft: 50, paddingRight: 50 }}>
        {isInsertingRecipe && (
          <Backdrop open={true} style={{ zIndex: 13 }} sx={{ color: '#fff' }}>
            <CircularProgress className="loading" />
          </Backdrop>
        )}
        {isLoadingLbs ? (
          <SkeletonListComponent />
        ) : (
          <>
            <Grid container item justifyContent="flex-end">
              <FormControlLabel
                label="Select All"
                labelPlacement="start"
                control={
                  <Checkbox checked={selectAll} color={'primary'} onChange={handleSelectAll} />
                }
              />
            </Grid>
            <DragDropContext onDragEnd={handleUpdatePriority}>
              <Droppable droppableId={'project-' + projectId} type={'location-' + projectId}>
                {(provided) => (
                  <Grid
                    container
                    {...provided.droppableProps}
                    justifyContent={'center'}
                    ref={provided.innerRef}
                  >
                    {locations?.length ? (
                      locations?.map((item, index) => {
                        return (
                          <Row
                            children={item.children}
                            handleSelect={handleSelectCheckbox}
                            id={item.id}
                            isSelected={item.isSelected}
                            key={item.id}
                            name={item.name}
                            priority={index}
                            type={item.type}
                          />
                        );
                      })
                    ) : (
                      <NoDataAvailableComponent />
                    )}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </DragDropContext>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button size="small" onClick={handleClose}>
          Close
        </Button>
        <Button
          color="primary"
          disabled={!locations?.length}
          size="small"
          variant="contained"
          onClick={handleAddFlowAITasks}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/**
 * Row
 * @param {String} id
 * @param {String} name
 * @param {String} priority
 * @param {String} type
 * @param {Object} children
 * @param {Boolean} isChildrenPresent
 * @returns
 */
const Row = memo(({ id, name, priority, children, type, handleSelect, isSelected = false }) => {
  const [collapse, setCollapse] = useState(true);
  const classes = useStyles();

  const nextType = type === 'location' ? 'zone' : type === 'zone' ? 'area' : undefined;
  const isChildrenPresent = children?.length;

  return (
    <Draggable draggableId={`${type}-${id}`} index={priority} key={`${id}-${type}`}>
      {(provided, snapshot) => (
        <Grid
          container
          item
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={
            type === 'location'
              ? classes.locationContainer
              : type === 'zone'
                ? classes.zoneContainer
                : classes.areaContainer
          }
          style={
            snapshot.isDragging
              ? {
                  border: '.5px solid #b3b3b3',
                  ...provided.draggableProps.style,
                }
              : { ...provided.draggableProps.style }
          }
        >
          <Grid
            container
            item
            sx={{
              py: (theme) => theme.spacing(1),
              ':hover': {
                backgroundColor: '#f5f5f5',
              },
              backgroundColor: 'grey.100',
              opacity: isSelected ? 1 : 0.5,
              border: type === 'location' ? '.5px solid #b3b3b3' : '',
              borderBottom: type === 'location' && !children.length ? '.5px solid #b3b3b3' : 'none',
            }}
          >
            <Grid item {...provided.dragHandleProps}>
              <DragIndicator sx={{ color: 'grey.600' }} />
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle2"
                sx={{
                  padding: '1px 5px',
                  backgroundColor: 'secondary.light',
                  borderRadius: '4px',
                  color: 'white',
                }}
              >
                {priority + 1}
              </Typography>
            </Grid>
            <Grid item style={{ width: '20px' }}>
              <IconButton
                disableFocusRipple={true}
                disableRipple={true}
                size="medium"
                sx={{ padding: 0 }}
                onClick={() => setCollapse(!collapse)}
              >
                {isChildrenPresent ? collapse ? <ArrowDropDownIcon /> : <ArrowRightIcon /> : null}
              </IconButton>
            </Grid>
            <Grid item style={!children ? { marginLeft: 25 } : {}}>
              <Typography variant="subtitle1">{name}</Typography>
            </Grid>
            <Grid container item xs justifyContent="flex-end">
              <Checkbox
                checked={isSelected}
                color={'primary'}
                style={{ paddingTop: 0, paddingBottom: 0 }}
                onChange={() => {
                  handleSelect(id, type, !isSelected);
                }}
              />
            </Grid>
          </Grid>

          {isChildrenPresent ? (
            <Droppable droppableId={`${type}-${id}`} type={`${nextType}-${id}`}>
              {(provided) => (
                <Grid container {...provided.droppableProps} ref={provided.innerRef}>
                  {collapse &&
                    children?.map((item, index) => {
                      return (
                        <Row
                          children={item.children}
                          handleSelect={handleSelect}
                          id={item.id}
                          isSelected={item.isSelected}
                          key={item.id}
                          name={item.name}
                          priority={index}
                          type={item.type}
                        />
                      );
                    })}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          ) : null}
        </Grid>
      )}
    </Draggable>
  );
});

export default FlowAiDialog;
