import { useEffect } from 'react';

export const useGanttLoadTaskAndLinks = (gantt, tasks, links, options = {}) => {
  const { skip = false } = options;

  useEffect(() => {
    if (
      gantt &&
      !gantt.$destroyed &&
      (!gantt.hasPendingUpdates || !gantt.hasPendingUpdates()) &&
      !skip
    ) {
      const scrollState = gantt.getScrollState();
      gantt.clearAll();
      gantt.parse({
        tasks,
        links,
      });
      gantt.scrollTo(scrollState.x, scrollState.y);
    }
  }, [gantt, links, skip, tasks]);
};
